require('./bootstrap');

const init = () => {
    /* ----- Global leaderboard Web URL ----- */
    // Comment out the URLs you dont want to reference
    // let globalLeaderboardWebURLPrepend = 'https://leaderboard.doextraordinary.com/'; /* --- Live --- */
    let globalLeaderboardWebURLPrepend = 'https://staging.leaderboard.doextraordinary.com/'; /* --- Staging --- */
    // let globalLeaderboardWebURLPrepend = 'http://192.168.0.111/wordpress/index.php/'; /* --- Dev local --- */

    // initialise WYSIWYG fields
    var customTemplates = {
        reset: function(context) {
            return '<li class="reset-list">' +
                '<a class="btn btn-default reset-wysiwyg">Reset</a>' +
            '</li>';
        },
        mediaArea: function(context) {
            return '<li class="media-list">' +
                '<input type="file" id="wysiwyg_file" name="wysiwyg_file" class="file-upload-input-inline">' +
            '</li>';
        }
    }

    // handle game table settings here

    const $wysiwyg = $('.textarea--wysiwyg');
    if ($wysiwyg.length) {
        $wysiwyg.wysihtml5({
            toolbar: {
                'font-styles': true,
                'emphasis': true,
                'lists': true,
                'html': true, 
                'link': false, 
                'image': false, 
                'color': false, 
                'blockquote': false,
                'mediaArea': true,
                'reset': true
            },
            parser: function(html) {
                return html;
            },
            customTemplates: customTemplates
        });
    }

    // reset the content in the wysiwyg
    $('.reset-wysiwyg').on('click', function(event) {
        const target = $(event.currentTarget);
        const wysiwyg = target.parents('.wysihtml5-toolbar').next().next('iframe').contents().find('.wysihtml5-editor');
        wysiwyg.html('');
    });

    $('.file-upload-input-inline').fileinput({
        uploadUrl: "/files/upload",
        autoReplace: true,
        showRemove: false
    });

    $('.file-upload-input-inline').on('fileuploaded', function(event, data, previewId, index) {
        let response = data.response;
        let imagePath = response['imagePath'];
        let target = $(event.currentTarget);
        let wysiwyg = target.parents('.wysihtml5-toolbar').next().next('iframe').contents().find('.wysihtml5-editor');
        let prevContent = wysiwyg.html();
        // append the media at the end of the wysiwyg field content
        let fileExtension = imagePath.split('.').pop();
        if (fileExtension == 'jpeg' || fileExtension == 'png' || fileExtension == 'jpg') {
            prevContent += '<p><br></p><img alt="" src="' + window.location.origin + imagePath + '">';
        } else if (fileExtension == 'mp4') {
            prevContent +=  '<p><video width="320" height="240" controls><source src="' + window.location.origin + imagePath + '" type="video/mp4"></video><br></p>';
        }
        wysiwyg.html(prevContent);
    });

    // initialise fully featured datatables
    const $fullFeaturedDataTables = $('.data-table--full');
    if ($fullFeaturedDataTables.length) {
        $fullFeaturedDataTables.each((index, element) => {
            $(element).DataTable({
                responsive: true,
                autoWidth: false,
                pageLength: '50',
                bSort: true,
                'fnCreatedRow': function(nRow, aData, iDataIndex) {
                    $(nRow).attr('id', iDataIndex);
                },
            });
        });
    }

    // initialise semi featured datatables - these remove searching and pagination datatable extras
    const $semiFeaturedDataTables = $('.data-table--semi');
    if ($semiFeaturedDataTables.length) {
        $semiFeaturedDataTables.each((index, element) => {
            var table = $(element).DataTable({
                rowReorder: true,
                responsive: true,
                autoWidth: false,
                bFilter: false,
                bPaginate: false,
                bInfo: false,
                bSort: true,
                'fnCreatedRow': function(nRow, aData, iDataIndex) {
                    $(nRow).attr('id', iDataIndex);
                },
                columnDefs: [{
                    orderable: false,
                    targets: "no-sort"
                }]
            });

            table.on( 'row-reorder', function ( e, diff, edit ) {
                var tableId = $(element).attr('id');
                switch (tableId) {
                    case 'intro_screen_table':
                        screens = getScreens('intro');
                        setUpScreenTable(screens, 'intro');
                        break;
                    case 'outro_screen_table':
                        screens = getScreens('outro');
                        setUpScreenTable(screens, 'outro');
                        break;
                    case 'pre_summary_screen_table':
                        screens = getScreens('preSummary');
                        setUpScreenTable(screens, 'preSummary');
                        break;
                    case 'leaderboard_columns_table':
                        lbColumns = getLeaderboardColumns();
                        var tempColumns = lbColumns.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempColumns[newIndex] = lbColumns[oldIndex];
                            $('.leaderboard-column-title', diff[i].node).attr('data-leaderboard-column-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        lbColumns = tempColumns.slice(0);
                        setLeaderboardColumns(lbColumns);
                        break;
                    case 'mailchimp_merge_tags_table':
                        mergeTags = getMailchimpMergeTags();
                        var tempMergeTags = mergeTags.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempColumns[newIndex] = mergeTags[oldIndex];
                            $('.mailchimp-merge-tag-description', diff[i].node).attr('data-mailchimp-merge-tag-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        mergeTags = tempMergeTags.slice(0);
                        saveMailchimpMergeTags(mergeTags);
                        break;
                    case 'experience_events':
                        setUpEventsTable(currentEventsArray);
                        break;
                    case 'master_stage_table':
                        var tempMasterStages = masterStages.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempMasterStages[newIndex] = masterStages[oldIndex];
                            $('.master-stage-title', diff[i].node).attr('data-master-stage-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        masterStages = tempMasterStages.slice(0);
                        setMasterStages();
                        break;
                    case 'master_stage_events':
                        setUpEventsTable(currentEventsArray);
                        break;
                    case 'stage_table':
                        var tempStages = stages.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempStages[newIndex] = stages[oldIndex];
                            $('.stage-title', diff[i].node).attr('data-stage-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        stages = tempStages.slice(0);
                        setStages();
                        break;
                    case 'stage_events':
                        setUpEventsTable(currentEventsArray);
                        break;
                    case 'puzzle_table':
                        var tempPuzzles = puzzles.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempPuzzles[newIndex] = puzzles[oldIndex];
                            $('.puzzle-title', diff[i].node).attr('data-puzzle-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        puzzles = tempPuzzles.slice(0);
                        setPuzzles();
                        break;
                    case 'answer_table':
                        var tempAnswers = resolutions.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempAnswers[newIndex] = resolutions[oldIndex];
                            $('.answer-description', diff[i].node).attr('data-answer-index', newIndex);
                            $('.answer-data', diff[i].node).attr('data-answer-index', newIndex);
                            $('.answer-reward', diff[i].node).attr('data-answer-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        resolutions = tempAnswers.slice(0);
                        setResolutions();
                        break;
                    case 'reward_table':
                        var tempRewards = rewards.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempRewards[newIndex] = rewards[oldIndex];
                            $('.reward-title', diff[i].node).attr('data-reward-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        rewards = tempRewards.slice(0);
                        setRewards();
                        break;
                    case 'hint_table':
                        var tempHints = hints.slice(0);
                        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
                            var oldIndex = diff[i].oldData;
                            var newIndex = diff[i].newData;
                            tempHints[newIndex] = hints[oldIndex];
                            $('.hint-title', diff[i].node).attr('data-hint-index', newIndex);
                            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
                        }
                        hints = tempHints.slice(0);
                        setHints();
                        break;
                }
            });
        });
    }

    function setUpScreenTable(screensArray, screenType) {
        let tempScreens = screensArray.slice(0);

        for ( let i = 0, ien = diff.length ; i < ien ; i++ ) {
            let oldIndex = diff[i].oldData;
            let newIndex = diff[i].newData;

            tempScreens[newIndex] = screens[oldIndex];

            $('.screen-title', diff[i].node).attr('data-screen-index', newIndex);
            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
        }

        screens = tempScreens.slice(0);
        setScreens(screenType);
    }

    // rearranges events table based on the table that is active
    function setUpEventsTable(eventArray) {
        var currentArray = getEventsArray(eventArray);
        var tempEvents = currentArray.slice(0);

        for ( var i = 0, ien = diff.length ; i < ien ; i++ ) {
            var oldIndex = diff[i].oldData;
            var newIndex = diff[i].newData;
            tempEvents[newIndex] = currentArray[oldIndex];
            $('.event-title', diff[i].node).attr('data-event-index', newIndex);
            $('.btn-open-delete-modal', diff[i].node).attr('data-id', newIndex);
        }

        // updates the appropriate events table
        switch(eventArray) {
            case 'experience_events':
                experienceEvents = tempEvents.slice(0);
                break;
            case 'm_stg_events':
                mStgEvents = tempEvents.slice(0);
                break;
            case 'stg_events':
                stgEvents = tempEvents.slice(0);
                break;
            default:
                break;
        }
        setEventsArray(eventArray);
    }

    // initialise data-tables which allow for the selection of rows via checkbox
    const $selectableDataTables = $('.data-table--select');
    if ($selectableDataTables.length) {
        $selectableDataTables.each((index, element) => {
            $(element).DataTable({
                responsive: true,
                autoWidth: false,
                order: [
                    [1, 'asc']
                ],
                bSort: false,
                bPaginate: false,
                bInfo: false
            });
        });
    }

    // initialise custom selectboxes
    const $selectBoxes = $('.select2');
    if ($selectBoxes.length) {
        $selectBoxes.select2({
            // minimumResultsForSearch: -1
            // placeholder: 'Select An Option'
        });
    }

    // initialise colour pickers
    const $colourPickers = $('.color-picker');
    if ($colourPickers.length) {
        $colourPickers.colorpicker();
    }

    // initialise radioboxes and checkboxes
    function generateCheckboxes() {
        $('body').not('.icheckbox_square-grey, .iradio_square-grey').find('input[type="radio"], input[type="checkbox"]').iCheck({
            checkboxClass: 'icheckbox_square-grey',
            radioClass: 'iradio_square-grey',
            increaseArea: '20%'
        });
    }

    generateCheckboxes();
    /*----- Extra Settings checkboxes ------*/
    // change for extra settings time restriction value and failure screen field based on time restriction checkbox
    const timeRestrictionValueField = $('#time_restriction_component input#time_restriction_value').parent().parent();
    const timeRestrictionTitleField = $('#time_restriction_component input#time_restriction_title');
    const failureScreenField = $('#failure_screen').parent().parent().parent();

    if ($('#time_restriction_component input#time_restriction').is(':checked')) {
        timeRestrictionValueField.show();
        timeRestrictionTitleField.show();
        failureScreenField.show();
    } else {
        timeRestrictionValueField.hide();
        timeRestrictionTitleField.hide();
        failureScreenField.hide();
    }

    $('#time_restriction_component input#time_restriction').on('ifChecked', function(){
        timeRestrictionValueField.show();
        timeRestrictionTitleField.show();
        failureScreenField.show();
    });
    $('#time_restriction_component input#time_restriction').on('ifUnchecked', function(){
        timeRestrictionValueField.hide();
        timeRestrictionTitleField.hide();
        failureScreenField.hide();
    });

    /* ----- Registration settings ----- */
    const singlePersonRego = $('#single_person_rego_component input#single_person_rego').parent().parent().parent();
    const collectDetails = $('#collect_details_component input#collect_details').parent().parent().parent();
    const teamPhotoReq = $('#team_photo_component input#team_photo').parent().parent().parent();
    const showTeamDetails = $('#show_details_component input#show_details').parent().parent().parent();

    if ($('#skip_registration_component input#skip_registration').is(':checked')) {
        singlePersonRego.hide();
        collectDetails.hide();
        teamPhotoReq.hide();
        showTeamDetails.hide();
    } else {
        singlePersonRego.show();
        collectDetails.show();
        teamPhotoReq.show();
        showTeamDetails.show();
    }

    $('#skip_registration_component input#skip_registration').on('ifChecked', function(){
        singlePersonRego.hide();
        collectDetails.hide();
        teamPhotoReq.hide();
        showTeamDetails.hide();
    });
    $('#skip_registration_component input#skip_registration').on('ifUnchecked', function(){
        singlePersonRego.show();
        collectDetails.show();
        teamPhotoReq.show();
        showTeamDetails.show();
    });
    // change for extra settings db address field based on send data checkbox
    const dbAddressField = $('#send_data_component input#db_address').parent();

    if ($('#send_data_component input#send_data').is(':checked')) {
        dbAddressField.show();
    } else {
        dbAddressField.hide();
    }

    $('#send_data_component input#send_data').on('ifChecked', function(){
        dbAddressField.show();
    });
    $('#send_data_component input#send_data').on('ifUnchecked', function(){
        dbAddressField.hide();
    });

    // toggle visibility for mailchimp details button based on send to email checkbox
    const mailchimpButton = $('.btn-open-mailchimp-modal');

    if ($('#send_emails_component input#send_emails').is(':checked')) {
        mailchimpButton.show();
    } else {
        mailchimpButton.hide();
    }

    $('#send_emails_component input#send_emails').on('ifChecked', function(){
        mailchimpButton.show();
    });
    $('#send_emails_component input#send_emails').on('ifUnchecked', function(){
        mailchimpButton.hide();
    });

    // toggle visibility for leaderboard builder button based on send to leaderboard checkbox
    const leaderboardButton = $('.btn-open-leaderboard-modal');

    if ($('#leaderboard_builder_component input#send_to_leaderboard').is(':checked')) {
        leaderboardButton.show();
    } else {
        leaderboardButton.hide();
    }

    $('#leaderboard_builder_component input#send_to_leaderboard').on('ifChecked', function(){
        leaderboardButton.show();
    });
    $('#leaderboard_builder_component input#send_to_leaderboard').on('ifUnchecked', function(){
        leaderboardButton.hide();
    });

    // toggle visibility for Dropbox token input field on send user media checkbox
    const dropboxTokenField = $('#send_user_media_component input#dropbox_token').parent();

    if ($('#send_user_media_component input#send_user_media').is(':checked')) {
        dropboxTokenField.show();
    } else {
        dropboxTokenField.hide();
    }

    $('#send_user_media_component input#send_user_media').on('ifChecked', function(){
        dropboxTokenField.show();
    });
    $('#send_user_media_component input#send_user_media').on('ifUnchecked', function(){
        dropboxTokenField.hide();
    });

    /******************************* GENERAL **********************************/

    // check if the value is empty or null
    function isEmpty(value) {
        return typeof value == 'string' && !value.trim() || typeof value == 'undefined' || value === null || $.isEmptyObject(value);
    }

    // do not remove modal-open class on body if there is modal opened
    $('.modal').on('hidden.bs.modal', (event) => {
        if ($('.modal.in').length) {
            $('body').addClass('modal-open');
        }
    });

    // need set up csrf token in order send request
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    function showNotification(message) {
        $('.js-notification').html(message);
        $('.js-notification').slideDown();
        setTimeout(function() {
            $('.js-notification').slideUp();
        }, 2000);
    }

    function showError(message) {
        $('.js-error').html(message);
        $('.js-error').slideDown();
        setTimeout(function() {
            $('.js-error').slideUp();
        }, 4000);
    }

    function removeA(arr) {
        let what, a = arguments,
            L = a.length,
            ax;
        while (L > 1 && arr.length) {
            what = a[--L];
            while ((ax = arr.indexOf(what)) !== -1) {
                arr.splice(ax, 1);
            }
        }
        return arr;
    }

    /***************************** FILE UPLOAD ********************************/

    // check if there is an existing file uploaded
    $('.file-upload-input').each((index, element) => {
        let target = $(element).data('target');
        if (target != '') {
            let targetValue = $('#' + target).val();
            if (isEmpty(targetValue)) {
                // no existing file uploaded
                // initial setup
                $(element).fileinput({
                    uploadUrl: "/files/upload",
                    uploadAsync: true,
                    maxFileCount: 1,
                    showBrowse: false,
                    browseOnZoneClick: true,
                    showCaption: false,
                    showRemove: false,
                    showUpload: false,
                    autoReplace: true,
                    uploadExtraData: function(previewId, index) {
                        return { key: index };
                    },
                    initialPreviewAsData: true,
                    previewSettings: {
                        image: { width: '100%', height: 'auto' },
                    }
                });
            } else {
                // there is an existing file stored
                // display the file in the preview
                $(element).fileinput({
                    uploadUrl: "/files/upload",
                    uploadAsync: true,
                    maxFileCount: 1,
                    showBrowse: false,
                    browseOnZoneClick: true,
                    showCaption: false,
                    showRemove: false,
                    showUpload: false,
                    autoReplace: true,
                    uploadExtraData: function(previewId, index) {
                        return { key: index };
                    },
                    initialPreviewAsData: true,
                    previewSettings: {
                        image: { width: '100%', height: 'auto' },
                    },
                    overwriteInitial: false,
                    initialPreview: [
                        targetValue
                    ],
                    initialPreviewFileType: 'image',
                    initialPreviewConfig: [
                        { url: '/files/delete', key: targetValue },
                    ]
                });
            }
        }
    });

    // check if there is an existing file uploaded
    $('.file-upload-input-multiple').each((index, element) => {
        let target = $(element).data('target');
        if (target != '') {
            let targetValue = $('#' + target).val();
            if (isEmpty(targetValue)) {
                // no existing file uploaded
                // initial setup
                $(element).fileinput({
                    uploadUrl: "/files/upload",
                    uploadAsync: true,
                    showBrowse: false,
                    browseOnZoneClick: true,
                    showCaption: false,
                    showRemove: false,
                    showUpload: false,
                    autoReplace: true,
                    previewClass: 'multiple-preview',
                    uploadExtraData: function(previewId, index) {
                        return { key: index };
                    },
                    initialPreviewAsData: true,
                    previewSettings: {
                        image: { width: '100%', height: 'auto' },
                    }
                });
            } else {
                // there is an existing file stored
                // display the file in the preview
                let imagePathArray = JSON.parse(targetValue);
                let previewConfigArray = [];
                $.each(imagePathArray, (index, element) => {
                    previewConfigArray.push({
                        url: '/files/delete',
                        key: element
                    });
                });
                $(element).fileinput({
                    uploadUrl: "/files/upload",
                    uploadAsync: true,
                    showBrowse: false,
                    browseOnZoneClick: true,
                    showCaption: false,
                    showRemove: false,
                    showUpload: false,
                    autoReplace: true,
                    previewClass: 'multiple-preview',
                    uploadExtraData: function(previewId, index) {
                        return { key: index };
                    },
                    initialPreviewAsData: true,
                    previewSettings: {
                        image: { width: '100%', height: 'auto' },
                    },
                    overwriteInitial: false,
                    initialPreview: imagePathArray,
                    initialPreviewFileType: 'image',
                    initialPreviewConfig: previewConfigArray
                });
            }
        }
    });

    // update the hidden field when upload successfully to store the path of the file
    $('.file-upload-input').on('fileuploaded', function(event, data, previewId, index) {
        let response = data.response;
        let imagePath = response['imagePath'];
        let target = $(event.currentTarget).data('target');
        $('#' + target).val(imagePath);
    });

    // automatically update the file
    $('.file-upload-input').on('fileloaded', function(event, file, previewId, index, reader) {
        $('.kv-file-upload', $('#' + previewId)).click();
    });
    
    // update the hidden field when delete successfully to empty the path of the file
    $('.file-upload-input').on('filedeleted', function(event, key) {
        let target = $(event.currentTarget).data('target');
        $('#' + target).val('');
    });

    // update the hidden field when upload successfully to store the path of the file
    $('.file-upload-input-multiple').on('fileuploaded', function(event, data, previewId, index) {
        updateImagesIndex($(event.currentTarget));
        let response = data.response;
        let imagePath = response['imagePath'];
        let target = $(event.currentTarget).data('target');
        let imagePathArray = [];
        if (!isEmpty($('#' + target).val())) {
            imagePathArray = JSON.parse($('#' + target).val());
        }
        imagePathArray.push(imagePath);
        $('#' + target).val(JSON.stringify(imagePathArray));
    });

    // automatically update the file
    $('.file-upload-input-multiple').on('fileloaded', function(event, file, previewId, index, reader) {
        $('.kv-file-upload', $('#' + previewId)).click();
    });

    // update the hidden field when delete successfully to empty the path of the file
    $('.file-upload-input-multiple').on('filedeleted', function(event, key) {
        updateImagesIndex($(event.currentTarget));
        let target = $(event.currentTarget).data('target');
        let imagePathArray = [];
        if (!isEmpty($('#' + target).val())) {
            imagePathArray = JSON.parse($('#' + target).val());
        }
        removeA(imagePathArray, key);
        $('#' + target).val(JSON.stringify(imagePathArray));
    });

    function updateImagesIndex(element) {
        $('.file-preview-initial.kv-preview-thumb:not(.file-uploading)', element.prev('.file-input')).each((index, element) => {
            $('.file-footer-caption', element).text('Index: ' + index);
        });
    }

    /****************************** ACCOUNT ***********************************/

    /*
     * check if it is on account page
     */

    /*
     * global variables
     */
    const accountView = $('.account-view');

    /****************************** COMPANY ***********************************/

    /*
     * check if it is on company page
     */

    /*
     * global variables
     */
    const companyView = $('.company-view');

    /*
     * temporary client side variable used to store the company data
     */
    let companyID = '';
    let companyData = {
        name: '',
        email: '',
        mailchimp_details: {},
        experiences: []
    }; 
    let companyExperiences = []; // array of avaiable games id
    let companyExperienceTableData = [];
    let loggedInCompanyId = $('.company-menu').attr('data-logged-in-company-id');

    /*
     * company modal opened
     */
    $(document).on('click', '.btn-open-company-modal', (event) => {
        let target = $(event.target);
        companyID = target.attr('data-company-index');
        // clear all tables
        $('table#company_employees_table').DataTable().clear().draw();
        $('table#company_experiences_table').DataTable().clear().draw();
        companyExperiences = [];

        if (companyID) {
            // must make POST request here when opening the modal to check company data as async requests through JS aren't supported
            $.ajax({
                type: 'POST',
                url: '/companies/data/' + companyID,
                success: function(response) {
                    companyData = JSON.parse(response);

                    // close the loader
                    $('#loader-wrapper').fadeOut('fast');
                    $('#company_name', $('#companyModal')).val(companyData.name); // text
                    $('#company_email', $('#companyModal')).val(companyData.email); // text

                    // mailchimp
                    let mailchimp = JSON.parse(companyData.mailchimp_details);

                    if (mailchimp) {
                        $('#mailchimp_address', $('#companyModal')).val(mailchimp.address); // text
                        $('#mailchimp_api', $('#companyModal')).val(mailchimp.api_key); // text
                        $('#list_id', $('#companyModal')).val(mailchimp.list_id); // text
                        $('#group_names', $('#companyModal')).tagsinput('removeAll');
                        if (!isEmpty(mailchimp.groups)) {
                            $.each(mailchimp.groups, (index, element) => {
                                $('#group_names', $('#companyModal')).tagsinput('add', element);
                            });
                        }
                    } else {
                        $('#mailchimp_address', $('#companyModal')).val(''); // text
                        $('#mailchimp_api', $('#companyModal')).val(''); // text
                        $('#list_id', $('#companyModal')).val(''); // text
                        $('#group_names', $('#companyModal')).tagsinput('removeAll');
                    }

                    // employees
                    let employees = companyData.employees;

                    if (employees.length > 0) {
                        $.each(employees, (key, value) => {
                            populateCompanyEmployeesTable(value.id, value);
                        });
                    }

                    // experiences
                    companyExperienceTableData = JSON.parse(companyData.experiences);
                    
                    if (companyExperienceTableData && companyExperienceTableData.length > 0) {
                        $.each(companyExperienceTableData, (key, value) => {
                            // populates the Experience Table for a company that shows what experiences a company has along with data assigned in the Companies Controller
                            populateCompanyExperiencesTable(key, value);
                            // populates the experience array with the ids that will be parsed back to the Companies table when the data is saved
                            companyExperiences.push(value.id);
                        });
                    }
                }
            });
        } else {
            // modal - create new 
            $('#company_name', $('#companyModal')).val(''); // text
            $('#company_email', $('#companyModal')).val(''); // text
            $('#mailchimp_address', $('#companyModal')).val(''); // text
            $('#mailchimp_api', $('#companyModal')).val(''); // text
            $('#list_id', $('#companyModal')).val(''); // text
            $('#group_names', $('#companyModal')).tagsinput('removeAll');
        }
    });

    function saveCompanyData(currentModal) {
        // no company modal is open, so a licensee is looking at the page
        if (currentModal.length === 0) {
            companyID = $('.company-view').attr('data-company-id');
            
            $('table#company_experiences_table tr').each(function (key, value) {
                if ($(value).attr('data-experience-id')) {
                    companyExperiences.push($(value).attr('data-experience-id'));
                }
            });
        }      

        let mailchimp = {};
        let mailchimpGroups = $('#group_names', currentModal).val().split(',');

        // empty the array if there is only an empty string
        if (mailchimpGroups.length === 1 & mailchimpGroups[0] === '') {
            mailchimpGroups = [];
        }

        mailchimp.address = $('#mailchimp_address', currentModal).val();;
        mailchimp.api_key = $('#mailchimp_api', currentModal).val();
        mailchimp.list_id = $('#list_id', currentModal).val()
        mailchimp.groups = mailchimpGroups;

        companyData.name = $('#company_name', currentModal).val();
        companyData.email = $('#company_email', currentModal).val();
        companyData.mailchimp_details = JSON.stringify(mailchimp);
        companyData.experiences = JSON.stringify(companyExperiences);

        delete companyData.employees; // required as we import companyData with an employees property, but an employees columns does not exist in the company table
        
        if (!companyID) {
            $.ajax({
                type: 'POST',
                url: '/companies',
                data: companyData,
                success: function(response) {
                    if (response.status == 'success') {
                        companyID = response.id;
                        showNotification(response.message);
                        // update company table with new entry company_listing_table
                        $('table#company_listing_table').DataTable().row.add([
                            '<a class="btn-open-company-modal company-name" href="#" data-toggle="modal" data-target="#companyModal" data-company-index="' + response.id +'">' + response.name + '</a>',
                            response.email,
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + response.id + ' data-type="company" data-name=' + response.name + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    } else {
                        ajaxValidationError(response.errors);
                    }
                },
                error: function(err) {
                    console.log('Error saving new company data: ', err);
                }
            });
        } else {
            $.ajax({
                type: 'PUT',
                url: '/companies/' + companyID,
                data: companyData,
                success: function(response) {
                    if (response.status == 'success') {
                        showNotification(response.message);
                    } else {
                        ajaxValidationError(response.errors);
                    }
                },
                error: function(err) {
                    console.log('Error encountered trying to update a company: ', err);
                }
            });
        }
    }

    // formats the data to be put into the experiences data table in the UI
    function setCompanyExperienceTableData(currentModal) {
        // allows for the assigning of the label color
        let labelTypes = [
            'default',
            'danger',
            'primary',
            'success',
            'warning',
        ];
        // reset companyExperiences
        companyExperiences = [];
        // reset the company experiences table
        $('table#company_experiences_table').DataTable().clear().draw();

        $('.select-item', currentModal).each((index, element) => {
            let newCompanyExperience = {
                id: '',
                row: '',
                name: '',
                status: '',
                labelType: '',
                updatedAt: '',
                createdAt: ''
            };

            if ($(element).is(':checked')) {
                newCompanyExperience.id = $(element).attr('data-experience-id');
                newCompanyExperience.row = $('#set_company_experiences_table tr[data-experience-id=' + newCompanyExperience.id + ']');
                newCompanyExperience.name = $('.company-experience-name', newCompanyExperience.row).text();
                newCompanyExperience.status = $('.company-experience-status', newCompanyExperience.row).text();
                newCompanyExperience.labelType = 'danger';
                newCompanyExperience.updatedAt = $('.company-experience-updated-at', newCompanyExperience.row).text();
                newCompanyExperience.createdAt = $('.company-experience-created-at', newCompanyExperience.row).text();

                for (var i = 0; i < labelTypes.length; i++) {
                    if ($('.company-experience-status', newCompanyExperience.row).attr('class').includes(labelTypes[i])) {
                        newCompanyExperience.labelType = labelTypes[i];
                    }
                }

                // add the newly assigned experience to the company experience array's
                companyExperiences.push(newCompanyExperience.id);
                companyExperienceTableData.push(newCompanyExperience);

                populateCompanyExperiencesTable(index, newCompanyExperience);
            }
        });
    }

    function populateCompanyEmployeesTable(key, value, update) {
        if (update) {
            $('table#company_employees_table .employee-first-name[data-employee-id=' + value.id + ']').text(value.first_name);
            $('table#company_employees_table .employee-last-name[data-employee-id=' + value.id + ']').text(value.last_name);
            $('table#company_employees_table .employee-group[data-employee-id=' + value.id + ']').text(value.group);
            $('table#company_employees_table .btn-open-delete-modal[data-id=' + value.id + ']').attr('data-name', value.first_name);
        } else {
            $('table#company_employees_table').DataTable().row.add([
                '<a class="btn-open-employee-modal employee-first-name" href="#" data-toggle="modal" data-target="#employeeModal" data-employee-index="' + key + '" data-employee-id="' + value.id + '">' + value.first_name + '</a>',
                '<span class="employee-last-name" data-employee-index=' + key + ' data-employee-id="' + value.id + '">' + value.last_name + '</span>',
                '<span class="employee-group" data-employee-index=' + key + ' data-employee-id="' + value.id + '">' + value.group + '</span>',
                '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + value.id + ' data-type="employee" data-name=' + value.first_name + '><span>Remove</span></a>'
            ]).draw();
        }
    }

    function populateCompanyExperiencesTable(key, value) {
        $('table#company_experiences_table').DataTable().row.add([
            value.name,
            '<span class="label label-' + value.labelType + ' company-experience-status">' + value.status + '</span>',
            value.updatedAt,
            value.createdAt,
            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="company-experience" data-name=' + value.name + '><span>Remove</span></a>'
        ]).draw();
    }

    // check all experiences that are assigned to the company in the list
    $(document).on('click', '.btn-open-set-company-experiences-modal', (event) => {
        // un-check all of the check boxes in the set experiences modal 
        $('table#set_company_experiences_table .select-item').iCheck('uncheck');
        // check all experience rows associated with the company
        $.each(companyExperiences, (key, value) => {
            $('table#set_company_experiences_table .select-item[data-experience-id=' + value + ']').iCheck('check');
        });
    });

    /****************************** EMPLOYEE ***********************************/
    // user that is assigned to a company
    /*
     * employee modal opened
     */
    const employeeView = $('.employee-view');

    let accessSettings = {};
    let employeeData = {};
    let employeeID = '';

    // Super admin or admin clicked on Employee name in Employees table
    $(document).on('click', '.btn-open-employee-modal', (event) => {
        let target = $(event.target);
        employeeID = target.attr('data-employee-id');
        // clear the default experience selection list
        setSelectionData($('#default_login_experience'), [{ 'none' : 'None' }]);

        if (!isEmpty(employeeID)) {
            $.ajax({
                type: 'POST',
                url: '/users/data/' + employeeID,
                success: function(response) {
                    employeeData = JSON.parse(response);

                    // close the loader
                    $('#loader-wrapper').fadeOut('fast');
                    accessSettings = getUserAccessSettings(employeeID);

                    $('#first_name', $('#employeeModal')).val(employeeData.first_name);
                    $('#last_name', $('#employeeModal')).val(employeeData.last_name);
                    $('#email', $('#employeeModal')).val(employeeData.email);
                    $('#contact_number', $('#employeeModal')).val(employeeData.contact_number);

                    if (employeeData.company) {
                        populateDefaultExperienceSelectionList(employeeData.company);
                    } else {
                        setSelectContent($('#default_login_experience'), 'none');
                    }

                    getUserBeaconLoginDetails(employeeData.beacon_login);
                    $('#ibeacon_proximity_parent', $('#beacon_login')).hide();
                    $('#ibeacon_duration_parent', $('#beacon_login')).hide();

                    // check if components are visible, if they are, populate them with the relevant data
                    if ($('.super', $('#employeeModal')).length) {
                        $('input[name="group"][value="' + employeeData.group + '"]', $('#employeeModal')).iCheck('check');
                        populateCompanySelectionList(employeeData.company);
                        
                        if (employeeData.access_options === null) {
                            employeeData.access_options = 'none';
                        }

                        // Editor Access Options
                        setSelectContent($('#user_access_options'), employeeData.access_options); // select
                    }
                },
                error: function(err) {
                    console.log('Error getting employee data: ', err);
                }
            });
        } else {
            // modal - create new employee
            employeeData = {};
            
            $('#first_name', $('#employeeModal')).val('');
            $('#last_name', $('#employeeModal')).val('');
            $('#email', $('#employeeModal')).val('');
            $('#contact_number', $('#employeeModal')).val('');
            $('#password', $('#employeeModal')).val('');
            // beacon
            $('#ibeacon_uuid', $('#beacon_login')).val(''); // parameter value
            $('#ibeacon_major', $('#beacon_login')).val(''); // parameter value
            $('#ibeacon_minor', $('#beacon_login')).val(''); // parameter value
            $('#ibeacon_proximity_parent', $('#beacon_login')).hide();
            $('#ibeacon_duration_parent', $('#beacon_login')).hide();

            // Get experiences based on company ID
            if (companyID) {
                populateDefaultExperienceSelectionList(companyID);
            }

            // check if components are visible, if they are, populate them with the relevant data
            if ($('.super', $('#employeeModal')).length) {
                $('input[name="group"]', $('#employeeModal')).val(['']);
                populateCompanySelectionList();
                // we use the super admin to get all of the access settings, then use the newEmployee bool to set them to none by default
                accessSettings = getUserAccessSettings('1', true);
            }
        }

        // if employee company is changed at any point
        $('#employee_company', $('#employeeModal')).on('change', function(event) {
            let newCompanyID = $('#employee_company', $('#employeeModal')).val();
            populateDefaultExperienceSelectionList(newCompanyID);
        });
    });

    function populateCompanySelectionList(setCompanyID) {
        $.ajax({
            type: 'POST',
            url: '/companies/list',
            success: function(response) {
                let companyList = JSON.parse(response);
                let companySelectionID = setCompanyID ? setCompanyID : companyData.id;

                // process the response data
                setSelectionData($('#employee_company', $('#employeeModal')), companyList);
                setSelectContent($('#employee_company', $('#employeeModal')), companySelectionID); // select                
            },
            error: function(err) {
                let errorObj = JSON.parse(err.responseText);
                console.log('Error retrieving companies: ', errorObj.message);
            }
        });
    }

        // beacon login assignment to user profile
    function getUserBeaconLoginDetails(loginBeacon) {
        let beacon = JSON.parse(loginBeacon);

        if (loginBeacon && loginBeacon.length > 0) {
            $('#ibeacon_uuid', $('#beacon_login')).val(beacon.ibeacon_uuid); // parameter value
            $('#ibeacon_major', $('#beacon_login')).val(beacon.ibeacon_major); // parameter value
            $('#ibeacon_minor', $('#beacon_login')).val(beacon.ibeacon_minor); // parameter value

        } else {
            $('#ibeacon_uuid', $('#beacon_login')).val(''); // parameter value
            $('#ibeacon_major', $('#beacon_login')).val(''); // parameter value
            $('#ibeacon_minor', $('#beacon_login')).val(''); // parameter value
        }
    }

    function setUserBeaconLoginDetails() {
        let beacon = {
            ibeacon_uuid: '',
            ibeacon_major: '',
            ibeacon_minor: '',
            ibeacon_proximity: '10',
            ibeacon_duration: ''
        };

        beacon.ibeacon_uuid = $('#ibeacon_uuid', $('#beacon_login')).val(); // parameter value
        beacon.ibeacon_major = $('#ibeacon_major', $('#beacon_login')).val(); // parameter value
        beacon.ibeacon_minor = $('#ibeacon_minor', $('#beacon_login')).val(); // parameter value

        beacon = JSON.stringify(beacon);
        return beacon;
    }

    function populateDefaultExperienceSelectionList(companyID) {
        $.ajax({
            type: 'POST',
            url: '/api/portal/game-list',
            data: { companyID: companyID }
        }).done((response) => {
            if (response.result === 'success') {
                let gamesList = response.games;

                setSelectionData($('#default_login_experience'), gamesList);
                
                if (employeeData.default_experience) {
                    getDefaultExperience(employeeData.default_experience);
                } else {
                    setSelectContent($('#default_login_experience'), 'none');
                }
            }
        }).fail((response) => {
            console.log(response);
        });
    }

    function getDefaultExperience(defaultExperience) {
        if (defaultExperience) {
            let defExp = JSON.parse(defaultExperience);

            setSelectContent($('#default_login_experience'), defExp.id);
        }
    }

    function setDefaultExperience() {
        let defaultExperienceObj = {
            id: 'none',
            game_name: 'None'
        };

        defaultExperienceObj.id = $('#default_login_experience').val();
        defaultExperienceObj.game_name = $('#default_login_experience option:selected').text();

        let defExpObj = JSON.stringify(defaultExperienceObj);

        return defExpObj;
    }

    // Get user access options
    function getUserAccessSettings(userId, newEmployee) {
        let userAccess = {};
        $.ajax({
            type: 'POST',
            url: '/access/data/' + userId,
            success: function(response) {
                userAccess = JSON.parse(response);

                if (userAccess !== null) {
                    // loop through the predefined user_access and assign the values from the user_access table to each key value pair
                    Object.keys(userAccess).forEach(function(userAccessKey, userAccessIndex) {
                        if (userAccessKey !== 'id' && userAccessKey !== 'user_id' && userAccessKey !== 'access_id' && userAccessKey !== 'created_at' && userAccessKey !== 'updated_at') {
                            userAccessName = userAccessKey + "_component";
                            if (userAccessKey === 'access_type') {
                                accessSettings[userAccessKey] = userAccess[userAccessKey];
                            } else {
                                accessSettings[userAccessName] = JSON.parse(userAccess[userAccessKey]);
                            }
                        }
                    });
                    if ($('#games-buttons').length) {
                        // games-buttons id belongs to <div> in views/pages/games.blade.php and is the parent container that both create and duplicate buttons belong to
                        // determines whether to display the create and/or duplicate buttons based on the access options for a user
                        setComponentAccess();
                    }
                }

                if (newEmployee) {
                    setSelectContent($('#user_access_options'), 'none'); // select
                } else {
                    setSelectContent($('#user_access_options'), accessSettings.access_type); // select

                }
            }
        });

        return userAccess;
    }

    function getAccessOption(option) {
        let accessOption = accessSettings[option];
        return accessOption;
    }

    // when the access option dropdown box changes option
    $('#user_access_options').on('change', function(event) {
        let option = $('#user_access_options').val();
        
        assignAccessSettings(option);
    });

    function assignAccessSettings(option) {
        let activateSaveButton = false; // hidden by default
        accessSettings.access_type = option;

        // set the editor access options based on a preset editor access option
        switch (option) {
        case 'full':
            Object.keys(accessSettings).forEach(function(key, index) {
                if (key !== 'access_type' && key !== 'experience_settings_component' && key !== 'extra_settings_component' && key !== 'save_button_component') {
                    let tempName = accessSettings[key].name;
                    setUserAccessOption(key, tempName, true, true);
                }
            });
            // display save button
            activateSaveButton = true;
            break;
        case 'custom':
            // find the index of the component in the array
            Object.keys(accessSettings).forEach(function(key, index) {
                let tempName = '';
                if (key !== 'access_type' && key !== 'experience_settings_component' && key !== 'extra_settings_component' && key !== 'save_button_component') {
                    tempName = accessSettings[key].name;
                    setUserAccessOption(key, tempName, accessSettings[key].visible, accessSettings[key].editable);
                    // if any components are editable, make the save button appear
                    if (accessSettings[key].editable) {
                        activateSaveButton = true;
                    }
                } else if (key === 'experience_settings_component' || key === 'extra_settings_component') {
                    tempName = accessSettings[key].name;
                    setUserAccessOption(key, tempName, true, true);
                }
            });
            break;
        case 'view_only':
            Object.keys(accessSettings).forEach(function(key, index) {
                let tempName = '';
                if (key !== 'access_type' && key !== 'experience_settings_component' && key !== 'extra_settings_component' && key !== 'save_button_component') {
                    tempName = accessSettings[key].name;
                    setUserAccessOption(key, tempName, true, false);
                } else if (key === 'experience_settings_component' || key === 'extra_settings_component') {
                    tempName = accessSettings[key].name;
                    setUserAccessOption(key, tempName, true, true);
                }
            });
            activateSaveButton = false;
            break;
        case 'none':
            Object.keys(accessSettings).forEach(function(key, index) {
                if (key !== 'access_type') {
                    let tempName = accessSettings[key].name;
                    setUserAccessOption(key, tempName, false, false);
                }
            });
            activateSaveButton = false;
            break;
        default:
            console.error('Error selecting preset access option');
            break;
        }

        setUpAccessOptionsTable();
    }

    // changes editor component values of a single component available to the user
    function setUserAccessOption(component, name, visible, editable) {
        accessSettings[component].name = name;
        accessSettings[component].visible = visible;
        accessSettings[component].editable = editable;
    }

    // sets up the access options table when the user profile page is openned
    function setUpAccessOptionsTable() {
        let accessType = accessSettings.access_type;
        // reset the inner table every time when modal is opened
        $('table#access_options_table').DataTable().clear().draw();

        $.each(accessSettings, (key, value) => {
            if (key !== 'access_type' && key !== 'experience_settings_component' && key !== 'extra_settings_component' && key !== 'save_button_component') {
                let label = value.name;
                if (!isEmpty(value)) {
                    $('table#access_options_table').DataTable().row.add([
                        '<span' + key + '>' + value.name + '</span>',
                        '<input type="checkbox" id= "visible_' + key + '_checkbox" class= "access_option" name= "' + key + '" value=' + value.visible + ' ' + (value.visible ? 'checked' : '') + ' ' + (accessType === 'custom' ? '' : 'disabled' ) + '></input>',
                        '<input type="checkbox" id= "editable_' + key + '_checkbox" class= "access_option" name= "' + key + '" value=' + value.editable + ' ' + (value.editable ? 'checked' : '') + ' ' + (accessType === 'custom' ? '' : 'disabled' ) + '></input>'
                    ]).draw();
                }
            }
        });
    }

    // toggle checkbox value of an access option
    $(document).on('change', '[class=access_option]', (event) => {
        let target = $(event.currentTarget);
        let accessObject = getAccessOption(target[0].name); // get the component from accessSettings Object
        let component = target[0].name;
        let name = accessObject.name;
        let visible = accessObject.visible;
        let editable = accessObject.editable;

        // get the current value of the component we're about to change
        if (target.is(':checked')) {
            if (target[0].id.includes('visible')) {
                visible = true;
            } else {
                editable = true;
            }
        } else {
            if (target[0].id.includes('visible')) {
                visible = false;
            } else {
                editable = false;
            }
        }

        setUserAccessOption(component, name, visible, editable);

        // determine whether save button is still needed or not
        if (editable) {
            setUserAccessOption('save_button_component', 'Save Button', true, true);
        } else {
            let keepSaveButton = false;
            // check all of the objects within the access_options object and see if any of them are still editable
            Object.keys(accessSettings).forEach(function(key, index) {
                if (key !== 'access_type' && key !== 'experience_settings_component' && key !== 'extra_settings_component' && key !== 'save_button_component') {
                    if (accessSettings[key].editable === true) {
                        keepSaveButton = true;
                    }
                }
            });
            // if not, turn off the save_button_component
            if (keepSaveButton === false) {
                setUserAccessOption('save_button_component', 'Save Button', false, false);
            }
        }
    });
    
    // assign hidden and disabled classes to html components when their access setting is assigned
    function setComponentAccess() {
        Object.keys(accessSettings).forEach(function(accessSettingKey, accessSettingIndex) {
            if (accessSettingKey !== 'access_type') {
                let visible = accessSettings[accessSettingKey].visible;
                let editable = accessSettings[accessSettingKey].editable;
                let tempComponent = "div#"+accessSettingKey;

                // create and duplicate experience buttons are not within divs, so we need to do specific checks and change the reference for them
                if (accessSettingKey === 'create_experience_component') {
                    tempComponent = "a#"+accessSettingKey;
                }

                if (accessSettingKey === 'duplicate_experience_component') {
                    tempComponent = "button#"+accessSettingKey;
                }
                
                if (!visible) {
                    $(tempComponent).hide();
                } else {
                    $(tempComponent).show();
                }

                $(tempComponent + ' *').prop('disabled', !editable);

                // additional function for disabling/enabling wysiwyg fields
                if (accessSettingKey === 'terms_and_conditions_component' || accessSettingKey === 'help_screen_component' || accessSettingKey === 'success_screen_component' || accessSettingKey === 'failure_screen_component') {
                    changeWYSIWYGState(accessSettingKey, editable);
                }
                // hide create and duplicate buttons based on editable value too
                if (accessSettingKey === 'create_experience_component' || accessSettingKey === 'duplicate_experience_component') {
                    if (!editable) {
                        $(tempComponent).hide();
                    } else {
                        $(tempComponent).show();
                    }
                }
            }
            
        });
    }

    // disables/enables the wysihtml5 editor field
    function changeWYSIWYGState(component, bool) {
        let wysiwygComponent = $("#" + component).find('.wysihtml5-sandbox');
        let wysiwygEditor = wysiwygComponent.contents().find('.wysihtml5-editor');

        wysiwygEditor.prop("contentEditable", bool);
    }

    /******************** DUPLICATION-MAILCHIMP MERGE TAGS ***************************/
    const $mailchimpMergeTagDuplicateSelect = $('.duplicate-mailchimp-merge-tag');
    const $mailchimpMergeTagDuplicateModal = $('#duplicateMailchimpMergeTagConfirm');
    const $duplicateMailchimpMergeTagButton = $('.btn-merge-tag-duplicate');


    if ($mailchimpMergeTagDuplicateSelect.length) {
        $mailchimpMergeTagDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $mailchimpMergeTagDuplicateModal.modal('show');
                $('.modal-body', $mailchimpMergeTagDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateMailchimpMergeTagButton.attr('data-merge-tag-id', target.val());
            }
        });
    }

    // reset event duplicate select when close the modal
    $mailchimpMergeTagDuplicateModal.on('hide.bs.modal', (event) => {
        $mailchimpMergeTagDuplicateSelect.val('');
        $mailchimpMergeTagDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate event
    if ($duplicateMailchimpMergeTagButton.length) {
        $duplicateMailchimpMergeTagButton.on('click', (event) => {
            let mergeTagId = $(event.target).attr('data-merge-tag-id');
            let tempMergeTags = getMailchimpMergeTags();

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/mergeTags/' + columnId,
                success: function(response) {
                    mergeTagIndex = tempMergeTags.length;
                    mergeTags = JSON.parse(response.merge_tags);
                    $('table#mailchimp_merge_tags_table').DataTable().row.add([
                    mergeTagIndex,
                    '<a class="btn-open-mailchimp-merge-tag-modal mailchimp-merge-tag-description" href="#" data-toggle="modal" data-target="#mailchimpMergeTagModal" data-mailchimp-merge-tag-index=' + key + '>' + value.description + '</a>',
                    '<span class="mailchimp-merge-tag" data-mailchimp-merge-tag-index=' + key + '>' + value.tag + '</span>',
                    '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="mailchimp_merge_tag" data-name=' + value.description + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                ]).draw();
                    // store the current merge tag value
                    addMailchimpMergeTag(mergeTagIndex); // merge tag{} => merge tags[{}][index]
                    saveMailchimpMergeTags(mergeTags); // merge tags[{}] => mailchimp
                    $mailchimpMergeTagDuplicateSelect.modal('hide');
                    showNotification('Merge tag has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATION-LEADERBOARD COLUMNS ***************************/
    const $colLBDuplicateSelect = $('.duplicate-leaderboard-column');
    const $colLBDuplicateModal = $('#duplicateLeaderboardColumnConfirm');
    const $duplicateColLBButton = $('.btn-leaderboard-column-duplicate');


    if ($colLBDuplicateSelect.length) {
        $colLBDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $colLBDuplicateModal.modal('show');
                $('.modal-body', $colLBDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateColLBButton.attr('data-leaderboard-column-id', target.val());
            }
        });
    }

    // reset event duplicate select when close the modal
    $colLBDuplicateModal.on('hide.bs.modal', (event) => {
        $colLBDuplicateSelect.val('');
        $colLBDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate event
    if ($duplicateColLBButton.length) {
        $duplicateColLBButton.on('click', (event) => {
            let columnId = $(event.target).attr('data-leaderboard-column-id');
            let tempColumns = getLeaderboardColumns();

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/column/' + columnId,
                success: function(response) {
                    lbColumnIndex = tempColumns.length;
                    lbColumn = JSON.parse(response.column);
                    // need to change table id based on where the admin is
                    $('table#leaderboard_columns_table').DataTable().row.add([
                        lbColumnIndex,
                        '<a class="btn-open-column-variable-modal leaderboard-column-title" href="#" data-toggle="modal" data-target="#leaderboardColumnModal" data-leaderboard-column-index=' + lbColumnIndex + '>' + column.columnTitle + '</a>',
                        '<span class="leaderboard-sorting-title" data-leaderboard-column-index=' + lbColumnIndex + '>' + column.sortingTitle + '</span>',
                        '<span class="leaderboard-column-variable" data-leaderboard-column-index=' + lbColumnIndex + '>' + column.columnVariable + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + lbColumnIndex + ' data-type="lb_delete" data-name=' + column.columnTitle + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                    // store the current leaderboard column value
                    setLeaderboardColumn(lbColumnIndex); // lbColumn{} => lbColumns[{}][index]
                    setLeaderboardColumns(lbColumns); // lbColumns[{}] => leaderboard
                    $colLBDuplicateModal.modal('hide');
                    showNotification('Column has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATION-MASTER STAGE ***************************/
    const $masterStageDuplicateSelect = $('.duplicate-master-stage');
    const $masterStageDuplicateModal = $('#duplicateMasterStageConfirm');
    const $duplicateMasterStageButton = $('.btn-master-stage-duplicate');

    if ($masterStageDuplicateSelect.length) {
        $masterStageDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $masterStageDuplicateModal.modal('show');
                $('.modal-body', $masterStageDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateMasterStageButton.attr('data-master-stage-id', target.val());
            }
        });
    }

    // reset master stage duplicate select when close the modal
    $masterStageDuplicateModal.on('hide.bs.modal', (event) => {
        $masterStageDuplicateSelect.val('');
        $masterStageDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate theme
    if ($duplicateMasterStageButton.length) {
        $duplicateMasterStageButton.on('click', (event) => {
            let masterStageId = $(event.target).attr('data-master-stage-id');

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/master-stage/' + masterStageId,
                success: function(response) {
                    masterStageIndex = masterStages.length;
                    masterStage = JSON.parse(response.master_stage);
                    $('table#master_stage_table').DataTable().row.add([
                        masterStageIndex,
                        '<a class="btn-open-master-stage-modal master-stage-title" href="#" data-toggle="modal" data-target="#masterStageModal" data-master-stage-index=' + masterStageIndex + '>' + masterStage.title + '</a>',
                        '<span class="master-stage-type" data-master-stage-index=' + masterStageIndex + '>' + masterStage.structure + '</span>',
                        '<span class="master-stage-minimum-requirements" data-master-stage-index=' + masterStageIndex + '>' + masterStage.minimum_requirements + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-type="master stage" data-id=' + masterStageIndex + ' data-name=' + masterStage.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                    setMasterStage(masterStageIndex);
                    setMasterStages();
                    $masterStageDuplicateModal.modal('hide');
                    showNotification('Master stage has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATION - EVENTS ***************************/
    const $eventDuplicateSelect = $('.duplicate-event');
    const $eventDuplicateModal = $('#duplicateEventConfirm');
    const $duplicateEventButton = $('.btn-event-duplicate');


    if ($eventDuplicateSelect.length) {
        $eventDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $eventDuplicateModal.modal('show');
                $('.modal-body', $eventDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateEventButton.attr('data-event-id', target.val());
            }
        });
    }

    // reset event duplicate select when close the modal
    $eventDuplicateModal.on('hide.bs.modal', (event) => {
        $eventDuplicateSelect.val('');
        $eventDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate event
    if ($duplicateEventButton.length) {
        $duplicateEventButton.on('click', (event) => {
            let eventId = $(event.target).attr('data-event-id');
            let tempEvents = getEventsArray(currentEventsArray);

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/event/' + eventId,
                success: function(response) {
                    eventIndex = tempEvents.length;
                    eventObj = JSON.parse(response.eventObj);
                    // need to change table id based on where the admin is
                    $('table#'+eventTable).DataTable().row.add([
                        eventIndex,
                        '<a class="btn-open-event-modal event-title" href="#" data-toggle="modal" data-target="#eventModal" data-event-index=' + eventIndex + '>' + eventObj.title + '</a>',
                        '<span class="event-trigger" data-event-index=' + eventIndex + '>' + eventObj.trigger + '</span>',
                        '<span class="event-event" data-event-index=' + eventIndex + '>' + eventObj.event_type + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + eventIndex + ' data-type="event" data-name=' + eventObj.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                    // store the current event value
                    setEventForArray(currentEventsArray, eventIndex);
                    setEventsArray(currentEventsArray);
                    $eventDuplicateModal.modal('hide');
                    showNotification('Event has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATION-STAGE ***************************/
    const $stageDuplicateSelect = $('.duplicate-stage');
    const $stageDuplicateModal = $('#duplicateStageConfirm');
    const $duplicateStageButton = $('.btn-stage-duplicate');

    if ($stageDuplicateSelect.length) {
        $stageDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $stageDuplicateModal.modal('show');
                $('.modal-body', $stageDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateStageButton.attr('data-stage-id', target.val());
            }
        });
    }

    // reset stage duplicate select when close the modal
    $stageDuplicateModal.on('hide.bs.modal', (event) => {
        $stageDuplicateSelect.val('');
        $stageDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate stage
    if ($duplicateStageButton.length) {
        $duplicateStageButton.on('click', (event) => {
            let stageId = $(event.target).attr('data-stage-id');

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/stage/' + stageId,
                success: function(response) {
                    stageIndex = stages.length;
                    stage = JSON.parse(response.stage);
                    $('table#stage_table').DataTable().row.add([
                        stageIndex,
                        '<a class="btn-open-stage-modal stage-title" href="#" data-toggle="modal" data-target="#stageModal" data-stage-index=' + stageIndex + '>' + stage.title + '</a>',
                        '<span class="stage-display-title" data-stage-index=' + stageIndex + '>' + stage.display_title + '</span>',
                        '<span class="stage-game-mode" data-stage-index=' + stageIndex + '>' + stage.game_mode + '</span>',
                        '<span class="stage-unlock-on" data-stage-index=' + stageIndex + '>' + stage.unlock_on + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + stageIndex + ' data-type="stage" data-name=' + stage.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                    setStage(stageIndex);
                    setStages();
                    setMasterStage(masterStageIndex);
                    setMasterStages();
                    $stageDuplicateModal.modal('hide');
                    showNotification('Stage has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATION-PUZZLE ***************************/
    const $puzzleDuplicateSelect = $('.duplicate-puzzle');
    const $puzzleDuplicateModal = $('#duplicatePuzzleConfirm');
    const $duplicatePuzzleButton = $('.btn-puzzle-duplicate');

    if ($puzzleDuplicateSelect.length) {
        $puzzleDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $puzzleDuplicateModal.modal('show');
                $('.modal-body', $puzzleDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicatePuzzleButton.attr('data-puzzle-id', target.val());
            }
        });
    }

    // reset puzzle duplicate select when close the modal
    $puzzleDuplicateModal.on('hide.bs.modal', (event) => {
        $puzzleDuplicateSelect.val('');
        $puzzleDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate puzzle
    if ($duplicatePuzzleButton.length) {
        $duplicatePuzzleButton.on('click', (event) => {
            let puzzleId = $(event.target).attr('data-puzzle-id');

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/puzzle/' + puzzleId,
                success: function(response) {
                    puzzleIndex = puzzles.length;
                    puzzle = JSON.parse(response.puzzle);
                    $('table#puzzle_table').DataTable().row.add([
                        puzzleIndex,
                        '<a class="btn-open-puzzle-modal puzzle-title" href="#" data-toggle="modal" data-target="#puzzleModal" data-puzzle-index=' + puzzleIndex + '>' + puzzle.title + '</a>',
                        '<span class="puzzle-type" data-puzzle-index=' + puzzleIndex + '>' + puzzle.type + '</span>',
                        '<span class="puzzle-num-hints" data-puzzle-index=' + puzzleIndex + '>' + getHints().length + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + puzzleIndex + ' data-type="puzzle" data-name=' + puzzle.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>',
                        // unlock on interaction
                        '<span class="puzzle-unlock-on" data-puzzle-index=' + puzzleIndex + '>' + puzzle.unlock_on_interaction + '</span>'
                    ]).draw();
                    // store the current puzzle value
                    setPuzzle(puzzleIndex);
                    setPuzzles();
                    setStage(stageIndex);
                    setStages();
                    setMasterStage(masterStageIndex);
                    setMasterStages();
                    $puzzleDuplicateModal.modal('hide');
                    showNotification('Puzzle has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATION-ANSWER ***************************/
    const $answerDuplicateSelect = $('.duplicate-answer');
    const $answerDuplicateModal = $('#duplicateAnswerConfirm');
    const $duplicateAnswerButton = $('.btn-answer-duplicate');

    if ($answerDuplicateSelect.length) {
        $answerDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $answerDuplicateModal.modal('show');
                $('.modal-body', $answerDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateAnswerButton.attr('data-answer-id', target.val());
            }
        });
    }

    // reset answer duplicate select when close the modal
    $answerDuplicateModal.on('hide.bs.modal', (event) => {
        $answerDuplicateSelect.val('');
        $answerDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate answer
    if ($duplicateAnswerButton.length) {
        $duplicateAnswerButton.on('click', (event) => {
            let answerId = $(event.target).attr('data-answer-id');

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/answer/' + answerId,
                success: function(response) {
                    answerIndex = resolutions.length;

                    resolution = JSON.parse(response.answer);

                    $('table#answer_table').DataTable().row.add([
                        answerIndex,
                        '<a class="btn-open-answer-modal answer-description" href="#" data-toggle="modal" data-target="#answerModal" data-answer-index=' + answerIndex + '>' + resolution.description + '</a>',
                        '<span class="answer-data" data-answer-index=' + answerIndex + '>' + resolution.answerData + '</span>',
                        '<span class="answer-reward" data-answer-index=' + answerIndex + '>' + resolution.rewards + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + answerIndex + ' data-type="answer" data-name=' + resolution.description + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();    
                    // store the current answer value
                    setResolution(answerIndex);
                    setResolutions();
                    setPuzzle(puzzleIndex);
                    setPuzzles();
                    setStage(stageIndex);
                    setStages();
                    setMasterStage(masterStageIndex);
                    setMasterStages();
                    $answerDuplicateModal.modal('hide');
                    showNotification('Answer has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATION-REWARD ***************************/
    const $rewardDuplicateSelect = $('.duplicate-reward');
    const $rewardDuplicateModal = $('#duplicateRewardConfirm');
    const $duplicateRewardButton = $('.btn-reward-duplicate');

    if ($rewardDuplicateSelect.length) {
        $rewardDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $rewardDuplicateModal.modal('show');
                $('.modal-body', $rewardDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateRewardButton.attr('data-reward-id', target.val());
            }
        });
    }

    // reset reward duplicate select when close the modal
    $rewardDuplicateModal.on('hide.bs.modal', (event) => {
        $rewardDuplicateSelect.val('');
        $rewardDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate reward
    if ($duplicateRewardButton.length) {
        $duplicateRewardButton.on('click', (event) => {
            let rewardId = $(event.target).attr('data-reward-id');

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/reward/' + rewardId,
                success: function(response) {
                    rewardIndex = rewards.length;
                    reward = JSON.parse(response.reward);
                    $('table#reward_table').DataTable().row.add([
                        rewardIndex,
                        '<a class="btn-open-reward-modal reward-title" href="#" data-toggle="modal" data-target="#rewardModal" data-reward-index=' + rewardIndex + '>' + reward.title + '</a>',
                        '<span class="reward-type" data-reward-index=' + rewardIndex + '>' + reward.type + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + rewardIndex + ' data-type="reward" data-name=' + reward.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();    
                    // store the current reward value
                    setReward(rewardIndex);
                    setRewards();
                    setPuzzle(puzzleIndex);
                    setPuzzles();
                    setStage(stageIndex);
                    setStages();
                    setMasterStage(masterStageIndex);
                    setMasterStages();
                    $rewardDuplicateModal.modal('hide');
                    showNotification('Reward has been duplicated successfully.');
                }
            })
        });
    }

    /******************** DUPLICATEION-HINT ***************************/
    const $hintDuplicateSelect = $('.duplicate-hint');
    const $hintDuplicateModal = $('#duplicateHintConfirm');
    const $duplicateHintButton = $('.btn-hint-duplicate');

    if ($hintDuplicateSelect.length) {
        $hintDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $hintDuplicateModal.modal('show');
                $('.modal-body', $hintDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $duplicateHintButton.attr('data-hint-id', target.val());
            }
        });
    }

    // reset hint duplicate select when close the modal
    $hintDuplicateModal.on('hide.bs.modal', (event) => {
        $hintDuplicateSelect.val('');
        $hintDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate hint
    if ($duplicateHintButton.length) {
        $duplicateHintButton.on('click', (event) => {
            let hintId = $(event.target).attr('data-hint-id');

            $.ajax({
                type: 'POST',
                url: '/games/duplicate/hint/' + hintId,
                success: function(response) {
                    hintIndex = hints.length;
                    hint = JSON.parse(response.hint);
                    $('table#hint_table').DataTable().row.add([
                        hintIndex,
                        '<a class="btn-open-hint-modal hint-title" href="#" data-toggle="modal" data-target="#hintModal" data-hint-index=' + hintIndex + '>' + hint.title + '</a>',
                        '<span class="hint-penalty-type" data-hint-index=' + hintIndex + '>' + hint.penalty_type + '</span>',
                        '<span class="hint-penalty-value" data-hint-index=' + hintIndex + '>' + hint.penalty_value + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + hintIndex + ' data-type="hint" data-name=' + hint.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();    
                    // store the current hint value
                    setHint(hintIndex);
                    setHints();
                    setPuzzle(puzzleIndex);
                    setPuzzles();
                    setStage(stageIndex);
                    setStages();
                    setMasterStage(masterStageIndex);
                    setMasterStages();
                    $hintDuplicateModal.modal('hide');
                    showNotification('Hint has been duplicated successfully.');
                }
            })
        });
    }

    /************************* DUPLICATEION-THEME *****************************/

    // pass value to theme duplicate modal programmatically
    const $themeDuplicateSelect = $('.theme-copy');
    const $themeDuplicateModal = $('#duplicateThemeConfirm');
    if ($themeDuplicateSelect.length) {
        $themeDuplicateSelect.on('change', (event) => {
            let target = $(event.currentTarget);
            if (!isEmpty(target.val())) {
                $themeDuplicateModal.modal('show');
                $('.modal-body', $themeDuplicateModal).html('<p>' + $(':selected', target).text() + '</p>');
                $('.btn-theme-duplicate', $themeDuplicateModal).attr('data-theme-id', target.val());
                $('.btn-theme-duplicate', $themeDuplicateModal).attr('data-theme', target.attr('id'));
            }
        });
    }

    // reset theme duplicate select when close the modal
    $themeDuplicateModal.on('hide.bs.modal', (event) => {
        $themeDuplicateSelect.val('');
        $themeDuplicateSelect.select2().trigger('change');
    });

    // ajax request to duplicate theme
    const $duplicateThemeButton = $('.btn-theme-duplicate');
    if ($duplicateThemeButton.length) {
        $duplicateThemeButton.on('click', (event) => {
            let themeId = $(event.target).attr('data-theme-id');
            let theme = $(event.target).attr('data-theme');

            $.ajax({
                type: 'POST',
                url: '/themes/duplicate/' + themeId,
                success: function(response) {
                    $.each(response, (key, value) => {
                        if (value !== null && key !== 'background_image_file' && key !== 'background_image') {
                            $('.' + theme + ' #' + key).val(value).trigger('change');
                        }
                        if (value !== null && key !== 'logo_image_file' && key !== 'logo_image') {
                            $('.' + theme + ' #' + key).val(value).trigger('change');
                        }
                    });

                    // theme -> background_image
                    // game -> master_theme_background_image
                    // master stage -> master_stage_theme_background_image
                    // stage -> stage_theme_background_image
                    if (theme == 'theme') {
                        $('#background_image').val(response.background_image);
                        $('#logo_image').val(response.logo_image);
                    } else {
                        $('#' + theme + '_background_image').val(response.background_image);
                        $('#' + theme + '_logo_image').val(response.logo_image);
                    }

                    fileUploadRefreshSingle($('.' + theme + ' .file-upload-input'), response.logo_image);
                    fileUploadRefreshSingle($('.' + theme + ' .file-upload-input'), response.background_image);
                    $themeDuplicateModal.modal('hide');
                    showNotification('Theme has been duplicated successfully.');
                }
            })
        });
    }

    function fileUploadRefreshSingle(element, imagePath) {
        element.fileinput('destroy').fileinput({
            uploadUrl: "/files/upload",
            uploadAsync: true,
            maxFileCount: 1,
            showBrowse: false,
            browseOnZoneClick: true,
            showCaption: false,
            showRemove: false,
            showUpload: false,
            autoReplace: true,
            uploadExtraData: function(previewId, index) {
                return { key: index };
            },
            initialPreviewAsData: true,
            previewSettings: {
                image: { width: '100%', height: 'auto' },
            },
            overwriteInitial: false,
            initialPreview: [
                imagePath
            ],
            initialPreviewFileType: 'image',
            initialPreviewConfig: [
                { url: '/files/delete', key: imagePath },
            ]
        });
    }

    function fileUploadRefreshMultiple(element, imagePathArray) {
        let previewConfigArray = [];
        $.each(imagePathArray, (index, element) => {
            previewConfigArray.push({
                url: '/files/delete',
                key: element
            });
        });
        element.fileinput('destroy').fileinput({
            uploadUrl: "/files/upload",
            uploadAsync: true,
            showBrowse: false,
            browseOnZoneClick: true,
            showCaption: false,
            showRemove: false,
            showUpload: false,
            autoReplace: true,
            previewClass: 'multiple-preview',
            uploadExtraData: function(previewId, index) {
                return { key: index };
            },
            initialPreviewAsData: true,
            previewSettings: {
                image: { width: '100%', height: 'auto' },
            },
            overwriteInitial: false,
            initialPreview: imagePathArray,
            initialPreviewFileType: 'image',
            initialPreviewConfig: previewConfigArray
        });
        updateImagesIndex(element);
    }

    function fileUploadResetSingle(element) {
        element.fileinput('destroy').fileinput({
            uploadUrl: "/files/upload",
            uploadAsync: true,
            maxFileCount: 1,
            showBrowse: false,
            browseOnZoneClick: true,
            showCaption: false,
            showRemove: false,
            showUpload: false,
            autoReplace: true,
            uploadExtraData: function(previewId, index) {
                return { key: index };
            },
            initialPreviewAsData: true,
            previewSettings: {
                image: { width: '100%', height: 'auto' },
            }
        });
    }

    function fileUploadResetMultiple(element) {
        element.fileinput('destroy').fileinput({
            uploadUrl: "/files/upload",
            uploadAsync: true,
            showBrowse: false,
            browseOnZoneClick: true,
            showCaption: false,
            showRemove: false,
            showUpload: false,
            autoReplace: true,
            previewClass: 'multiple-preview',
            uploadExtraData: function(previewId, index) {
                return { key: index };
            },
            initialPreviewAsData: true,
            previewSettings: {
                image: { width: '100%', height: 'auto' },
            }
        });
    }

    // notification effect
    const $notification = $('.notification');
    if ($notification.length) {
        $notification.slideDown();
        setTimeout(function() {
            $notification.slideUp();
        }, 2000);
    }

    // set wysiwyg field content
    function setWysiwygContent(element, htmlContent) {       
        if (htmlContent === undefined) {
            htmlContent = '';
        }
        element.html(htmlContent);
        element.next('iframe').contents().find('.wysihtml5-editor').html(htmlContent);
    }

    // populate a selection field with the required data
    function setSelectionData(element, data) {
        element.empty();
        element.select2({ data: data });
    }
    

    // set select field content
    function setSelectContent(element, value) {
        element.val(value).select2().trigger('change');
    }

    function setSelectContentByText(element, array, text) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].text === text) {
                element.prop('selectedIndex', i).select2().trigger('change');
            }
        }
    }

    /******************************** GAME ************************************/

    /*
     * check if it is on create/edit game page
     */

    /*
     * global variables
     */
    const gameView = $('.game-view');

    /*
     * temporary client side variable used to store the game data
     */
    let gameData = {}; // object
    let gameStatus = {
        status: 'none',
        statusText: 'None',
        labelType: 'danger'
    };
    let helpScreen = {html: '', bg: ''}; // feedback object
    let successScreen = {html: '', bg: ''}; // feedback object
    let failureScreen = {html: '', bg: ''}; // feedback object
    let screens = []; // array of screen object
    let screen = {}; // object
    let leaderboard = {}; // leaderboard builder object
    let lbColumns = []; // columns that belong to the leaderboard obj table
    let lbColumn = {}; // column obj
    let columnDataOptions = []; // Game variable options for assigning the column variable
    let lbColumnIndex; // int, stores the current open leaderboard column index 
    let masterStages = []; // array of master stage object
    let masterStage = {}; // object
    let timerList = []; // array of all timers available
    let timerTriggerList = []; // array of specified timers associated in a master stage/stageavailable

    /* -- Events variables -- */
    let currentEventsArray = 'experience_events';
    let eventTable = 'experience_events';// name of the current event table
    let eventObj = {}; // object
    let experienceEvents = []; // array for Experience event objects
    let mStgEvents = []; // array for Master Stage event objects
    let stgEvents = []; // array for Stage event objects

    let stages = []; // array of stage object
    let stage = {}; // object
    let puzzles = []; // array of puzzle object
    let puzzle = {}; // object
    let hints = []; // array of hint object
    let hint = {}; // object
    let rewards = []; // array of reward object
    let reward = {}; // object 
    let resolutions = []; // array of resolution object
    let resolution = {}; // object
    let themeSettings = {};

    let masterStageIndex; // int, store the current opened master stage index
    let stageIndex; // int, store the current opened stage index
    let puzzleIndex; // int, store the current opened puzzle index

    function getGameData(gameId) {
        $.ajax({
            type: 'POST',
            url: '/games/data/' + gameId,
            success: function(response) {
                gameData = JSON.parse(response);
                masterStages = getMasterStages();
                experienceEvents = getEventsArray('experience_events');
                leaderboard = getLeaderboardData();
                // columnDataOptions = getGameVariables(); <- why are we doing this here?
                themeSettings = getThemeSettings();

                // set up the table for the experience scene
                $.each(experienceEvents, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#experience_events').DataTable().row.add([
                            key,
                            '<a class="btn-open-event-modal event-title" href="#" data-toggle="modal" data-target="#eventModal" data-event-index=' + key + '>' + value.title + '</a>',
                            '<span class="event-trigger" data-event-index=' + key + '>' + value.trigger + '</span>',
                            '<span class="event-event" data-event-index=' + key + '>' + value.event_type + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="event" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    }
                });

                // required for experiences that have tablet set as mode <- can remove once all experiences have been reviewed
                if (gameData.mode === 'Tablet') {
                    gameStatus.status = 'review';
                } else {
                    // construct the game status object
                    gameStatus = JSON.parse(gameData.mode);
                }

                setSelectContent($('#mode'), gameStatus.status); // parameter value
                
                /* --- assign feedback previews --- */
                // help screen - game info
                if (isValidJSON(gameData.help_screen)) {
                    helpScreen = JSON.parse(gameData.help_screen);
                    assignFileToFileComponent('help_screen', helpScreen.bg, '_bg', '_file');
                }

                // success screen - game completed
                if (isValidJSON(gameData.success_screen)) {
                    successScreen = JSON.parse(gameData.success_screen);
                    assignFileToFileComponent('success_screen', successScreen.bg, '_bg', '_file');
                }

                // failure screen - game over
                if (isValidJSON(gameData.failure_screen)) {
                    failureScreen = JSON.parse(gameData.failure_screen);
                    assignFileToFileComponent('failure_screen', failureScreen.bg, '_bg', '_file');
                }

                // set up editor components in line with the access settings for the logged in user
                setComponentAccess();

                // close the loader
                $('#loader-wrapper').fadeOut('fast');
            }
        });
    }

    function getScreens(type) {
        let result = [];

        switch (type) {
            case 'intro':
                if (!isEmpty(gameData.intro_screens)) {
                    result = JSON.parse(gameData.intro_screens);
                }
                break;
            case 'outro':
                if (!isEmpty(gameData.outro_screens)) {
                    result = JSON.parse(gameData.outro_screens);
                }
                break;
            case 'preSummary':
                if (!isEmpty(gameData.pre_summary_screens)) {
                    result = JSON.parse(gameData.pre_summary_screens);
                }
                break;
        }

        return result;
    }

    function setScreens(type) {
        switch (type) {
            case 'intro':
                gameData.intro_screens = JSON.stringify(screens);
                break;
            case 'outro':
                gameData.outro_screens = JSON.stringify(screens);
                break;
            case 'preSummary':
                gameData.pre_summary_screens = JSON.stringify(screens);
                break;
        }        
    }

    function getScreen(index) {
        let result = {};
        if (!isEmpty(screens[index])) {
            result = screens[index];
        }
        return result;
    }

    function setScreen(index) {
        screens[index] = screen;
    }

    /** ----- Leaderboard builder functions ----- **/
    // Leaderboard data
    function getLeaderboardData() {
        let result = {};
        if (!isEmpty(gameData.leaderboard)) {
            result = JSON.parse(gameData.leaderboard);
        }
        return result;
    }

    function setLeaderboardData() {
        gameData.leaderboard = JSON.stringify(leaderboard);
    }

    // Leaderboard columns
    function getLeaderboardColumns() {
        let result = [{
            columnTitle:"Team Name",
            sortingTitle:"Teams",
            columnVariable:"team_name"
        }];

        if (!isEmpty(leaderboard.columns)) {
            result = leaderboard.columns;
        }
        return result;
    }

    function setLeaderboardColumns(newColumns) {
        leaderboard.columns = newColumns;
    }

    function getLeaderboardColumn(index) {
        let result = {};
        if (!isEmpty(lbColumns[index])) {
            result = lbColumns[index];
        }
        return result;
    }

    function setLeaderboardColumn(index) {
        lbColumns[index] = lbColumn;
    }

    /** ----- Master Stage functions ----- **/
    function getMasterStages() {
        let result = [];
        if (!isEmpty(gameData.master_stages)) {
            result = JSON.parse(gameData.master_stages);
        }
        return result;
    }

    function setMasterStages() {
        if (isValidJSON(masterStages)) {
            gameData.master_stages = masterStages;
        } else {
            gameData.master_stages = JSON.stringify(masterStages);
        }
    }

    function getMasterStage(index) {
        let result = {};
        if (!isEmpty(masterStages[index])) {
            result = masterStages[index];
        }

        return result;
    }

    function setMasterStage(index) {
        masterStages[index] = masterStage;
    }

    /** ----- Event functions ----- **/
    function getEventsArray(array) {
        let result = [];

        switch(array) {
            case 'experience_events':
                currentEventsArray = 'experience_events';
                eventTable = 'experience_events';
                if (!isEmpty(gameData.experience_events)) {
                    result = JSON.parse(gameData.experience_events);
                }
                break;
            case 'm_stg_events':
                currentEventsArray = 'm_stg_events';
                eventTable = 'master_stage_events';
                if (!isEmpty(masterStage.mStgEvents)) {
                    result = masterStage.mStgEvents;
                }
                break;
            case 'stg_events':
                currentEventsArray = 'stg_events';
                eventTable = 'stage_events';
                if (!isEmpty(stage.stgEvents)) {
                    result = stage.stgEvents;
                }
                break;
            default:
                break;
        }
        return result;
    }

    // Sets the array for a particular Event table. Can be given an array obj to directly affect the array
    function setEventsArray(arrayName, arrayObj) {
        switch(arrayName) {
            case 'experience_events':
                if (arrayObj !== undefined) {
                    experienceEvents = arrayObj;
                    gameData.experience_events = JSON.stringify(experienceEvents);
                } else {
                    gameData.experience_events = JSON.stringify(experienceEvents);
                }
                break;
            case 'm_stg_events':
                if (arrayObj !== undefined) {
                    mStgEvents = arrayObj;
                    masterStage.mStgEvents = mStgEvents;
                } else {
                    masterStage.mStgEvents = mStgEvents;
                }
                break;
            case 'stg_events':
                if (arrayObj !== undefined) {
                    stgEvents = arrayObj
                    stage.stgEvents = stgEvents;
                } else {
                    stage.stgEvents = stgEvents;
                }
                break;
            default:
                break;
        }
    }

    function getEventFromArray(array, index) {
        let result = {};

        switch(array) {
            case 'experience_events':
                if (!isEmpty(experienceEvents[index])) {
                    result = experienceEvents[index];
                }
                break;
            case 'm_stg_events':
                if (!isEmpty(masterStage.mStgEvents[index])) {
                    result = masterStage.mStgEvents[index];
                }
                break;
            case 'stg_events':
                if (!isEmpty(stage.stgEvents[index])) {
                    result = stage.stgEvents[index];
                }
                break;
            default:
                break;
        }

        return result;
    }

    function setEventForArray(array, index) {
        switch(array) {
            case 'experience_events':
                experienceEvents[index] = eventObj;
                break;
            case 'm_stg_events':
                mStgEvents[index] = eventObj;
                break;
            case 'stg_events':
                stgEvents[index] = eventObj;
                break;
            default:
                break;
        }
    }

    function getStages() {
        let result = [];
        if (!isEmpty(masterStage.stages)) {
            result = masterStage.stages;
        }

        return result;
    }

    function setStages() {
        masterStage.stages = stages;
    }

    function getStage(index) {
        let result = {};
        if (!isEmpty(stages[index])) {
            result = stages[index];
        }

        return result;
    }

    function setStage(index) {
        stages[index] = stage;
    }

    function getPuzzles() {
        let result = [];
        if (!isEmpty(stage.puzzles)) {
            result = stage.puzzles;
        }
        return result;
    }

    function setPuzzles() {
        stage.puzzles = puzzles;
    }

    function getPuzzle(index) {
        let result = {};
        if (!isEmpty(puzzles[index])) {
            result = puzzles[index];
        }
        return result;
    }

    function setPuzzle(index) {
        puzzles[index] = puzzle;
    }

    function getHints() {
        let result = [];
        if (!isEmpty(puzzle.hints)) {
            result = puzzle.hints;
        }
        return result;
    }

    function setHints() {
        puzzle.hints = hints;
    }

    function getHint(index) {
        let result = {};
        if (!isEmpty(hints[index])) {
            result = hints[index];
        }
        return result;
    }

    function setHint(index) {
        hints[index] = hint;
    }

    function getRewards() {
        let result = [];
        if (!isEmpty(puzzle.rewards)) {
            result = puzzle.rewards;
        }
        return result;
    }

    function setRewards() {
        puzzle.rewards = rewards;
    }

    function getReward(index) {
        let result = {};
        if (!isEmpty(rewards[index])) {
            result = rewards[index];
        }
        return result;
    }

    function setReward(index) {
        rewards[index] = reward;
    }

    function getResolutions() {
        let result = [];
        if (!isEmpty(puzzle.resolutions)) {
            result = puzzle.resolutions;
        }
        return result;
    }

    function setResolutions() {
        puzzle.resolutions = resolutions;
    }

    function getResolution(index) {
        let result = {};
        if (!isEmpty(resolutions[index])) {
            result = resolutions[index];
        }
        return result;
    }

    function setResolution(index) {
        resolutions[index] = resolution;
    }

    function getThemeSettings() {
        themeSettings = JSON.parse(gameData.theme_settings);

        // convert the font percentage back to number
        themeSettings.primary_font_size = convertFromAppPercentage(themeSettings.primary_font_size, 40);
        themeSettings.secondary_font_size = convertFromAppPercentage(themeSettings.secondary_font_size, 30);
        themeSettings.tertiary_font_size = convertFromAppPercentage(themeSettings.tertiary_font_size, 20);

        // assign the components the correct value
        $('.master_theme #primary_font_size').val(themeSettings.primary_font_size);
        $('.master_theme #secondary_font_size').val(themeSettings.secondary_font_size);
        $('.master_theme #tertiary_font_size').val(themeSettings.tertiary_font_size);

        return themeSettings;
    }

    function setThemeSettings() {
        gameData.theme_settings = JSON.stringify(themeSettings);
    }

    // experience events have access to all timers, master stage events have access to it's own timer and all stage timers within it, stage event only has access to it's own timer
    function getTimers(level, mStgIndex, stgIndex) {
        let newTimerList = [];
        
        // only assign timers that are required
        switch(level) {
            case 'm_stg_events':
                newTimerList = getMasterStageTimers(mStgIndex);
            break;
            case 'stg_events':
                if (masterStages[mStgIndex].stages[stgIndex].time_restriction !== '') {
                    newTimerList.push({
                        timerIndex: {
                            mStgIndex: mStgIndex,
                            stgIndex: stgIndex
                        },
                        timer: 'sTimer',
                        title: masterStages[mStgIndex].title + ': ' + masterStages[mStgIndex].stages[stgIndex].title + ' Timer'
                    });
                }
            break;
            default:
                let extra_settings = JSON.parse(gameData.extra_settings);
                // check if there is a game timer set in extra settings, if there is add it
                if (extra_settings.includes('time_restriction')) {
                    newTimerList.push({
                        timerIndex: 'n/a',
                        timer: 'gameTimer',
                        title: 'Game Timer'
                    });
                }

                for (let i = 0; i < masterStages.length; i++) {
                    let masterStageTimers = getMasterStageTimers(i);
                    let currentTimeList = newTimerList;

                    newTimerList = currentTimeList.concat(masterStageTimers);
                }
            break;
        }

        return newTimerList;
    }

    function getMasterStageTimers(index, solo) {
        let timeObj = {
            timerIndex: 'n/a',
            timer: 'msTimer',
            title: ''
        }

        let masterStageTimerArray = [];
        let stageTimerArray = getStageTimers(index, masterStages[index].stages);

        if (masterStages[index].time_restriction !== '') {
            timeObj.timerIndex = { mStgIndex: index };
            timeObj.title = masterStages[index].title + ' Timer';
            masterStageTimerArray.push(timeObj);
        }

        let timerArray = masterStageTimerArray.concat(stageTimerArray);

        return timerArray;
    }

    function getStageTimers(mStgIndex, stagesArray) {
        let timeObj = {
            timerIndex: 'n/a',
            timer: 'sTimer',
            title: ''
        }
        let newStageTimerList = [];

        for (let i = 0; i < stagesArray.length; i++) {
            if (stagesArray[i]) {
                if (stagesArray[i].time_restriction !== '') {
                    newStageTimerList.push({
                        timerIndex: {
                            mStgIndex: mStgIndex,
                            stgIndex: i
                        },
                        timer: 'sTimer',
                        title: masterStages[mStgIndex].title + ': ' + stagesArray[i].title + ' Timer'
                    });
                }
            }
        }    

        return newStageTimerList;
    }

    function findTimer(title, list) {
        for (var i = 0; i < list.length; i++) {
            if (list[i].title === title) {
                return i;
            }
        }
        return -1;
    }

    // retrieves all possible candidates and formats them in option parameters for the dropdown box
    function getSelectionOptions (value, parent, grand_parent) {
        var optionParameters = [];
        var incremental = [];
        optionParameters = [{ id: 'None', text: 'None' }];
        switch(value) {
            case 'master_stage':
                incremental = getMasterStages();
                break;
            case 'stage':
                incremental = masterStages[parent].stages;
                break;
            case 'interaction':
                incremental = masterStages[grand_parent].stages[parent].puzzles;
                break;
            default:
                break;
        }
        
        $.each(incremental, (index, element) => {
            if (element) {
                var text = element.title;
                if (element.title === undefined) {
                    text = element.description; // resolutions don't have a title, so we use description instead
                }
                optionParameters.push({
                    id: index,
                    text: text
                });
            }
        });

        return optionParameters;
    }

    // retrieves candidates that have a specific property and formats them in option parameters for the dropdown box
    function getSelectionOptionsWithProperty (value, prop, parent, grand_parent) {
        var optionParameters = [];
        var incremental = [];
        optionParameters = [{ id: 'None', text: 'None' }];
        switch(value) {
            case 'master_stage':
                incremental = getMasterStages();
                break;
            case 'stage':
                incremental = masterStages[parent].stages;
                break;
            case 'interaction':
                incremental = masterStages[grand_parent].stages[parent].puzzles;
                break;
            default:
                break;
        }
        
        $.each(incremental, (index, element) => {
            if (element && element[prop]) {
                var text = element.title;

                if (element.title === undefined) {
                    text = element.description; // resolutions don't have a title, so we use description instead
                }

                optionParameters.push({
                    id: index,
                    text: text
                });
            }
        });

        return optionParameters;
    }

    /* ----- Page opened (when the view property is populated it will mean a specific page is open)  ----- */
    if (gameView.length) {
        let gameId = gameView.attr('data-game-id');
        if (!isEmpty(gameId)) {
            getGameData(gameId);
        } else {
            // close the loader
            $('#loader-wrapper').fadeOut('fast');
        }
    } else {
        // close the loader
        $('#loader-wrapper').fadeOut('fast');
    }

    /********************  pass variables when opens modals *******************/

    /*
     * opening screen modal
     */
    $(document).on('click', '.btn-open-screen-modal', (event) => {
        let target = $(event.target);
        let screenType = target.attr('data-screen-type');
        let screenIndex = target.attr('data-screen-index');

        screens = getScreens(screenType); // screens[{}] <= gamaData

        if (!isEmpty(screenIndex)) {
            screen = getScreen(screenIndex); // screen{} <= screens[{}][index]
            let screenWYSIWYG = screen.html !== undefined ? screen.html : screen.content;

            $('#index', $('#' + screenType + 'ScreenModal')).val(screenIndex); // text
            $('#title', $('#' + screenType + 'ScreenModal')).val(screen.title); // text
            assignHTMLtoWYSIWYGComponent('content', screenWYSIWYG, screenType + 'ScreenModal');
            assignFileToFileComponent(screenType + '_screen', screen.bg, '_bg', '_file', screenType + 'ScreenModal');
        } else {
            // modal - create new 
            screenIndex = screens.length; // automatically create a new index based on the length
            screen = getScreen(screenIndex); // screen{} <= screens[{}][index]; {}
            $('#index', $('#' + screenType + 'ScreenModal')).val(screenIndex); // text
            $('#title', $('#' + screenType + 'ScreenModal')).val(''); // text
            assignHTMLtoWYSIWYGComponent('content', '', screenType + 'ScreenModal');
            assignFileToFileComponent(screenType + '_screen', '', '_bg', '_file', screenType + 'ScreenModal');
        }
    });

    /** ----- Mailchimp merge tags function ----- **/
    let mergeTags = []; // merge tags that belong to the mailchimp merge tags table
    let mergeTag = {}; // merge tag obj
    let mergeTagOptions = []; // Game variable options for assigning the merge tag variable
    let mergeTagIndex; // int, stores the current open mailchimp merge tag index

    $(document).on('click', '.btn-open-mailchimp-modal', (event) => {
        let target = $(event.target);
        mergeTags = getMailchimpMergeTags();

        /* ----- Merge Tags table ----- */
        updateMergeTagsTable(mergeTags);
    });
    
    // Mailchimp merge tags
    function getMailchimpMergeTags() {
        let result = [];

        if (gameData.mailchimp.length > 0) {
            let mailchimpObj = JSON.parse(gameData.mailchimp);

            if (mailchimpObj.merge_tags) {
                result = mailchimpObj.merge_tags;
            } else {
                result = mailchimpObj;
            }
        }

        return result;
    }

    function saveMailchimpMergeTags() {
        if (Array.isArray(mergeTags)) {
            gameData.mailchimp = JSON.stringify(mergeTags);
        }
    }

    function getMailchimpMergeTag(index) {
        let result = {};
        if (!isEmpty(mergeTags[index])) {
            result = mergeTags[index];
        }
        return result;
    }

    function addMailchimpMergeTag(index) {
        mergeTags[index] = mergeTag;
        updateMergeTagsTable(mergeTags); // updates the mailchimp merge tags table
    }

    // updates the merge tags table for mailchimp correctly
    function updateMergeTagsTable(newMergeTags) {
        // reset the inner table every time when modal is opened
        $('table#mailchimp_merge_tags_table').DataTable().clear().draw();
        
        $.each(newMergeTags, (key, value) => {
            if (!isEmpty(value)) {
                $('table#mailchimp_merge_tags_table').DataTable().row.add([
                    key,
                    '<a class="btn-open-mailchimp-merge-tag-modal mailchimp-merge-tag-description" href="#" data-toggle="modal" data-target="#mailchimpMergeTagModal" data-mailchimp-merge-tag-index=' + key + '>' + value.description + '</a>',
                    '<span class="mailchimp-merge-tag" data-mailchimp-merge-tag-index=' + key + '>' + value.tag + '</span>',
                    '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="mailchimp_merge_tag" data-name=' + value.description + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                ]).draw();
            }
        });
    }

    // Open merge tag from mailchimp merge tags table
    $(document).on('click', '.btn-open-mailchimp-merge-tag-modal', (event) => {
        let target = $(event.target);     
        mergeTagIndex = target.attr('data-mailchimp-merge-tag-index');
        mergeTagOptions = getMergeTagOptions();
        var selectedMasterStage;
        var selectedStage;
        var selectedInteraction;
        
        // reset the merge tag options
        setSelectionData($('#merge_tags', $('#mailchimpMergeTagModal')), mergeTagOptions);

        var currentMasterStages = getSelectionOptions('master_stage');
        setSelectionData($('#master_stage_index', $('#mailchimpMergeTagModal')), currentMasterStages);

        // update the stage selection box when master stage is selected
        $('#master_stage_index', $('#mailchimpMergeTagModal')).on('change', function(event) {
            selectedMasterStage =  $('#master_stage_index', $('#mailchimpMergeTagModal')).val();
            if (selectedMasterStage !== null && selectedMasterStage !== 'None') {
                var currentStages = getSelectionOptions('stage', selectedMasterStage);
                // reset the stage selection options
                setSelectionData($('#stage_index', $('#mailchimpMergeTagModal')), currentStages);
            }
        });

        // update the interaction selection box when stage is selected
        $('#stage_index', $('#mailchimpMergeTagModal')).on('change', function(event) {
            selectedStage =  $('#stage_index', $('#mailchimpMergeTagModal')).val();
            if (selectedStage !== null && selectedStage !== 'None') {
                var currentInteractions = getSelectionOptions('interaction', selectedStage, selectedMasterStage);
                // reset the interaction selection options
                setSelectionData($('#interaction_index', $('#mailchimpMergeTagModal')), currentInteractions);
            }
        });

        if (!isEmpty(mergeTagIndex)) {
            mergeTag = getMailchimpMergeTag(mergeTagIndex); // mergeTag{} <= mergeTags[{}][index]
            $('#tag_description', $('#mailchimpMergeTagModal')).val(mergeTag.description); // text
            setSelectContent($('#merge_tags', $('#mailchimpMergeTagModal')), mergeTag.tag); // parameter value
            setSelectContent($('#master_stage_index', $('#mailchimpMergeTagModal')), mergeTag.resultObj.mStgIndex); // parameter value
            setSelectContent($('#stage_index', $('#mailchimpMergeTagModal')), mergeTag.resultObj.stgIndex); // parameter value
            setSelectContent($('#interaction_index', $('#mailchimpMergeTagModal')), mergeTag.resultObj.interactionIndex); // parameter value
        } else {
            mergeTagIndex = mergeTags.length;
            $('#tag_description', $('#mailchimpMergeTagModal')).val(''); // text
            setSelectContent($('#master_stage_index', $('#mailchimpMergeTagModal')), ''); // parameter value
            setSelectContent($('#stage_index', $('#mailchimpMergeTagModal')), ''); // parameter value
            setSelectContent($('#interaction_index', $('#mailchimpMergeTagModal')), ''); // parameter value
            setSelectContent($('#merge_tags', $('#mailchimpMergeTagModal')), ''); // parameter value
        }
    });

    // gets the available tags that have not been assigned yet for use
    function getMergeTagOptions() {
        let tags = [];

        // there will be 10 potential merge tags that will be assignable
        for (var j = 0; j < 10; j++) {
            var tag = 'TAG'+(j+1);
            tags.push({
                id: tag, 
                text: tag
            });
        }

        return tags;
    }

    /*
     * opening leaderboard modal
     */
    $(document).on('click', '.btn-open-leaderboard-modal', (event) => {
        let target = $(event.target);
        leaderboard = getLeaderboardData();
        lbColumns = getLeaderboardColumns();
        let orderOptions = getColumnOrderOptions();
        // change for extra settings db address field based on send data checkbox
        let refreshField = $('#refresh_rate', $('#leaderboardBuilderModal'));
        let searchCheckbox = $('#searchable', $('#leaderboardBuilderModal'));

        // when updating session id, update the URL label
        $('#session_id', $('#leaderboardBuilderModal')).on('input', function(event) {
            let sessionName = $('#session_id', $('#leaderboardBuilderModal')).val();
            let hyphenatedGameName = gameData.name.replace(/\s+/g, '-').toLowerCase();
            let hyphenatedSessionName = sessionName.replace(/\s+/g, '-').toLowerCase();
            let lbURLPath = globalLeaderboardWebURLPrepend + hyphenatedGameName + '-' + hyphenatedSessionName + '-leaderboard/';
            $('#leaderboard_url', $('#leaderboardBuilderModal')).text(lbURLPath);
        });

        if ($('#frequent_refresh', $('#leaderboardBuilderModal')).is(':checked')) {
            refreshField.parent().show();
        } else {
            refreshField.parent().hide();
        }

        $('#frequent_refresh', $('#leaderboardBuilderModal')).on('ifChecked', function() {
            refreshField.parent().show();
        });
        $('#frequent_refresh', $('#leaderboardBuilderModal')).on('ifUnchecked', function() {
            refreshField.parent().hide();
        });
        
        // reset the table sorting order options
        setSelectionData($('#table_order', $('#leaderboardBuilderModal')), orderOptions);
        // reset the priority order options
        setSelectionData($('#priority_order', $('#leaderboardBuilderModal')), orderOptions);
        // reset the secondary order options
        setSelectionData($('#secondary_order', $('#leaderboardBuilderModal')), orderOptions);
        /* ----- Leaderboard column table ----- */
        updateLeaderboardColumnTable();
        // if there is data
        if (!isEmpty(leaderboard)) {
            $('#leaderboard_url', $('#leaderboardBuilderModal')).text(leaderboard.url_path);
            $('#session_id', $('#leaderboardBuilderModal')).val(leaderboard.session_id); // text
            $('#leaderboard_title', $('#leaderboardBuilderModal')).val(leaderboard.title); // text

            if (leaderboard.searchable == true) { // checkbox
                $('#searchable', $('#leaderboardBuilderModal')).iCheck('check'); // checkbox
            } else {
                $('#searchable', $('#leaderboardBuilderModal')).iCheck('uncheck'); // checkbox
            }
            
            if (leaderboard.frequent_update == true) { // checkbox
                $('#frequent_update', $('#leaderboardBuilderModal')).iCheck('check'); // checkbox
            } else {
                $('#frequent_update', $('#leaderboardBuilderModal')).iCheck('uncheck'); // checkbox
            }
            // refresh rate for the session leaderboard page
            if (leaderboard.frequent_refresh == true) { // checkbox
                $('#frequent_refresh', $('#leaderboardBuilderModal')).iCheck('check'); // checkbox
            } else {
                $('#frequent_refresh', $('#leaderboardBuilderModal')).iCheck('uncheck'); // checkbox
            }
            $('#refresh_rate', $('#leaderboardBuilderModal')).val(leaderboard.refresh_rate); // number
            $('#lb_bg_image', $('#leaderboardBuilderModal')).val(leaderboard.lb_bg_image); // file
            fileUploadRefreshSingle($('#leaderboard_bg_file'), leaderboard.lb_bg_image);
            $('#lb_logo', $('#leaderboardBuilderModal')).val(leaderboard.lb_logo); // file
            fileUploadRefreshSingle($('#leaderboard_logo_file'), leaderboard.lb_logo);
            $('#company_logo', $('#leaderboardBuilderModal')).val(leaderboard.company_logo); // file
            fileUploadRefreshSingle($('#company_logo_file'), leaderboard.company_logo);
            setSelectContent($('#table_order', $('#leaderboardBuilderModal')), leaderboard.default_sorting_order.table_order); // table select
            setSelectContent($('#table_sorting_order', $('#leaderboardBuilderModal')), leaderboard.default_sorting_order.table_sorting_order); // table sorting select

            if (leaderboard.priority_order === 'progressive_points') {
                setSelectContentByText($('#priority_order', $('#leaderboardBuilderModal')), orderOptions, leaderboard.priorityOrderValue);
            } else {
                setSelectContent($('#priority_order', $('#leaderboardBuilderModal')), leaderboard.priority_order); // priority select
            }

            if (leaderboard.secondary_order === 'progressive_points') {
                setSelectContentByText($('#secondary_order', $('#leaderboardBuilderModal')), orderOptions, leaderboard.secondaryOrderValue);
            } else {
                setSelectContent($('#secondary_order', $('#leaderboardBuilderModal')), leaderboard.secondary_order); // secondary select
            }

            setSelectContent($('#sorting_order', $('#leaderboardBuilderModal')), leaderboard.sorting_order); // sorting select
            setSelectContent($('#secondary_sorting_order', $('#leaderboardBuilderModal')), leaderboard.secondary_sorting_order); // secondary sorting order select
        } else {
            // if there is not data
            $('#session_id', $('#leaderboardBuilderModal')).val(''); // text
            $('#leaderboard_title', $('#leaderboardBuilderModal')).val(''); // text
            $('#searchable', $('#leaderboardBuilderModal')).iCheck('uncheck'); // checkbox
            $('#frequent_update', $('#leaderboardBuilderModal')).iCheck('uncheck'); // checkbox
            $('#frequent_refresh', $('#leaderboardBuilderModal')).iCheck('uncheck'); // checkbox
            $('#refresh_rate', $('#leaderboardBuilderModal')).val(''); // number
            $('#lb_bg_image', $('#leaderboardBuilderModal')).val(''); // file
            fileUploadResetSingle($('#leaderboard_bg_file'));
            $('#lb_logo', $('#leaderboardBuilderModal')).val(''); // file
            fileUploadResetSingle($('#leaderboard_logo_file'));
            $('#company_logo', $('#leaderboardBuilderModal')).val(''); // file
            fileUploadResetSingle($('#company_logo_file'));
            setSelectContent($('#table_order', $('#leaderboardBuilderModal')), 'None'); // table select
            setSelectContent($('#table_sorting_order', $('#leaderboardBuilderModal')), 'desc'); // table sorting select
            setSelectContent($('#priority_order', $('#leaderboardBuilderModal')), 'None'); // priority select
            setSelectContent($('#secondary_order', $('#leaderboardBuilderModal')), 'None'); // secondary select
            setSelectContent($('#sorting_order', $('#leaderboardBuilderModal')), 'desc'); // sorting select
            setSelectContent($('#secondary_sorting_order', $('#leaderboardBuilderModal')), 'desc'); // secondary sorting order select
        }
    });

    const clearResultsConfirmModal = $('#clearResultsConfirmModal');
    $(document).on('click', '.btn-clear-results', (event) => {
        clearUserResults();
        clearResultsConfirmModal.modal('hide');
    });

    // Gets Column title and variable and returns them for use as options
    function getColumnOrderOptions() {
        let order = [];

        for (let i = 0; i < lbColumns.length; i++) {
            let orderObj = {
                id: lbColumns[i].columnVariable,
                text: lbColumns[i].columnTitle
            }
            order.push(orderObj);
        }

        order.unshift({
            id: 'None', 
            text: 'None'
        });

        return order;
    }

    // updates the column table for the leaderboard correctly
    function updateLeaderboardColumnTable() {
        // reset the inner table every time when modal is opened
        $('table#leaderboard_columns_table').DataTable().clear().draw();

        lbColumns = getLeaderboardColumns();
        $.each(lbColumns, (key, value) => {
            if (!isEmpty(value)) {
                let dataNameClass = value.columnTitle;
                // disable the delete button for the team name row as it's a requirement now for leaderboards
                if (key == 0) {
                    dataNameClass = value.columnTitle + ' disabled';
                }

                let rowArray = [
                     key,
                    '<a class="btn-open-column-variable-modal leaderboard-column-title" href="#" data-toggle="modal" data-target="#leaderboardColumnModal" data-leaderboard-column-index=' + key + '>' + value.columnTitle + '</a>',
                    '<span class="leaderboard-sorting-title" data-leaderboard-column-index=' + key + '>' + value.sortingTitle + '</span>',
                    '<span class="leaderboard-column-variable" data-leaderboard-column-index=' + key + '>' + value.columnVariable + '</span>',
                    '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="lb_column" data-name=' + dataNameClass + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                ];                

                $('table#leaderboard_columns_table').DataTable().row.add(rowArray).draw();
            }
        });
    }

    // Open column variables from leaderboard builder columns table
    $(document).on('click', '.btn-open-column-variable-modal', (event) => {
        let target = $(event.target);     
        lbColumnIndex = target.attr('data-leaderboard-column-index'); // update the current leaderboard column index
        let colVar = $('#column_variable', $('#leaderboardColumnModal')).val();
        let masterStageOptions = $('#master_stage_column', $('#leaderboardColumnModal')).val();
        let stageOptions = $('#stage_column', $('#leaderboardColumnModal')).val();
        let interactionOptions = $('#interaction_column', $('#leaderboardColumnModal')).val();
        let selectedMasterStage;
        let selectedStage;
        let selectedInteraction;
        timerList = getTimers(); // we want to get all available timers to ensure we can manipulate them
        let leaderboardTimeObj = $('#leaderboard_timers', $('#leaderboardColumnModal')).parent();
        leaderboardTimeObj.addClass('hidden');
        columnDataOptions = getGameVariables();
        hideExperienceAreaBoxes(true);
        
        // reset the column variable options
        setSelectionData($('#column_variable', $('#leaderboardColumnModal')), columnDataOptions);

        $('#column_variable', $('#leaderboardColumnModal')).on('change', function(event) {
            colVar = $('#column_variable', $('#leaderboardColumnModal')).val();
            
            switch(colVar) {
                case 'interaction_result':
                case 'progressive_points':
                    // show the selection boxes for Master Stage, Stage, Interaction and Answers that are available
                    leaderboardTimeObj.addClass('hidden');
                    hideExperienceAreaBoxes(false, colVar);
                    let currentMasterStages = getSelectionOptions('master_stage');
                    // reset the master stage selection options
                    setSelectionData($('#master_stage_column', $('#leaderboardColumnModal')), currentMasterStages);
                    break;
                case 'time_spent':
                case 'time_left':
                    leaderboardTimeObj.removeClass('hidden');
                    let timersAvailable = [{ id: 'None', text: 'None' }];
                    
                    // set the Master stage selection variables
                    $.each(timerList, (index, element) => {
                        if (element) {
                            timersAvailable.push({
                                id: index,
                                text: element.title
                            });
                        }
                    });
                    setSelectionData($('#leaderboard_timers', $('#leaderboardColumnModal')), timersAvailable);
                    hideExperienceAreaBoxes(true);
                    break;
                default:
                    hideExperienceAreaBoxes(true);
                    leaderboardTimeObj.addClass('hidden');
                    break;
            }
        });

        // update the stage selection box when master stage is selected
        $('#master_stage_column', $('#leaderboardColumnModal')).on('change', function(event) {
            selectedMasterStage =  $('#master_stage_column', $('#leaderboardColumnModal')).val();
            let currentStages = getSelectionOptions('stage', selectedMasterStage);
            // reset the stage selection options
            setSelectionData($('#stage_column', $('#leaderboardColumnModal')), currentStages);
        });

        // update the interaction selection box when stage is selected
        $('#stage_column', $('#leaderboardColumnModal')).on('change', function(event) {
            selectedStage =  $('#stage_column', $('#leaderboardColumnModal')).val();
            let currentInteractions = getSelectionOptions('interaction', selectedStage, selectedMasterStage);
            // reset the interaction selection options
            setSelectionData($('#interaction_column', $('#leaderboardColumnModal')), currentInteractions);
        });

        if (!isEmpty(lbColumnIndex)) {
            lbColumn = getLeaderboardColumn(lbColumnIndex); // column{} <= lb_columns[{}][index]
            $('#column_title', $('#leaderboardColumnModal')).val(lbColumn.columnTitle); // text
            $('#sorting_title', $('#leaderboardColumnModal')).val(lbColumn.sortingTitle); // text
            setSelectContent($('#column_variable', $('#leaderboardColumnModal')), lbColumn.columnVariable); // parameter value

            switch(lbColumn.columnVariable) {
                case 'interaction_result':
                    setSelectContent($('#master_stage_column', $('#leaderboardColumnModal')), lbColumn.resultObj.mStgIndex); 
                    setSelectContent($('#stage_column', $('#leaderboardColumnModal')), lbColumn.resultObj.stgIndex);
                    setSelectContent($('#interaction_column', $('#leaderboardColumnModal')), lbColumn.resultObj.interactionIndex);
                    break;
                case 'progressive_points':
                    setSelectContent($('#master_stage_column', $('#leaderboardColumnModal')), lbColumn.resultObj.mStgIndex); 
                    setSelectContent($('#stage_column', $('#leaderboardColumnModal')), lbColumn.resultObj.stgIndex);
                    break;
                case 'time_spent':
                case 'time_left':
                    // make sure that we're using the correct index for displaying the selected option
                    let newIndex = findTimer(lbColumn.timeObj.title, timerList);

                    if (newIndex !== -1) {
                        setSelectContent($('#leaderboard_timers', $('#leaderboardColumnModal')), newIndex);
                    } else {
                        setSelectContent($('#leaderboard_timers', $('#leaderboardColumnModal')), 'None');
                    }
                    break;
            }
        } else {
            hideExperienceAreaBoxes(true);
            lbColumnIndex = lbColumns.length;
            $('#column_title', $('#leaderboardColumnModal')).val(''); // text
            $('#sorting_title', $('#leaderboardColumnModal')).val(''); // text
            setSelectContent($('#column_variable', $('#leaderboardColumnModal')), ''); // parameter value
            setSelectContent($('#leaderboard_timers', $('#leaderboardColumnModal')), '');
        }
    });

    // Gets Game variables that could be used in a leaderboard
    function getGameVariables() {
        let potentialVariables = [{
            id: 'team_name', 
            text: 'Team Name'
        }];

        // total points, hints and interaction result
        potentialVariables.push({
            id: 'total_points', 
            text: 'Total Points'
        },
        {
            id: 'hints_used', 
            text: 'Hints used'
        },
        {
            id: 'progressive_points', 
            text: 'Progressive Points'
        },
        {
            id: 'interaction_result', 
            text: 'Interaction Result'
        },
        {
            id: 'game_time_finished', 
            text: 'Game Time Finished'
        });

        if (timerList.length > 0) {
            // any timers present
            potentialVariables.push({
                id: 'time_spent', 
                text: 'Time Spent'
            },
            {
                id: 'time_left', 
                text: 'Time Left'
            });
        }
        
        return potentialVariables;
    }

    function hideExperienceAreaBoxes(hide, type) {
        var masterStageOptionsObj = $('#master_stage_column', $('#leaderboardColumnModal')).parent();
        var stageOptionsObj = $('#stage_column', $('#leaderboardColumnModal')).parent();
        var interactionOptionsObj = $('#interaction_column', $('#leaderboardColumnModal')).parent();

        if (hide === false) {
            if (type === 'interaction_result') {
                masterStageOptionsObj.removeClass('hidden');
                stageOptionsObj.removeClass('hidden');
                interactionOptionsObj.removeClass('hidden');
            }

            if (type === 'progressive_points') {
                masterStageOptionsObj.removeClass('hidden');
                stageOptionsObj.removeClass('hidden');

                if (!interactionOptionsObj.hasClass('hidden')) {
                    interactionOptionsObj.addClass('hidden');
                }
            }
        } else {
            masterStageOptionsObj.addClass('hidden');
            stageOptionsObj.addClass('hidden');
            interactionOptionsObj.addClass('hidden');
        }
    }

    /*
     * opening event modal - regardless of the level we're in
     */
    $(document).on('click', '.btn-open-event-modal', (event) => {
        let target = $(event.target);
        let eventsTable = getEventsArray(currentEventsArray);

        eventIndex = target.attr('data-event-index'); // update the current event index
        // Event updates
        // Trigger options
        let eventTrigger = $('#trigger', $('#eventModal')).val();
        let triggerOptions = setTriggerOptions();

        // reset the trigger options
        setSelectionData($('#trigger', $('#eventModal')), triggerOptions);

        // Parameter options
        let parameterOptions = setTriggerParameters(eventTrigger, eventIndex);
        let selectedParameter = $('#parameter', $('#eventModal').val());

        // reset the parameter options
        setSelectionData($('#parameter', $('#eventModal')), parameterOptions);

        // Feedback variables
        let feedbackOptions = $('#eventFeedbackOptions', $('#eventModal')).val();
        let feedbackSelectionObj = $('#eventFeedbackOptions', $('#eventModal')).parent();
        let feedbackContent = $('#feedback_content', $('#eventModal')).val();
        let feedbackContentObj = $('#feedback_content', $('#eventModal')).parent();
        let feedbackWYSIWYG = $('#event_wysiwyg_feedback', $('#eventModal')).val();
        let feedbackWYSIWYGObj = $('#event_wysiwyg_feedback', $('#eventModal')).parent().parent().parent();

        // update the parameter options when the user changes the trigger option
        $('#trigger', $('#eventModal')).on('change', function(event) {
            eventTrigger = $('#trigger', $('#eventModal')).val();
            parameterOptions = setTriggerParameters(eventTrigger, eventIndex);
            // reset the options
            setSelectionData($('#parameter', $('#eventModal')), parameterOptions);
            setParameterValues('None');

            if (eventTrigger === 'Time') {
                let timersAvailable = [{ id: 'None', text: 'None' }];
                timerTriggerList = getTimers(currentEventsArray, masterStageIndex, stageIndex); // only get timers relevant to the level of where the event is being created
                let timerTrigger = $('#trigger_timer', $('#eventModal')).parent();
                timerTrigger.removeClass('hidden');
                
                // set the Master stage selection variables
                $.each(timerTriggerList, (index, element) => {
                    if (element) {
                        timersAvailable.push({
                            id: index,
                            text: element.title
                        });
                    }
                });

                // reset the master stage selection options
                setSelectionData($('#trigger_timer', $('#eventModal')), timersAvailable);
            }
        });

        // update the value fields when the user changes the parameter option
        $('#parameter', $('#eventModal')).on('change', function(event) {
            selectedParameter = $('#parameter', $('#eventModal')).val();
            setParameterValues(selectedParameter);

            // if Stage or Interaction is selected as a parameter, get their associated information for the parameter value
            if (selectedParameter === 'Stage') {
                let stageOptions = getSelectionOptions('stage', masterStageIndex);

                setSelectionData($('#completed_selection', $('#eventModal')), stageOptions);
            }

            if (selectedParameter === 'Interaction') {
                let interactionOptions = getSelectionOptions('interaction', stageIndex, masterStageIndex);

                setSelectionData($('#completed_selection', $('#eventModal')), interactionOptions);
            }
        });

        // Event type variables
        let eventType = $('#event_type', $('#eventModal')).val();
        // update the event fields when the user changes the event type
        $('#event_type', $('#eventModal')).on('change', function(event) {
            eventType = $('#event_type', $('#eventModal')).val();
            setEventTypeField(eventType);
            let timerEventObj = $('#event_timer', $('#eventModal')).parent();
            timerEventObj.addClass('hidden');
        
            // set the selection options for the master stage, stage and interaction boxes for the GO TO and UNLOCK... event type
            if (eventType === 'go_to_masterStage' || eventType === 'go_to_stage' || eventType === 'go_to_interaction' || eventType === 'unlock_stage' || eventType === 'unlock_interaction') {
                let masterStageOptions = [];
                masterStages = getMasterStages(); // get all master stages available
                // set the Master stage selection variables
                masterStageOptions = [{ id: 'None', text: 'None' }];
                $.each(masterStages, (index, element) => {
                    if (element) {
                        masterStageOptions.push({
                            id: index,
                            text: element.title
                        });
                    }
                });

                // reset the master stage selection options
                setSelectionData($('#masterStageOptions', $('#eventModal')), masterStageOptions);
            }

            // determine whether to hide the feedback option field
            if (eventType === 'go_to_masterStage' || eventType === 'go_to_stage' || eventType === 'go_to_interaction') {
                // hide the Feedback type selection box - Go To events will always display a pop up modal in the app
                feedbackSelectionObj.addClass('hidden');
                setSelectContent($('#eventFeedbackOptions', $('#eventModal')), 'pop_up_modal');
            } else {
                feedbackSelectionObj.removeClass('hidden');
                setSelectContent($('#eventFeedbackOptions', $('#eventModal')), '');
            }

            if (eventType === 'give_time' || eventType === 'take_time') {
                let timersAvailable = [{ id: 'None', text: 'None' }];
                timerList = getTimers(); // we want to get all available timers to ensure we can manipulate them
                timerEventObj.removeClass('hidden');
                
                // set the Master stage selection variables
                $.each(timerList, (index, element) => {
                    if (element) {
                        timersAvailable.push({
                            id: index,
                            text: element.title
                        });
                    }
                });

                // reset the master stage selection options
                setSelectionData($('#event_timer', $('#eventModal')), timersAvailable);
                $('#event_num_label', $('#eventModal')).text('Amount of time'); // number input title
            }

            if (eventType === 'give_points' || eventType === 'take_points') {
                $('#event_num_label', $('#eventModal')).text('Amount of points'); // number input title
            }
        });

        let selectedMStg = {}; // object that will hold the selected master stage variables for access later by the stage logic

        // when the master stage option changes - update the available stages to select from
        $('#masterStageOptions', $('#eventModal')).on('change', function(event) {
            mStgIndex = $('#masterStageOptions', $('#eventModal')).val();
            if (mStgIndex !== 'None') {
                selectedMStg = getMasterStage(mStgIndex); 
                let childStages = selectedMStg.stages; // get the stages that belong to the master stage
                let stageOptions = [];

                stageOptions = [{ id: 'None', text: 'None' }];
                $.each(childStages, (index, element) => {
                    if (element) {
                        stageOptions.push({
                            id: index,
                            text: element.title
                        });
                    }
                });

                // reset the stage selection options
                setSelectionData($('#stageOptions', $('#eventModal')), stageOptions);

                // clear the interaction options if necessary
                if (eventType === 'go_to_interaction') {
                    $('#interactionOptions', $('#eventModal')).empty();
                    indexInteraction = '';
                }
            } else {
                // reset the stage selection options
                $('#stageOptions', $('#eventModal')).empty();
                indexStg = '';
            }
        });

        // when the stage option changes - update the available interactions to select from
        $('#stageOptions', $('#eventModal')).on('change', function(event) {
            if (selectedMStg.stages !== undefined) {
                stgIndex = $('#stageOptions', $('#eventModal')).val();
                if (stgIndex !== 'None' || stgIndex !== null) {
                    var selectedStg = selectedMStg.stages[stgIndex];
                    if (selectedStg !== undefined) {
                        var childInteractions = selectedStg.puzzles; // get the interactions that belong to the stage
                        var interactionOptions = [];

                        interactionOptions = [{ id: 'None', text: 'None' }];
                        $.each(childInteractions, (index, element) => {
                            if (element) {
                                interactionOptions.push({
                                    id: index,
                                    text: element.title
                                });
                            }
                        });

                        // reset the interaction selection options
                        setSelectionData($('#interactionOptions', $('#eventModal')), interactionOptions);
                    }
                } else {
                    // reset the interaction selection options
                    $('#interactionOptions', $('#eventModal')).empty();
                    indexInteraction = '';
                }
            }
        });

        // feedback content component switching
        $('#eventFeedbackOptions', $('#eventModal')).on('change', function(event) {
            feedbackOptions = $('#eventFeedbackOptions', $('#eventModal')).val();
            feedbackWYSIWYGObj.addClass('hidden');
            feedbackContentObj.addClass('hidden');
            
            // change the feedback input component when they change the drop down selection
            if (feedbackOptions === 'pop_up_modal') {
                feedbackWYSIWYGObj.removeClass('hidden');
            } else if (feedbackOptions === 'notification') {
                feedbackContentObj.removeClass('hidden');
            }
        });

        // populates the fields with the values contained in the relevant table if the table is not empty
        if (!isEmpty(eventIndex)) {
            eventObj = getEventFromArray(currentEventsArray, eventIndex); // event{} <= events[{}][index]
            $('#title', $('#eventModal')).val(eventObj.title); // text
            if (eventObj.persistent_event == true) { // checkbox
                $('#persistent_event', $('#eventModal')).iCheck('check'); // checkbox
            } else {
                $('#persistent_event', $('#eventModal')).iCheck('uncheck'); // checkbox
            }
            /* --- Trigger section --- */
            setSelectContent($('#trigger', $('#eventModal')), eventObj.trigger); // trigger select
            setSelectContent($('#parameter', $('#eventModal')), eventObj.parameter); // parameter select
            if (eventObj.trigger === 'Time') {
                // make sure that we're using the correct index for displaying the selected option
                let newIndex = findTimer(eventObj.parameterValue.title, timerTriggerList);

                if (newIndex !== -1) {
                    setSelectContent($('#trigger_timer', $('#eventModal')), newIndex); // time trigger select
                } else {
                    setSelectContent($('#trigger_timer', $('#eventModal')), eventObj.trigger_timer); // time trigger select
                }
            }
            // set the parameter value fields for the selected parameter
            if (selectedParameter == 'Elapsed' || selectedParameter == 'Remaining') {
                $('#num_value', $('#eventModal')).val(eventObj.parameterValue.value);// parameter value
            } else if (selectedParameter == 'Distance' || selectedParameter == 'Duration') {
                if (typeof eventObj.parameterValue !== 'undefined') {
                    $('#ibeacon_uuid', $('#eventModal')).val(eventObj.parameterValue.object_value.ibeacon_uuid);// parameter value
                    $('#ibeacon_major', $('#eventModal')).val(eventObj.parameterValue.object_value.ibeacon_major);// parameter value
                    $('#ibeacon_minor', $('#eventModal')).val(eventObj.parameterValue.object_value.ibeacon_minor);// parameter value
                    $('#ibeacon_proximity', $('#eventModal')).val(eventObj.parameterValue.object_value.ibeacon_proximity);// parameter value
                    $('#ibeacon_duration', $('#eventModal')).val(eventObj.parameterValue.object_value.ibeacon_duration);// parameter value
                } else {
                    $('#ibeacon_uuid', $('#eventModal')).val(''); // parameter value
                    $('#ibeacon_major', $('#eventModal')).val(''); // parameter value
                    $('#ibeacon_minor', $('#eventModal')).val(''); // parameter value
                    $('#ibeacon_proximity', $('#eventModal')).val(''); // parameter value
                    $('#ibeacon_duration', $('#eventModal')).val(''); // parameter value
                }
            } else if (selectedParameter === 'Stage') {
                // parameter value
                if (typeof eventObj.parameterValue !== 'undefined') {
                    setSelectContent($('#completed_selection', $('#eventModal')),eventObj.parameterValue.stgIndex);
                }
            } else if (selectedParameter === 'Interaction') {
                // parameter value
                if (typeof eventObj.parameterValue !== 'undefined') {
                    setSelectContent($('#completed_selection', $('#eventModal')), eventObj.parameterValue.interactionIndex);
                }
            }
            setSelectContent($('#event_type', $('#eventModal')), eventObj.event_type); // event type selected
            /* --- Event Types --- */
            if (eventType === 'go_to_masterStage' || eventType === 'go_to_stage' || eventType === 'go_to_interaction' || eventType === 'unlock_stage' || eventType === 'unlock_interaction') {
                setSelectContent($('#masterStageOptions', $('#eventModal')), eventObj.content.mStgIndex);
                setSelectContent($('#stageOptions', $('#eventModal')), eventObj.content.stgIndex);
                setSelectContent($('#interactionOptions', $('#eventModal')), eventObj.content.interactionIndex);
            }
            if (eventType === 'give_time' || eventType === 'take_time') {
                // make sure that we're using the correct index for displaying the selected option
                var newIndex = findTimer(eventObj.content.title, timerList);
                if (newIndex !== -1) {
                    setSelectContent($('#event_timer', $('#eventModal')), newIndex); // time trigger select
                } else {
                    setSelectContent($('#event_timer', $('#eventModal')), eventObj.event_timer); // event timer select
                }
                $('#event_num_label', $('#eventModal')).text('Amount of time'); // number input title
                $('#num_content', $('#eventModal')).val(eventObj.content.value); // event type value
            }
            if (eventType === 'give_points' || eventType === 'take_points') {
                $('#event_num_label', $('#eventModal')).text('Amount of points'); // number input title
                $('#num_content', $('#eventModal')).val(eventObj.content); // event type value
            }
            if (eventType === 'give_note') {
                setWysiwygContent($('#wysiwyg_content', $('#eventModal')), eventObj.content); // event type value
            }
            /* --- Feedback section --- */
            setSelectContent($('#eventFeedbackOptions', $('#eventModal')), eventObj.feedback_option); // feedback select
            $('#feedback_content', $('#eventModal')).val(eventObj.feedback_content); // feedback content text input
            setWysiwygContent($('#event_wysiwyg_feedback', $('#eventModal')), eventObj.feedback_content.html); // feedback content wysiwyg 
            assignFileToFileComponent('event_feedback', eventObj.feedback_content.bg, '_bg', '_file', 'eventModal');
        } else {
            eventIndex = eventsTable.length;
            eventObj = {}; // reset the event object
            $('#title', $('#eventModal')).val(''); // text
            $('#persistent_event', $('#eventModal')).iCheck('uncheck'); // checkbox
            setSelectContent($('#trigger', $('#eventModal')), ''); // trigger type select
            setSelectContent($('#parameter', $('#eventModal')), ''); // parameter select
            setSelectContent($('#trigger_timer', $('#eventModal')), ''); // time trigger select
            $('#num_value', $('#eventModal')).val(''); // parameter value
            $('#ibeacon_uuid', $('#eventModal')).val(''); // parameter value
            $('#ibeacon_major', $('#eventModal')).val(''); // parameter value
            $('#ibeacon_minor', $('#eventModal')).val(''); // parameter value
            $('#ibeacon_proximity', $('#eventModal')).val(''); // parameter value
            $('#ibeacon_duration', $('#eventModal')).val(''); // parameter value
            setSelectContent($('#completed_selection', $('#eventModal')), ''); // parameter value
            setSelectContent($('#event_type', $('#eventModal')), ''); // event type select
            setSelectContent($('#event_timer', $('#eventModal')), ''); // event type timer select
            setSelectContent($('#masterStageOptions', $('#eventModal')), ''); // event type value
            setSelectContent($('#stageOptions', $('#eventModal')),''); // event type value
            setSelectContent($('#interactionOptions', $('#eventModal')), ''); // event type value
            setWysiwygContent($('#wysiwyg_content', $('#eventModal')), ''); // event type value
            $('#num_content', $('#eventModal')).val(''); // event type value
            setSelectContent($('#eventFeedbackOptions', $('#eventModal')),''); // feedback select
            $('#feedback_content', $('#eventModal')).val(''); // feedback content text input
            setWysiwygContent($('#event_wysiwyg_feedback', $('#eventModal')), ''); // feedback wysiwyg input
            assignFileToFileComponent('event_feedback', '', '_bg', '_file', 'eventModal');
        }
    });

    function setTriggerOptions() {
        result = [];

        switch(currentEventsArray) {
            case 'experience_events':
                if (gameData.extra_settings.includes('time_restriction')) {
                    result.push({
                        id: 'Time',
                        text: 'Time'
                    });
                }
                break;
            case 'm_stg_events':
                let timerPresent = false;
                result.push({
                    id: 'Completed',
                    text: 'Completed'
                });

                if (masterStages[masterStageIndex].time_restriction) {
                    timerPresent = true;
                } else {
                    // check stages in master stage if time_restriction exists, if it does, set to true and break the for loop
                    for (var i = 0; i < masterStages[masterStageIndex].stages.length; i++) {
                        if (masterStages[masterStageIndex].stages[i].time_restriction) {
                            timerPresent = true;
                            break;
                        }
                    }
                }

                if (timerPresent) {
                    result.push({
                        id: 'Time',
                        text: 'Time'
                    });
                }
                break;
            case 'stg_events':
                result.push({
                    id: 'Completed',
                    text: 'Completed'
                });
                if (stages[stageIndex].time_restriction !== '' && stages[stageIndex].time_restriction > 0) {
                    result.push({
                        id: 'Time',
                        text: 'Time'
                    });
                }
                break;
        }

        result.unshift({
            id: 'None', 
            text: 'None'
        }, 
        {
            id: 'Beacon',
            text: 'Beacon'
        });

        return result;
    }

    // displays parameters that are available to a trigger
    function setTriggerParameters(trigger) {
        let parameters = [];
        let timerTrigger = $('#trigger_timer', $('#eventModal')).parent();
        timerTrigger.addClass('hidden');

        switch(trigger) {
            case 'Time':
                parameters = [{
                    id: 'None',
                    text: 'None'
                },
                {
                    id: 'Remaining',
                    text: 'Remaining'
                },
                {
                    id: 'Elapsed',
                    text: 'Elapsed'
                }];
                break;
            case 'Beacon':
                parameters = [{
                    id: 'None',
                    text: 'None'
                },
                {
                    id:'Distance',
                    text: 'Distance'
                },
                {
                    id: 'Duration',
                    text: 'Duration'
                }];
                break;
            case 'Stage':
                stages = getStages(); // stages[{}] <= masterStage{}.stages
                // trigger when stage completed
                parameters = [{ id: 'None', text: 'None' }];
                $.each(stages, (index, element) => {
                    if (element) {
                        parameters.push({
                            id: index,
                            text: element.title
                        });
                    }
                });
                break;
            case 'Interaction':
                interactions = getPuzzles(); // puzzles[{}] <= stage{}.puzzles
                // trigger when interaction completed
                parameters = [{ id: 'None', text: 'None' }];
                $.each(interactions, (index, element) => {
                    if (element) {
                        parameters.push({
                            id: index,
                            text: element.title
                        });
                    }
                });
                break;
            case 'Completed':
                if (currentEventsArray === 'm_stg_events') {
                    parameters = [{ 
                        id: 'None', 
                        text: 'None'
                    },
                    {
                        id: 'Stage', 
                        text: 'Stage'
                    }];
                } else if (currentEventsArray === 'stg_events') {
                    parameters = [{ 
                        id: 'None', 
                        text: 'None'
                    },
                    {
                        id: 'Interaction', 
                        text: 'Interaction'
                    }];
                }
                break;
            default:
                parameters = [{id: 'NA', text: 'NA'}];
                break;
        }

        return parameters;
    }

    function setParameterValues(parameter) {
        // value fields
        let numValue = $('#num_value', $('#eventModal')).parent().parent();
        let beaconTrigger = $('#beacon_trigger', $('#eventModal'));
        let completedSelection = $('#completed_selection', $('#eventModal')).parent();

        numValue.addClass('hidden');
        beaconTrigger.addClass('hidden');
        completedSelection.addClass('hidden');

        // conditionally show the fields
        if (parameter === 'Elapsed' || parameter === 'Remaining') {
            numValue.removeClass('hidden');
        } else if (parameter === 'Distance' || parameter === 'Duration') {
            beaconTrigger.removeClass('hidden');
        } else if (parameter === 'Stage' || parameter === 'Interaction') {
            completedSelection.removeClass('hidden');
        }
    }

    function setEventTypeField(type) {
        // type fields
        let numContent = $('#num_content', $('#eventModal')).parent();
        let wysiwygContent = $('#wysiwyg_content', $('#eventModal')).parent();
        let masterStageSelect = $('#masterStageOptions', $('#eventModal')).parent();
        let stageSelect = $('#stageOptions', $('#eventModal')).parent();
        let interactionSelect = $('#interactionOptions', $('#eventModal')).parent();

        numContent.addClass('hidden');
        wysiwygContent.addClass('hidden');
        masterStageSelect.addClass('hidden');
        stageSelect.addClass('hidden');
        interactionSelect.addClass('hidden');

        // conditionally show the fields
        if (type === 'give_points' || type === 'take_points' || type === 'give_time' || type === 'take_time') {
            numContent.removeClass('hidden');
        } else if (type === 'give_note') {
            wysiwygContent.removeClass('hidden');
        } else if (type === 'go_to_masterStage') {
            masterStageSelect.removeClass('hidden');
        } else if (type === 'go_to_stage' || type === 'unlock_stage') {
            masterStageSelect.removeClass('hidden');
            stageSelect.removeClass('hidden');
        } else if (type === 'go_to_interaction' || type === 'unlock_interaction') {
            masterStageSelect.removeClass('hidden');
            stageSelect.removeClass('hidden');
            interactionSelect.removeClass('hidden');
        }
    }

    /*
     * open master stage modal
     */
    $(document).on('click', '.btn-open-master-stage-modal', (event) => {
        let target = $(event.target);
        masterStageIndex = target.attr('data-master-stage-index'); // update the current master stage index  

        // reset the inner table every time when modal is opened
        $('table#master_stage_events').DataTable().clear().draw();
        $('table#stage_table').DataTable().clear().draw();

        masterStages = getMasterStages(); // masterStages[{}] <= gameData

        // hide the minimum stages to proceed field if the structure is linear
        // hide the abandon stage allowed check box if the structure is linear
        if ($('#structure', $('#masterStageModal')).val() == 'Non-Linear') {
            $('#stage_sel_instructions', $('#masterStageModal')).parent().show();
            $('#minimum_requirements', $('#masterStageModal')).parent().show();
            $('#abandon', $('#masterStageModal')).parents('.col-sm-6').first().show();
        } else {
            $('#stage_sel_instructions', $('#masterStageModal')).parent().hide();
            $('#minimum_requirements', $('#masterStageModal')).parent().hide();
            $('#abandon', $('#masterStageModal')).parents('.col-sm-6').first().hide();
            setWysiwygContent($('#stage_sel_instructions', $('#masterStageModal')), '');
            $('#minimum_requirements', $('#masterStageModal')).val('');
            $('#abandon', $('#masterStageModal')).iCheck('uncheck');
        }

        $('#structure', $('#masterStageModal')).on('change', function(event) {
            if ($('#structure', $('#masterStageModal')).val() == 'Non-Linear') {
                $('#stage_sel_instructions', $('#masterStageModal')).parent().show();
                $('#minimum_requirements', $('#masterStageModal')).parent().show();
                $('#abandon', $('#masterStageModal')).parents('.col-sm-6').first().show();
            } else {
                $('#stage_sel_instructions', $('#masterStageModal')).parent().hide();
                $('#minimum_requirements', $('#masterStageModal')).parent().hide();
                $('#abandon', $('#masterStageModal')).parents('.col-sm-6').first().hide();
                setWysiwygContent($('#stage_sel_instructions', $('#masterStageModal')), '');
                $('#minimum_requirements', $('#masterStageModal')).val('');
                $('#abandon', $('#masterStageModal')).iCheck('uncheck');
            }
        });

        if (!isEmpty(masterStageIndex)) {
            masterStage = getMasterStage(masterStageIndex); // masterStage{} <= masterStages[{}][index]

            $('#title', $('#masterStageModal')).val(masterStage.title); // text
            setSelectContent($('#structure', $('#masterStageModal')), masterStage.structure); // select
            $('#minimum_requirements', $('#masterStageModal')).val(masterStage.minimum_requirements); // text
            if (masterStage.abandon_stage_allowed == 'yes') { // checkbox
                $('#abandon', $('#masterStageModal')).iCheck('check');
            } else {
                $('#abandon', $('#masterStageModal')).iCheck('uncheck');
            }
            $('#time_restriction', $('#masterStageModal')).val(masterStage.time_restriction); // text
            setSelectContent($('#time_penalty', $('#masterStageModal')), masterStage.time_penalty); // select
            $('#time_restriction_title', $('#masterStageModal')).val(masterStage.time_restriction_title); // text
            $('#master_stage_map', $('#masterStageModal')).val(masterStage.master_stage_map_image); //file
            fileUploadRefreshSingle($('#master_stage_map_file'), masterStage.master_stage_map_image);
            setWysiwygContent($('#stage_sel_instructions', $('#masterStageModal')), masterStage.stage_sel_instructions); // wysiwyg
            // feedback
            setWysiwygContent($('#instruction', $('#masterStageModal')), masterStage.instruction.html); // wysiwyg
            assignFileToFileComponent('mstg_instruction', masterStage.instruction.bg, '_bg', '_file', 'masterStageModal'); // bg file
            setWysiwygContent($('#success_screen', $('#masterStageModal')), masterStage.success_screen.html); // wysiwyg
            assignFileToFileComponent('mstg_success_screen', masterStage.success_screen.bg, '_bg', '_file', 'masterStageModal'); // bg file
            setWysiwygContent($('#failure_screen', $('#masterStageModal')), masterStage.failure_screen.html); // wysiwyg
            assignFileToFileComponent('mstg_failure_screen', masterStage.failure_screen.bg, '_bg', '_file', 'masterStageModal'); // bg file

            // theme setting for master stage
            if (!isEmpty(masterStage.theme_settings)) {
                let masterStageThemeSettings = masterStage.theme_settings;
                $('.master_stage_theme #master_stage_theme_logo_image').val(masterStageThemeSettings.master_stage_theme_logo_image);
                $('.master_stage_theme #master_stage_theme_background_image').val(masterStageThemeSettings.master_stage_theme_background_image);
                $('.master_stage_theme #primary_color').val(masterStageThemeSettings.primary_color);
                setSelectContent($('.master_stage_theme #primary_font_family'), masterStageThemeSettings.primary_font_family);
                $('.master_stage_theme #primary_font_size').val(convertFromAppPercentage(masterStageThemeSettings.primary_font_size, 40));
                $('.master_stage_theme #secondary_color').val(masterStageThemeSettings.secondary_color);
                setSelectContent($('.master_stage_theme #secondary_font_family'), masterStageThemeSettings.secondary_font_family);
                $('.master_stage_theme #secondary_font_size').val(convertFromAppPercentage(masterStageThemeSettings.secondary_font_size, 30));
                $('.master_stage_theme #tertiary_color').val(masterStageThemeSettings.tertiary_color);
                setSelectContent($('.master_stage_theme #tertiary_font_family'), masterStageThemeSettings.tertiary_font_family);
                $('.master_stage_theme #tertiary_font_size').val(convertFromAppPercentage(masterStageThemeSettings.tertiary_font_size, 20));
                fileUploadRefreshSingle($('#master_stage_theme_logo_image_file'), masterStageThemeSettings.master_stage_theme_logo_image);
                fileUploadRefreshSingle($('#master_stage_theme_background_image_file'), masterStageThemeSettings.master_stage_theme_background_image);
            } else {
                // reset theme settings
                fileUploadResetSingle($('#master_stage_theme_logo_image_file'));
                $('.master_stage_theme #master_stage_theme_logo_image').val('');
                fileUploadResetSingle($('#master_stage_theme_background_image_file'));
                $('.master_stage_theme #master_stage_theme_background_image').val('');
                $('.master_stage_theme #primary_color').val('');
                setSelectContent($('.master_stage_theme #primary_font_family'), '');
                $('.master_stage_theme #primary_font_size').val('');
                $('.master_stage_theme #secondary_color').val('');
                setSelectContent($('.master_stage_theme #secondary_font_family'), '');
                $('.master_stage_theme #secondary_font_size').val('');
                $('.master_stage_theme #tertiary_color').val('');
                setSelectContent($('.master_stage_theme #tertiary_font_family'), '');
                $('.master_stage_theme #tertiary_font_size').val('');
            }

            /* ----- Master Stage Events Array ----- */
            mStgEvents = getEventsArray('m_stg_events');
            
            $.each(mStgEvents, (key, value) => {
                if (!isEmpty(value)) {
                    $('table#master_stage_events').DataTable().row.add([
                        key,
                        '<a class="btn-open-event-modal event-title" href="#" data-toggle="modal" data-target="#eventModal" data-event-index=' + key + '>' + value.title + '</a>',
                        '<span class="event-trigger" data-event-index=' + key + '>' + value.trigger + '</span>',
                        '<span class="event-event" data-event-index=' + key + '>' + value.event_type + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="event" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                }
            });
            
            // initalise stages for this master stage
            stages = getStages(); // stages[{}] <= masterStage{}.stages

            $.each(stages, (key, value) => {
                if (!isEmpty(value)) {
                    $('table#stage_table').DataTable().row.add([
                        key,
                        '<a class="btn-open-stage-modal stage-title" href="#" data-toggle="modal" data-target="#stageModal" data-stage-index=' + key + '>' + value.title + '</a>',
                        '<span class="stage-display-title" data-stage-index=' + key + '>' + value.display_title + '</span>',
                        '<span class="stage-game-mode" data-stage-index=' + key + '>' + value.game_mode + '</span>',
                        '<span class="stage-unlock-on" data-stage-index=' + key + '>' + value.unlock_on + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="stage" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                }
            });
        } else {
            // modal - create new 
            masterStageIndex = masterStages.length; // automatically create a new index based on the length
            masterStage = getMasterStage(masterStageIndex); // masterStage{} <= masterStages[{}][index]; {}
            currentEventsArray = 'm_stg_events';
            $('#title', $('#masterStageModal')).val(''); // text
            setSelectContent($('#structure', $('#masterStageModal')), ''); // select
            $('#minimum_requirements', $('#masterStageModal')).val(''); // text
            $('#abandon', $('#masterStageModal')).iCheck('uncheck'); // checkbox
            $('#time_restriction', $('#masterStageModal')).val(''); // text
            setSelectContent($('#time_penalty', $('#masterStageModal')), ''); // select
            $('#time_restriction_title', $('#masterStageModal')).val(''); // text
            $('#master_stage_map', $('#masterStageModal')).val(''); // file
            fileUploadResetSingle($('#master_stage_map_file'));
            setWysiwygContent($('#stage_sel_instructions', $('#masterStageModal')), '');
            // feedback
            setWysiwygContent($('#instruction', $('#masterStageModal')), ''); // wysiwyg
            assignFileToFileComponent('instruction', '', '_bg', '_file', 'masterStageModal'); // bg file
            setWysiwygContent($('#success_screen', $('#masterStageModal')), ''); // wysiwyg
            assignFileToFileComponent('mstg_success_screen', '', '_bg', '_file', 'masterStageModal'); // bg file
            setWysiwygContent($('#failure_screen', $('#masterStageModal')), ''); // wysiwyg
            assignFileToFileComponent('mstg_failure_screen', '', '_bg', '_file', 'masterStageModal'); // bg file
            // reset theme settings
            fileUploadResetSingle($('#master_stage_theme_logo_image_file'));
            $('.master_stage_theme #master_stage_theme_logo_image').val('');
            fileUploadResetSingle($('#master_stage_theme_background_image_file'));
            $('.master_stage_theme #master_stage_theme_background_image').val('');
            $('.master_stage_theme #primary_color').val('');
            setSelectContent($('.master_stage_theme #primary_font_family'), '');
            $('.master_stage_theme #primary_font_size').val('');
            $('.master_stage_theme #secondary_color').val('');
            setSelectContent($('.master_stage_theme #secondary_font_family'), '');
            $('.master_stage_theme #secondary_font_size').val('');
            $('.master_stage_theme #tertiary_color').val('');
            setSelectContent($('.master_stage_theme #tertiary_font_family'), '');
            $('.master_stage_theme #tertiary_font_size').val('');

            // initalise events for this master stage
            mStgEvents = [];
            // initalise stages for this master stage
            stages = [];
        }
    });

    /*
     * opening stage modal
     */
    $(document).on('click', '.btn-open-stage-modal', (event) => {
        let target = $(event.target);
        let parentModal = target.closest('.modal').parent().prevObject;
        // check if Master stage is valid before continuing. If it is not, prompt user to fix up Master stage before proceeding to build the stage
        $.when(checkModalValidity(parentModal)).then((isValid) => {
            if (isValid === true) {
                stageIndex = target.attr('data-stage-index'); // update the current stage index

                // reset the inner table every time when modal is opened
                $('table#stage_events').DataTable().clear().draw();
                $('table#puzzle_table').DataTable().clear().draw();

                stages = getStages(); // stages[{}] <= masterStage{}.stages

                let unlockOnOptions = [{ id: 'None', text: 'None' }];
                $.each(stages, (index, element) => {
                    // exclude itself
                    if (stageIndex != index) {
                        if (element) {
                            unlockOnOptions.push({
                                id: element.title,
                                text: element.title
                            });
                        }
                    }
                });
                // reset the options
                setSelectionData($('#unlock_on', $('#stageModal')), unlockOnOptions);

                const unlockOnSelection = $('#unlock_on', $('#stageModal')).parent();
                // show/hide unlock stage depending selection dropdown for unlocking a stage when lock stage is checked/unchecked
                if ($('#lock_stage', $('#stageModal')).is(':checked')) {
                    unlockOnSelection.show();
                } else {
                    unlockOnSelection.val('None');
                    unlockOnSelection.hide();
                }

                $('#lock_stage', $('#stageModal')).on('ifChecked', function(){
                    unlockOnSelection.show();
                });
                $('#lock_stage', $('#stageModal')).on('ifUnchecked', function(){
                    unlockOnSelection.val('None');
                    unlockOnSelection.hide();
                });

                // hide the minimum stages to proceed field and finish minimum number of puzzles success screen if the structure is linear
                const minimumRequirementsField = $('#minimum_requirements', $('#stageModal')).parent();
                const minimumRequirementsValueField = $('#minimum_requirements', $('#stageModal'));
                const puzzleSelectionInstructions = $('#sel_instructions', $('#stageModal')).parent();

                if ($('#structure', $('#stageModal')).val() == 'Non-Linear') {
                    puzzleSelectionInstructions.show();
                    minimumRequirementsField.show();
                } else {
                    puzzleSelectionInstructions.hide();
                    minimumRequirementsField.hide();
                    minimumRequirementsValueField.val('');
                }
                $('#structure', $('#stageModal')).on('change', function(event) {
                    if ($('#structure', $('#stageModal')).val() == 'Non-Linear') {
                        puzzleSelectionInstructions.show();
                        minimumRequirementsField.show();
                    } else {
                        puzzleSelectionInstructions.hide();
                        setWysiwygContent($('#sel_instructions', $('#stageModal')), '');
                        minimumRequirementsField.hide();
                        minimumRequirementsValueField.val('');
                    }
                });

                // show the target points only if the type is Scavenger
                const targetPointsField = $('#target_points', $('#stageModal')).parent();
                const targetPointsValueField = $('#target_points', $('#stageModal'));

                if ($('#game_mode', $('#stageModal')).val() == 'Scavenger') {
                    targetPointsField.show();
                } else {
                    targetPointsField.hide();
                    targetPointsValueField.val('');
                }
                $('#game_mode', $('#stageModal')).on('change', function(event) {
                    if ($('#game_mode', $('#stageModal')).val() == 'Scavenger') {
                        targetPointsField.show();
                    } else {
                        targetPointsField.hide();
                        targetPointsValueField.val('');
                    }
                });

                // show the run out of time failure screen when time restriction has value
                const failureScreenField = $('#failure_screen', $('#stageModal')).parent().parent().parent();
                $('#time_restriction', $('#stageModal')).on('input', function(event) {
                    if (parseInt($('#time_restriction', $('#stageModal')).val()) > 0) {
                        failureScreenField.show();
                    } else {
                        failureScreenField.hide();  
                    }
                });

                if (!isEmpty(stageIndex)) {
                    stage = getStage(stageIndex); // stage{} <= stages[{}][index]
                    $('#title', $('#stageModal')).val(stage.title); // text
                    $('#display_title', $('#stageModal')).val(stage.display_title); // text
                    setSelectContent($('#game_mode', $('#stageModal')), stage.game_mode); // select
                    setSelectContent($('#structure', $('#stageModal')), stage.structure); // select
                    $('#minimum_requirements', $('#stageModal')).val(stage.minimum_requirements); // text
                    if (stage.show_progress === 'yes') { // checkbox
                        $('#show_progress', $('#stageModal')).iCheck('check');
                    } else {
                        $('#show_progress', $('#stageModal')).iCheck('uncheck');
                    }
                    if (stage.repeatable_stage === 'yes') { // checkbox
                        $('#repeatable_stage', $('#stageModal')).iCheck('check');
                    } else {
                        $('#repeatable_stage', $('#stageModal')).iCheck('uncheck');
                    }
                    $('#target_points', $('#stageModal')).val(stage.target_points); // text
                    if (stage.locked_stage === true) { // checkbox
                        $('#lock_stage', $('#stageModal')).iCheck('check');
                    } else {
                        $('#lock_stage', $('#stageModal')).iCheck('uncheck');
                    }
                    setSelectContent($('#unlock_on', $('#stageModal')), stage.unlock_on); // select
                    $('#time_restriction', $('#stageModal')).val(stage.time_restriction); // text
                    setSelectContent($('#time_penalty', $('#stageModal')), stage.time_penalty); // select
                    $('#time_restriction_title', $('#stageModal')).val(stage.time_restriction_title); // text
                    setWysiwygContent($('#sel_instructions', $('#stageModal')), stage.selection_instructions); // wysiwyg
                    $('#stage_selection_image', $('#stageModal')).val(stage.selection_image); // file
                    fileUploadRefreshSingle($('#stage_selection_image_file'), stage.selection_image);
                    $('#stage_selection_map', $('#stageModal')).val(stage.selection_map); //file
                    fileUploadRefreshSingle($('#stage_selection_map_file'), stage.selection_map);
                    // feedback
                    setWysiwygContent($('#instructions', $('#stageModal')), stage.instructions.html); // wysiwyg
                    assignFileToFileComponent('instructions', stage.instructions.bg, '_bg', '_file', 'stageModal'); // bg file
                    setWysiwygContent($('#success_all_screen', $('#stageModal')), stage.success_all_screen.html); // wysiwyg
                    assignFileToFileComponent('success_all_screen', stage.success_all_screen.bg, '_bg', '_file', 'stageModal'); // bg file
                    setWysiwygContent($('#failure_screen', $('#stageModal')), stage.failure_screen.html); // wysiwyg
                    assignFileToFileComponent('stage_failure_screen', stage.failure_screen.bg, '_bg', '_file', 'stageModal'); // bg file

                    // show the run out of time failure screen when time restriction greater then zero
                    if (parseInt(stage.time_restriction) > 0) {
                        failureScreenField.show();
                    } else {
                        failureScreenField.hide();
                    }
                    // theme setting for stage
                    if (!isEmpty(stage.theme_settings)) {
                        let stageThemeSettings = stage.theme_settings;
                        $('.stage_theme #stage_theme_logo_image').val(stageThemeSettings.stage_theme_logo_image);
                        $('.stage_theme #stage_theme_background_image').val(stageThemeSettings.stage_theme_background_image);
                        $('.stage_theme #primary_color').val(stageThemeSettings.primary_color);
                        setSelectContent($('.stage_theme #primary_font_family'), stageThemeSettings.primary_font_family);
                        $('.stage_theme #primary_font_size').val(convertFromAppPercentage(stageThemeSettings.primary_font_size, 40));
                        $('.stage_theme #secondary_color').val(stageThemeSettings.secondary_color);
                        setSelectContent($('.stage_theme #secondary_font_family'), stageThemeSettings.secondary_font_family);
                        $('.stage_theme #secondary_font_size').val(convertFromAppPercentage(stageThemeSettings.secondary_font_size, 30));
                        $('.stage_theme #tertiary_color').val(stageThemeSettings.tertiary_color);
                        setSelectContent($('.stage_theme #tertiary_font_family'), stageThemeSettings.tertiary_font_family);
                        $('.stage_theme #tertiary_font_size').val(convertFromAppPercentage(stageThemeSettings.tertiary_font_size, 20));
                        fileUploadRefreshSingle($('#stage_theme_logo_image_file'), stageThemeSettings.stage_theme_logo_image);
                        fileUploadRefreshSingle($('#stage_theme_background_image_file'), stageThemeSettings.stage_theme_background_image);
                    } else {
                        // reset theme settings
                        fileUploadResetSingle($('#stage_theme_logo_image_file'));
                        $('.stage_theme #stage_theme_logo_image').val('');
                        fileUploadResetSingle($('#stage_theme_background_image_file'));
                        $('.stage_theme #stage_theme_background_image').val('');
                        $('.stage_theme #primary_color').val('');
                        setSelectContent($('.stage_theme #primary_font_family'), '');
                        $('.stage_theme #primary_font_size').val('');
                        $('.stage_theme #secondary_color').val('');
                        setSelectContent($('.stage_theme #secondary_font_family'), '');
                        $('.stage_theme #secondary_font_size').val('');
                        $('.stage_theme #tertiary_color').val('');
                        setSelectContent($('.stage_theme #tertiary_font_family'), '');
                        $('.stage_theme #tertiary_font_size').val('');
                    }

                    /* ----- Stage Events Array ----- */
                    stgEvents = getEventsArray('stg_events');
                    
                    $.each(stgEvents, (key, value) => {
                        if (!isEmpty(value)) {
                            $('table#stage_events').DataTable().row.add([
                                key,
                                '<a class="btn-open-event-modal event-title" href="#" data-toggle="modal" data-target="#eventModal" data-event-index=' + key + '>' + value.title + '</a>',
                                '<span class="event-trigger" data-event-index=' + key + '>' + value.trigger + '</span>',
                                '<span class="event-event" data-event-index=' + key + '>' + value.event_type + '</span>',
                                '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="event" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                            ]).draw();
                        }
                    });

                    // initalise puzzles for this stage
                    puzzles = getPuzzles(); // puzzles[{}] <= stage{}.puzzles

                    $.each(puzzles, (key, value) => {
                        if (!isEmpty(value)) {
                            let numOfHints = 0;
                            if (!isEmpty(value.hints)) {
                                numOfHints = value.hints.length;
                            }
                            $('table#puzzle_table').DataTable().row.add([
                                key,
                                '<a class="btn-open-puzzle-modal puzzle-title" href="#" data-toggle="modal" data-target="#puzzleModal" data-puzzle-index=' + key + '>' + value.title + '</a>',
                                '<span class="puzzle-type" data-puzzle-index=' + key + '>' + value.type + '</span>',
                                '<span class="puzzle-num-hints" data-puzzle-index=' + key + '>' + numOfHints + '</span>',
                                '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="puzzle" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                            ]).draw();
                        }
                    });
                } else {
                    // modal - create new
                    stageIndex = stages.length;
                    stage = getStage(stageIndex) // stage{} <= stage[{}][index]; {}
                    currentEventsArray = 'stg_events';
                    $('#title', $('#stageModal')).val(''); // text
                    $('#display_title', $('#stageModal')).val(''); // text
                    setSelectContent($('#game_mode', $('#stageModal')), ''); // select
                    setSelectContent($('#structure', $('#stageModal')), ''); // select
                    $('#minimum_requirements', $('#stageModal')).val(''); // text
                    $('#show_progress', $('#stageModal')).iCheck('uncheck'); // checkbox
                    $('#repeatable_stage', $('#stageModal')).iCheck('uncheck'); // checkbox
                    $('#target_points', $('#stageModal')).val(''); // text
                    $('#lock_stage', $('#stageModal')).iCheck('uncheck'); // checkbox
                    setSelectContent($('#unlock_on', $('#stageModal')), 'None'); // select
                    $('#time_restriction', $('#stageModal')).val(''); // select
                    setSelectContent($('#time_penalty', $('#stageModal')), ''); // select
                    $('#time_restriction_title', $('#stageModal')).val(''); // select
                    setWysiwygContent($('#sel_instructions', $('#stageModal')), '');
                    
                    $('#stage_selection_image', $('#stageModal')).val('');
                    $('#stage_selection_map', $('#stageModal')).val('');
                    fileUploadResetSingle($('#stage_selection_image_file'));
                    fileUploadResetSingle($('#stage_selection_map_file'));
                    // feedback
                    setWysiwygContent($('#instructions', $('#stageModal')), '');
                    assignFileToFileComponent('instructions', '', '_bg', '_file', 'stageModal'); // bg file
                    setWysiwygContent($('#success_all_screen', $('#stageModal')), '');
                    assignFileToFileComponent('success_all_screen', '', '_bg', '_file', 'stageModal'); // bg file
                    // setWysiwygContent($('#success_min_screen', $('#stageModal')), ''); <-- getting rid of
                    setWysiwygContent($('#failure_screen', $('#stageModal')), '');
                    assignFileToFileComponent('stage_failure_screen', '', '_bg', '_file', 'stageModal'); // bg file
                    // hide failure screen field initially
                    failureScreenField.hide();
                    // reset theme settings
                    fileUploadResetSingle($('#stage_theme_logo_image_file'));
                    $('.stage_theme #stage_theme_logo_image').val('');
                    fileUploadResetSingle($('#stage_theme_background_image_file'));
                    $('.stage_theme #stage_theme_background_image').val('');
                    $('.stage_theme #primary_color').val('');
                    setSelectContent($('.stage_theme #primary_font_family'), '');
                    $('.stage_theme #primary_font_size').val('');
                    $('.stage_theme #secondary_color').val('');
                    setSelectContent($('.stage_theme #secondary_font_family'), '');
                    $('.stage_theme #secondary_font_size').val('');
                    $('.stage_theme #tertiary_color').val('');
                    setSelectContent($('.stage_theme #tertiary_font_family'), '');
                    $('.stage_theme #tertiary_font_size').val('');

                    stgEvents = [];
                    // initalise puzzles for this stage
                    puzzles = [];
                }
            } else {
                $('#stageModal').modal('hide');
                parentModal.animate({ scrollTop: 0 }, 1000);
            }
        });
    });

    /*
     * opening puzzle modal
     */
    $('#type', $('#puzzleModal')).on('change', (event) => {
        let select = $(event.target);
        let type = select.val();
        if (!isEmpty(type)) {
            $('.answer-field', $('#puzzleModal')).each((index, element) => {
                if ($(element).hasClass(type.replace(/ /g, '-') + '-field')) {
                    $(element).removeClass('hidden');
                } else {
                    $(element).addClass('hidden');
                }
            });

            if (type === 'image selection') {
                $('.image_index', $('#answer_table')).show();
                $('.answer-data', $('#answer_table')).parent().show();
            } else {
                $('.image_index', $('#answer_table')).hide();
                $('.answer-data', $('#answer_table')).parent().hide();
            }
        } else {
            // hide all of them
            $('.answer-field', $('#puzzleModal')).addClass('hidden');
        }
    });

    $(document).on('click', '.btn-open-puzzle-modal', (event) => {
        let target = $(event.target);
        let parentModal = target.closest('.modal').parent().prevObject;
        $.when(checkModalValidity(parentModal)).then((isValid) => {
            if (isValid === true) {
                puzzleIndex = target.attr('data-puzzle-index'); // update the current puzzle index

                // reset the inner table every time when modal is opened
                $('table#answer_table').DataTable().clear().draw();
                $('table#reward_table').DataTable().clear().draw();
                $('table#hint_table').DataTable().clear().draw();

                puzzles = getPuzzles(); // puzzles[{}] <= stage{}.puzzles

                // unlock interaction dependent on completion of another interaction
                let unlockPuzzleOptions = [{ id: 'None', text: 'None' }];
                $.each(puzzles, (index, element) => {
                    // exclude itself
                    if (puzzleIndex != index) {
                        if (element) {
                            unlockPuzzleOptions.push({
                                id: element.title,
                                text: element.title
                            });
                        }
                    }
                });
                // reset the options
                setSelectionData($('#unlock_on_interaction', $('#puzzleModal')), unlockPuzzleOptions);

                // hide the unlock interaction and repeatable interaction if the structure is linear
                const unlockOnInteractionField = $('#unlock_on_interaction', $('#puzzleModal')).parent();
                const lockInteractionCheckbox = $('#lock_interaction', $('#puzzleModal')).parent().parent();

               // if stage structure is non linear show the lock interaction check box
                if ($('#structure', $('#stageModal')).val() == 'Non-Linear') {
                    lockInteractionCheckbox.show();
                    $('#repeatable_interaction', $('#puzzleModal')).parent().parent().show();
                } else {
                    lockInteractionCheckbox.hide();
                    unlockOnInteractionField.val('None');
                    $('#repeatable_interaction', $('#puzzleModal')).parent().parent().hide();
                }

                $('#structure', $('#stageModal')).on('change', function(event) {
                    if ($('#structure', $('#stageModal')).val() == 'Non-Linear') {
                        lockInteractionCheckbox.show();
                        $('#repeatable_interaction', $('#puzzleModal')).parent().parent().show();
                    } else {
                        lockInteractionCheckbox.hide();
                        unlockOnInteractionField.val('None');
                        $('#repeatable_interaction', $('#puzzleModal')).parent().parent().hide();
                    }
                });

                // show/hide unlock interaction depending selection dropdown for unlocking an interaction when lock interaction is checked/unchecked
                if ($('#lock_interaction', $('#puzzleModal')).is(':checked')) {
                    unlockOnInteractionField.show();
                } else {
                    unlockOnInteractionField.val('None');
                    unlockOnInteractionField.hide();
                }

                $('#lock_interaction', $('#puzzleModal')).on('ifChecked', function(){
                    unlockOnInteractionField.show();
                });
                $('#lock_interaction', $('#puzzleModal')).on('ifUnchecked', function(){
                    unlockOnInteractionField.val('None');
                    unlockOnInteractionField.hide();
                });

                // show/hide the AR file components if Add AR component is checked
                const arImageReference = $('#ar_target_image_reference', $('#puzzleModal')).parent();
                const arObject = $('#ar_object_file', $('#puzzleModal')).parent();
                if ($('#ar_content', $('#puzzleModal')).is(':checked')) {
                    arImageReference.show();
                    arObject.show();
                } else {
                    arImageReference.hide();
                    arObject.hide();
                }

                $('#ar_content', $('#puzzleModal')).on('ifChecked', function(){
                    arImageReference.show();
                    arObject.show();
                });
                $('#ar_content', $('#puzzleModal')).on('ifUnchecked', function(){
                    arImageReference.hide();
                    arObject.hide();
                });

                // hide the question field if the interaction type is set to Content
                const questionField = $('#question', $('#puzzleModal')).parent().parent().parent();

                if (($('#type', $('#puzzleModal')).val()) !== 'content') {
                    questionField.show();
                } else {
                    questionField.hide();
                }

                // on changing to Content field
                $('#type', $('#puzzleModal')).on('change', function(event) {
                    if (($('#type', $('#puzzleModal')).val()) !== 'content') {
                        questionField.show();
                    } else {
                        questionField.hide();
                    }
                });

                // answer based on the type
                let answer = {};
                // clear all input
                $('#text_default_content', $('#puzzleModal')).val('');
                $('#number_default_content', $('#puzzleModal')).val('');
                $('#image_selection_images', $('#puzzleModal')).val('');
                fileUploadResetMultiple($('#image_selection_image_file', $('#puzzleModal')));
                $('#image_pairing_images', $('#puzzleModal')).val('');
                fileUploadResetMultiple($('#image_pairing_image_file', $('#puzzleModal')));
                setWysiwygContent($('#content', $('#puzzleModal')), '');

                // show the target points only if the type is Scavenger
                if ($('#game_mode', $('#stageModal')).val() === 'Scavenger') {
                    $('#points', $('#puzzleModal')).parent().show();
                } else {
                    $('#points', $('#puzzleModal')).parent().hide();
                    $('#points', $('#puzzleModal')).val('');
                }
                $('#game_mode', $('#stageModal')).on('change', function(event) {
                    if ($('#game_mode', $('#stageModal')).val() === 'Scavenger') {
                        $('#points', $('#puzzleModal')).parent().show();
                    } else {
                        $('#points', $('#puzzleModal')).parent().hide();
                        $('#points', $('#puzzleModal')).val('');
                    }
                });

                if (!isEmpty(puzzleIndex)) {
                    $('.image_index', $('#answer_table')).hide();

                    puzzle = getPuzzle(puzzleIndex); // puzzle{} <= puzzles[{}][index]
                    $('#title', $('#puzzleModal')).val(puzzle.title); // text
                    $('#display_title', $('#puzzleModal')).val(puzzle.display_title); // text
                    $('#short_description', $('#puzzleModal')).val(puzzle.short_description); // text
                    $('#puzzle_selection_image', $('#puzzleModal')).val(puzzle.selection_image); // file
                    fileUploadRefreshSingle($('#puzzle_selection_image_file'), puzzle.selection_image);

                    if (puzzle.locked_interaction === true) { // checkbox
                        $('#lock_interaction', $('#puzzleModal')).iCheck('check');
                    } else {
                        $('#lock_interaction', $('#puzzleModal')).iCheck('uncheck');
                    }
                    setSelectContent($('#unlock_on_interaction', $('#puzzleModal')), puzzle.unlock_on_interaction); // select

                    // AR Component
                    if (puzzle.ar_content === true) {
                        $('#ar_content', $('#puzzleModal')).iCheck('check');
                        $('#ar_target_image_reference', $('#puzzleModal')).val(puzzle.ar_target_image_reference); // text
                        $('#ar_object', $('#puzzleModal')).val(puzzle.ar_object); // file
                        fileUploadRefreshSingle($('#ar_object_file'), puzzle.ar_object);
                    } else {
                        $('#ar_content', $('#puzzleModal')).iCheck('uncheck');
                    }

                    // Repeatable interaction
                    if (puzzle.repeatable_interaction === 'yes') { // checkbox
                        $('#repeatable_interaction', $('#puzzleModal')).iCheck('check');
                    } else {
                        $('#repeatable_interaction', $('#puzzleModal')).iCheck('uncheck');
                    }

                    $('#display_points', $('#puzzleModal')).val(puzzle.display_points); // text

                    $('#attempts', $('#puzzleModal')).val(puzzle.attempts); // text

                    // Accept any answer and move to next interaction
                    if (puzzle.accept_any_answer === 'yes') { // checkbox
                        $('#accept_any_answer', $('#puzzleModal')).iCheck('check');
                    } else {
                        $('#accept_any_answer', $('#puzzleModal')).iCheck('uncheck');
                    }

                    // Create note from answer given
                    if (puzzle.create_answer_note) { // checkbox
                        $('#create_answer_note', $('#puzzleModal')).iCheck('check');
                    } else {
                        $('#create_answer_note', $('#puzzleModal')).iCheck('uncheck');
                    }

                    // Add text field for user notes in interaction
                    if (puzzle.text_field == 'yes') { // checkbox
                        $('#text_field', $('#puzzleModal')).iCheck('check');
                    } else {
                        $('#text_field', $('#puzzleModal')).iCheck('uncheck');
                    }

                    $('#submit_button_title', $('#puzzleModal')).val(puzzle.submit_button_title); // text
                    setWysiwygContent($('#question', $('#puzzleModal')), puzzle.question); // wysiwyg
                    setSelectContent($('#type', $('#puzzleModal')), puzzle.type); // select

                    if (!isEmpty(puzzle.answer)) {
                        answer = puzzle.answer;
                        switch (puzzle.type) {
                            case 'text':
                                $('#text_default_content', $('#puzzleModal')).val(answer.default_content);
                                break;

                            case 'number':
                                $('#number_default_content', $('#puzzleModal')).val(answer.default_content);
                                break;

                            case 'proximity':
                                break;

                            case 'image selection':
                                $('.image_index', $('#answer_table')).show();

                                $('#image_selection_images', $('#puzzleModal')).val(answer.images);
                                if (!isEmpty(answer.images)) {
                                    fileUploadRefreshMultiple($('#image_selection_image_file', $('#puzzleModal')), JSON.parse(answer.images));
                                }

                                // Show/Hide Match all/ Combine selection answer checkboxes and children based on what is selected
                                let matchAllField = $('#match_all', $('#puzzleModal')).parent().parent();
                                let combineAnswersField = $('#combine_answers', $('#puzzleModal')).parent().parent();

                                // show/hide order required
                                let orderReqField = $('#order_required', $('#puzzleModal')).parent().parent();
                                if ($('#match_all', $('#puzzleModal')).is(':checked')) {
                                    orderReqField.show();
                                    combineAnswersField.hide();
                                } else {
                                    orderReqField.hide();
                                    combineAnswersField.show();
                                }

                                $('#match_all', $('#puzzleModal')).on('ifChecked', function(){
                                    orderReqField.show();
                                    combineAnswersField.hide();
                                });
                                $('#match_all', $('#puzzleModal')).on('ifUnchecked', function(){
                                    orderReqField.hide();
                                    combineAnswersField.show();
                                });

                                // show/hide min/max selection required
                                let minSelectionField = $('#min_selection', $('#puzzleModal')).parent();
                                let maxSelectionField = $('#max_selection', $('#puzzleModal')).parent();
                                if ($('#combine_answers', $('#puzzleModal')).is(':checked')) {
                                    minSelectionField.show();
                                    maxSelectionField.show();
                                    matchAllField.hide();
                                } else {
                                    minSelectionField.hide();
                                    maxSelectionField.hide();
                                    matchAllField.show();
                                }

                                $('#combine_answers', $('#puzzleModal')).on('ifChecked', function(){
                                    minSelectionField.show();
                                    maxSelectionField.show();
                                    matchAllField.hide();
                                });
                                $('#combine_answers', $('#puzzleModal')).on('ifUnchecked', function(){
                                    minSelectionField.hide();
                                    maxSelectionField.hide();
                                    matchAllField.show();
                                });

                                // Match all image selection answers
                                if (answer.match_all === 'yes') {
                                    $('#match_all', $('#puzzleModal')).iCheck('check');
                                } else {
                                    $('#match_all', $('#puzzleModal')).iCheck('uncheck');
                                }
                                // Match in correct order
                                if (answer.order_required === 'yes') {
                                    $('#order_required', $('#puzzleModal')).iCheck('check');
                                } else {
                                    $('#order_required', $('#puzzleModal')).iCheck('uncheck');
                                }
                                // Combine the answers from multiple selected images
                                if (answer.combine_answers === true) {
                                    $('#combine_answers', $('#puzzleModal')).iCheck('check');
                                } else {
                                    $('#combine_answers', $('#puzzleModal')).iCheck('uncheck');
                                }
                                $('#min_selection', $('#puzzleModal')).val(answer.min_selection);
                                $('#max_selection', $('#puzzleModal')).val(answer.max_selection);
                                break;
                            case 'image pairing':
                                $('#image_pairing_images', $('#puzzleModal')).val(answer.images);
                                if (!isEmpty(answer.images)) {
                                    fileUploadRefreshMultiple($('#image_pairing_image_file', $('#puzzleModal')), JSON.parse(answer.images));
                                }
                                break;

                            case 'image scanning barcode':
                                break;

                            case 'image scanning qr':
                                break;

                            case 'content':
                                setWysiwygContent($('#content', $('#puzzleModal')), answer.content);
                                break;
                            case 'survey':
                                $('#text_default_content', $('#puzzleModal')).val(answer.default_content);
                                break;
                            case 'take_image':
                                $('#text_default_content', $('#puzzleModal')).val(answer.default_content);
                                break;
                            case 'take_video':
                                $('#text_default_content', $('#puzzleModal')).val(answer.default_content);
                                break;
                        }
                    }

                    // assign values to feedback components
                    setWysiwygContent($('#correct_feedback', $('#puzzleModal')), puzzle.correct_feedback.html); // wysiwyg
                    assignFileToFileComponent('correct_feedback', puzzle.correct_feedback.bg, '_bg', '_file', 'puzzleModal');
                    setWysiwygContent($('#incorrect_feedback', $('#puzzleModal')), puzzle.incorrect_feedback.html); // wysiwyg
                    assignFileToFileComponent('incorrect_feedback', puzzle.incorrect_feedback.bg, '_bg', '_file', 'puzzleModal');

                    // initalise answers for this puzzle
                    resolutions = getResolutions(); // resolutions[{}] <= puzzle{}.resolutions
                    // if there is no resolution, add a default one
                    if (resolutions.length === 0) {
                        resolution.description = 'Default Answer';
                        resolution.incorrect_answer_response = 'no';
                        $('table#answer_table').DataTable().row.add([
                            0,
                            '<a class="btn-open-answer-modal answer-description" href="#" data-toggle="modal" data-target="#answerModal" data-answer-index=' + 0 + '>' + resolution.description + '</a>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + 0 + ' data-type="answer" data-name=' + resolution.description + ' disabled><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                        setResolution(0);
                        setResolutions();
                        setPuzzle(puzzleIndex);
                    } else {
                        $.each(resolutions, (key, value) => {
                            if (!isEmpty(value)) {
                                var resolutionDeleteRow;
                                if (value.description == 'Default Answer') {
                                    resolutionDeleteRow = '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="answer" data-name=' + value.description + ' disabled><i class="fa fa-trash"></i> <span>Delete</span></a>';
                                } else {
                                    resolutionDeleteRow = '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="answer" data-name=' + value.description + '><i class="fa fa-trash"></i> <span>Delete</span></a>';
                                }
                                $('table#answer_table').DataTable().row.add([
                                    key,
                                    '<a class="btn-open-answer-modal answer-description" href="#" data-toggle="modal" data-target="#answerModal" data-answer-index=' + key + '>' + value.description + '</a>',
                                    '<span class="answer-data" data-answer-index=' + key + '>' + value.answerData + '</span>',
                                    '<span class="answer-reward" data-answer-index=' + key + '>' + value.rewards + '</span>',
                                    resolutionDeleteRow
                                ]).draw();
                            }
                        });
                        // check if the image index header is missing or not, if it is hide the answer-data column <- For non image selection interactions
                        if ($('.image_index').css('display') === 'none') {
                            $('.answer-data', $('#answer_table')).parent().hide();
                        }
                    }

                    // initalise rewards for this puzzle
                    rewards = getRewards(); // rewards[{}] <= puzzle{}.rewards
                    $.each(rewards, (key, value) => {
                        if (!isEmpty(value)) {
                            $('table#reward_table').DataTable().row.add([
                                key,
                                '<a class="btn-open-reward-modal reward-title" href="#" data-toggle="modal" data-target="#rewardModal" data-reward-index=' + key + '>' + value.title + '</a>',
                                '<span class="reward-type" data-reward-index=' + key + '>' + value.type + '</span>',
                                '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="reward" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                            ]).draw();
                        }
                    });

                    // initalise hints for this puzzle
                    hints = getHints(); // hints[{}] <= puzzle{}.hints
                    $.each(hints, (key, value) => {
                        if (!isEmpty(value)) {
                            $('table#hint_table').DataTable().row.add([
                                key,
                                '<a class="btn-open-hint-modal hint-title" href="#" data-toggle="modal" data-target="#hintModal" data-hint-index=' + key + '>' + value.title + '</a>',
                                '<span class="hint-penalty-type" data-hint-index=' + key + '>' + value.penalty_type + '</span>',
                                '<span class="hint-penalty-value" data-hint-index=' + key + '>' + value.penalty_value + '</span>',
                                '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="hint" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                            ]).draw();
                        }
                    });
                } else {
                    $('.image_index', $('#answer_table')).hide();
                    // modal - create new
                    puzzleIndex = puzzles.length;
                    puzzle = getPuzzle(puzzleIndex); // puzzle{} <= puzzles[{}][index]; {}
                    $('#title', $('#puzzleModal')).val(''); // text
                    $('#display_title', $('#puzzleModal')).val(''); // text
                    $('#short_description', $('#puzzleModal')).val(''); // text
                    $('#puzzle_selection_image', $('#puzzleModal')).val(''); // file
                    fileUploadRefreshSingle($('#puzzle_selection_image_file'), '');
                    $('#lock_interaction', $('#puzzleModal')).iCheck('uncheck'); // checkbox
                    setSelectContent($('#unlock_on_interaction', $('#puzzleModal')), 'None'); // select
                    $('#repeatable_interaction', $('#puzzleModal')).iCheck('uncheck'); // checkbox
                    $('#display_points', $('#puzzleModal')).val(''); // text
                    $('#attempts', $('#puzzleModal')).val(''); // text
                    $('#accept_any_answer', $('#puzzleModal')).iCheck('uncheck'); // checkbox
                    $('#create_answer_note', $('#puzzleModal')).iCheck('uncheck'); // checkbox
                    $('#text_field', $('#puzzleModal')).iCheck('uncheck'); // checkbox
                    $('#submit_button_title', $('#puzzleModal')).val(''); // text
                    $('#ar_content', $('#puzzleModal')).iCheck('uncheck'); // checkbox
                    $('#ar_target_image_reference', $('#puzzleModal')).val(''); // text
                    $('#ar_object', $('#puzzleModal')).val(''); // file
                    fileUploadRefreshSingle($('#ar_object_file'), 'None');
                    setWysiwygContent($('#question', $('#puzzleModal')), ''); // wysiwyg
                    setSelectContent($('#type', $('#puzzleModal')), ''); // select
                    setWysiwygContent($('#correct_feedback', $('#puzzleModal')), ''); // wysiwyg
                    assignFileToFileComponent('correct_feedback', '', '_bg', '_file', 'puzzleModal');
                    setWysiwygContent($('#incorrect_feedback', $('#puzzleModal')), ''); // wysiwyg
                    assignFileToFileComponent('incorrect_feedback', '', '_bg', '_file', 'puzzleModal');

                    // initalise resolutions for this puzzle
                    resolutions = [];
                    resolution = {};
                    resolution.description = 'Incorrect Answer Response';
                    resolution.answerData = null;
                    resolution.rewards = '';
                    resolution.incorrect_answer_response = 'yes';
                    resolution.string_value = '';
                    resolution.int_value = '';
                    resolution.image_value = [];
                    resolution.object_value = {
                        ibeacon_uuid: '',
                        ibeacon_major: '',
                        ibeacon_minor: '',
                        ibeacon_proximity: '',
                        ibeacon_duration: ''
                    };
                    enableIncorrctAnswerResponse();
                    
                    $('table#answer_table').DataTable().row.add([
                        0,
                        '<a class="btn-open-answer-modal answer-description" href="#" data-toggle="modal" data-target="#answerModal" data-answer-index=' + 0 + '>' + resolution.description + '</a>',
                        '<span class="answer-data" data-answer-index=' + 0 + '>' + resolution.answerData + '</span>',
                        '<span class="answer-reward" data-answer-index=' + 0 + '>' + resolution.rewards + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + 0 + ' data-type="answer" data-name=' + resolution.description + ' disabled><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                    setResolution(0);
                    setResolutions();
                    setPuzzle(puzzleIndex);
                    
                    // initalise rewards for this puzzle
                    rewards = [];

                    // initalise hints for this puzzle
                    hints = [];
                }
            } else {
                $('#puzzleModal').modal('hide');
                parentModal.animate({ scrollTop: 0 }, 1000);
            }
        });
    });

    /**
     * disable some fields and make the description value default to be incorrect answer response
     * @return void
     */
    function enableIncorrctAnswerResponse() {
        $('#string_value', $('#answerModal')).prop('disabled', true);
        $('#int_value', $('#answerModal')).prop('disabled', true);
        $('#image_value', $('#answerModal')).prop('disabled', true);
        $('#ibeacon_uuid', $('#answerModal')).prop('disabled', true);
        $('#ibeacon_major', $('#answerModal')).prop('disabled', true);
        $('#ibeacon_minor', $('#answerModal')).prop('disabled', true);
        $('#ibeacon_proximity', $('#answerModal')).prop('disabled', true);
        $('#ibeacon_duration', $('#answerModal')).prop('disabled', true);
        $('#description', $('#answerModal')).prop('disabled', true);
        $('#image_value', $('#answerModal')).tagsinput('removeAll');
        $('#description', $('#answerModal')).val('Incorrect Answer Response');
        // wipe all values but attach them to the answer object
        $('#string_value', $('#answerModal')).val('');
        $('#int_value', $('#answerModal')).val('');
        $('#image_value', $('#answerModal')).tagsinput('removeAll');
        $('#ibeacon_uuid', $('#answerModal')).val('');
        $('#ibeacon_major', $('#answerModal')).val('');
        $('#ibeacon_minor', $('#answerModal')).val('');
        $('#ibeacon_proximity', $('#answerModal')).val('');
        $('#ibeacon_duration', $('#answerModal')).val('');
    }

    /**
     * enable these fields which are disabled by check the box
     * @return {[type]} [description]
     */
    function disableIncorrctAnswerResponse() {
        $('#string_value', $('#answerModal')).prop('disabled', false);
        $('#int_value', $('#answerModal')).prop('disabled', false);
        $('#image_value', $('#answerModal')).prop('disabled', false);
        $('#ibeacon_uuid', $('#answerModal')).prop('disabled', false);
        $('#ibeacon_major', $('#answerModal')).prop('disabled', false);
        $('#ibeacon_minor', $('#answerModal')).prop('disabled', false);
        $('#ibeacon_proximity', $('#answerModal')).prop('disabled', false);
        $('#ibeacon_duration', $('#answerModal')).prop('disabled', false);
        $('#description', $('#answerModal')).prop('disabled', false);
    }

    /*
     * opening answer modal
     */
    $(document).on('click', '.btn-open-answer-modal', (event) => {
        let target = $(event.target);
        resolutionIndex = target.attr('data-answer-index'); // update the current answer index

        rewards = getRewards();

        let rewardsOptions = [];
        $.each(rewards, (index, element) => {
            if (element) {
                rewardsOptions.push({
                    id: element.title,
                    text: element.title
                });
            }
        });

        // reset the options
        setSelectionData($('#rewards', $('#answerModal')), rewardsOptions);

        // conditionally show the value fields
        var puzzleType = $('#type', $('#puzzleModal')).val();
        var stringValueField = $('#string_value', $('#answerModal')).parent();
        var intValueField = $('#int_value', $('#answerModal')).parent();
        var imgSelIndexField = $('#image_value_field', $('#answerModal'));
        var objectValueField = $('#object_value', $('#answerModal'));

        if (puzzleType == 'text' || puzzleType == 'image scanning qr' || puzzleType == 'image scanning barcode') {
            stringValueField.removeClass('hidden');
            intValueField.addClass('hidden');
            imgSelIndexField.addClass('hidden');
            objectValueField.addClass('hidden');
        } else if (puzzleType == 'number') {
            stringValueField.addClass('hidden');
            intValueField.removeClass('hidden');
            imgSelIndexField.addClass('hidden');
            objectValueField.addClass('hidden');
        } else if (puzzleType == 'image selection') {
            stringValueField.addClass('hidden');
            intValueField.addClass('hidden');
            imgSelIndexField.removeClass('hidden');
            objectValueField.addClass('hidden');
        } else if (puzzleType == 'proximity') {
            stringValueField.addClass('hidden');
            intValueField.addClass('hidden');
            imgSelIndexField.addClass('hidden');
            objectValueField.removeClass('hidden');
        } else {
            stringValueField.addClass('hidden');
            intValueField.addClass('hidden');
            imgSelIndexField.addClass('hidden');
            objectValueField.addClass('hidden');
        }

        // auto populate desciption field and disable input fields when incorrect answer response checked
        if ($('#incorrect_answer_response', $('#answerModal')).is(':checked')) {
            enableIncorrctAnswerResponse();
        } else {
            disableIncorrctAnswerResponse();
        }

        $('#incorrect_answer_response', $('#answerModal')).on('ifChecked', function(){
            enableIncorrctAnswerResponse();
        });
        $('#incorrect_answer_response', $('#answerModal')).on('ifUnchecked', function(){
            disableIncorrctAnswerResponse();
        });

        if (!isEmpty(resolutionIndex)) {
            resolution = getResolution(resolutionIndex); // resolution{} <= resolutions[{}][index]
            $('#description', $('#answerModal')).val(resolution.description); // text

            if (puzzleType == 'text' || puzzleType == 'image scanning qr' || puzzleType == 'image scanning barcode') {
                $('#string_value', $('#answerModal')).val(resolution.string_value);
            } else if (puzzleType == 'number') {
                $('#int_value', $('#answerModal')).val(resolution.int_value);
            } else if (puzzleType == 'image selection') {
                // clear the field first
                $('#image_value', $('#answerModal')).tagsinput('removeAll');
                if (!isEmpty(resolution.image_value)) {
                    $.each(resolution.image_value, (index, element) => {
                        $('#image_value', $('#answerModal')).tagsinput('add', element);
                    });
                }
            } else if (puzzleType == 'proximity') {
                if (typeof resolution.object_value !== 'undefined') {
                    $('#ibeacon_uuid', $('#answerModal')).val(resolution.object_value.ibeacon_uuid);
                    $('#ibeacon_major', $('#answerModal')).val(resolution.object_value.ibeacon_major);
                    $('#ibeacon_minor', $('#answerModal')).val(resolution.object_value.ibeacon_minor);
                    $('#ibeacon_proximity', $('#answerModal')).val(resolution.object_value.ibeacon_proximity);
                    $('#ibeacon_duration', $('#answerModal')).val(resolution.object_value.ibeacon_duration);
                } else {
                    $('#ibeacon_uuid', $('#answerModal')).val('');
                    $('#ibeacon_major', $('#answerModal')).val('');
                    $('#ibeacon_minor', $('#answerModal')).val('');
                    $('#ibeacon_proximity', $('#answerModal')).val('');
                    $('#ibeacon_duration', $('#answerModal')).val('');
                }
            }

            if (resolution.incorrect_answer_response == 'yes') { // checkbox
                $('#incorrect_answer_response', $('#answerModal')).iCheck('check');
            } else {
                $('#incorrect_answer_response', $('#answerModal')).iCheck('uncheck');
            }
            setSelectContent($('#rewards', $('#answerModal')), resolution.rewards); // select
        } else {
            // modal - create new
            resolutionIndex = resolutions.length;
            resolution = getResolution(resolutionIndex);
            $('#description', $('#answerModal')).val(''); // text
            $('#string_value', $('#answerModal')).val('');
            $('#int_value', $('#answerModal')).val('');
            $('#image_value', $('#answerModal')).tagsinput('removeAll');
            $('#ibeacon_uuid', $('#answerModal')).val('');
            $('#ibeacon_major', $('#answerModal')).val('');
            $('#ibeacon_minor', $('#answerModal')).val('');
            $('#ibeacon_proximity', $('#answerModal')).val('');
            $('#ibeacon_duration', $('#answerModal')).val('');
            $('#incorrect_answer_response', $('#answerModal')).iCheck('uncheck');
            setSelectContent($('#rewards', $('#answerModal')), ''); // select
        }
    });

    /*
     * opening reward modal
     */
    $(document).on('click', '.btn-open-reward-modal', (event) => {
        let target = $(event.target);
        rewardIndex = target.attr('data-reward-index'); // update the current reward index

        rewards = getRewards(); // rewards[{}] <= puzzle{}.rewards

        // hide the reward contents field if the type is none
        let rewardTypeField = $('#type', $('#rewardModal'));
        let rewardPointsField = $('#points', $('#rewardModal'));
        let rewardPercentageField = $('#percentage', $('#rewardModal'));
        let rewardNotesField = $('#notes', $('#rewardModal'));

        if (rewardTypeField.val() == '') {
            rewardTypeField.val('none');
            rewardPointsField.parent().parent().hide();
            rewardPointsField.val('');
            rewardPointsField.removeClass('js-required');

            rewardPercentageField.parent().parent().hide();
            rewardPercentageField.val('');
            rewardPercentageField.removeClass('js-required');

            rewardNotesField.parent().hide();
            setWysiwygContent(rewardNotesField, '');
            rewardNotesField.removeClass('js-required');
        }

        rewardTypeField.on('change', function(event) {
            if (rewardTypeField.val() === 'none') {
                rewardPointsField.parent().parent().hide();
                rewardPointsField.val('');
                rewardPointsField.removeClass('js-required');

                rewardPercentageField.parent().parent().hide();
                rewardPercentageField.val('');
                rewardPercentageField.removeClass('js-required');

                rewardNotesField.parent().hide();
                setWysiwygContent(rewardNotesField, '');
                rewardNotesField.removeClass('js-required');

            } else if (rewardTypeField.val() === 'notes') {
                rewardPointsField.parent().parent().hide();
                rewardPointsField.val('');
                rewardPointsField.removeClass('js-required');

                rewardPercentageField.parent().parent().hide();
                rewardPercentageField.val('');
                rewardPercentageField.removeClass('js-required');

                rewardNotesField.parent().show();
                rewardNotesField.addClass('js-required');

            } else if (rewardTypeField.val() === 'points') {
                rewardPointsField.parent().parent().show();
                rewardPointsField.addClass('js-required');

                rewardPercentageField.parent().parent().hide();
                rewardPercentageField.val('');
                rewardPercentageField.removeClass('js-required');

                rewardNotesField.parent().hide();
                setWysiwygContent(rewardNotesField, '');
                rewardNotesField.removeClass('js-required');

            } else if (rewardTypeField.val() === 'percentage') {
                rewardPointsField.parent().parent().hide();
                rewardPointsField.val('');
                rewardPointsField.removeClass('js-required');

                rewardPercentageField.parent().parent().show();
                rewardPercentageField.addClass('js-required');

                rewardNotesField.parent().hide();
                setWysiwygContent(rewardNotesField, '');
                rewardNotesField.removeClass('js-required');
            }
        });

        if (!isEmpty(rewardIndex)) {
            reward = getReward(rewardIndex); // reward{} <= rewards[{}][index]
            $('#title', $('#rewardModal')).val(reward.title); // text
            if (reward.penalty == 'yes') { // checkbox
                $('#penalty', $('#rewardModal')).iCheck('check');
            } else {
                $('#penalty', $('#rewardModal')).iCheck('uncheck');
            }
            setSelectContent($('#type', $('#rewardModal')), reward.type); // select
            $('#points', $('#rewardModal')).val(reward.points); // number
            $('#percentage', $('#rewardModal')).val(reward.percentage); // number
            setWysiwygContent($('#notes', $('#rewardModal')), reward.notes); // wysiwyg
        } else {
            // modal - create new
            rewardIndex = rewards.length;
            reward = getReward(rewardIndex); // reward{} <= rewards[{}][index]; {}
            $('#title', $('#rewardModal')).val(''); // text
            $('#penalty', $('#rewardModal')).iCheck('uncheck');
            $('#points', $('#rewardModal')).val(''); // number
            $('#percentage', $('#rewardModal')).val(''); // number
            setWysiwygContent($('#notes', $('#rewardModal')), ''); // wysiwyg
        }
    });

    /*
     * opening hint modal
     */
    $(document).on('click', '.btn-open-hint-modal', (event) => {
        let target = $(event.target);
        hintIndex = target.attr('data-hint-index'); // update the current hint index

        hints = getHints(); // hints[{}] <= puzzle{}.hints

        // hide the penalty value field if the type is none
        let penaltyTypeField = $('#penalty_type', $('#hintModal'));
        let penaltyValueField = $('#penalty_value', $('#hintModal'));

        if (penaltyTypeField.val() == 'none') {
            penaltyValueField.parent().hide();
            penaltyValueField.val('');
            penaltyValueField.removeClass('js-required');
        } else {
            penaltyValueField.parent().show();
            penaltyValueField.addClass('js-required');
        }

        penaltyTypeField.on('change', function(event) {
            if (penaltyTypeField.val() == 'none') {
                penaltyValueField.parent().hide();
                penaltyValueField.val('');
                penaltyValueField.removeClass('js-required');
            } else {
                penaltyValueField.parent().show();
                penaltyValueField.addClass('js-required');
            }
        });

        if (!isEmpty(hintIndex)) {
            hint = getHint(hintIndex); // hint{} <= hints[{}][index]
            $('#title', $('#hintModal')).val(hint.title); // text
            setSelectContent($('#penalty_type', $('#hintModal')), hint.penalty_type); // select
            $('#penalty_value', $('#hintModal')).val(hint.penalty_value); // text
            setWysiwygContent($('#content', $('#hintModal')), hint.content); // wysiwyg
        } else {
            // modal - create new
            hintIndex = hints.length;
            hint = getHint(hintIndex); // hint{} <= hints[{}][index]; {}
            $('#title', $('#hintModal')).val(''); // text
            setSelectContent($('#penalty_type', $('#hintModal')), ''); // select
            $('#penalty_value', $('#hintModal')).val(''); // text
            setWysiwygContent($('#content', $('#hintModal')), ''); // wysiwyg
        }
    });

    /*
     * opening set games modal
     */
    $(document).on('click', '.btn-open-set-games-modal', (event) => {
        userGames = getUserGames();
        // check the game that has already been added
        $('.select-item').iCheck('uncheck');
        $.each(userGames, (index, element) => {
            $('.select-item[data-game-id=' + element + ']').iCheck('check');
        });

    });

    /*
     * opening duplicate games modal
     */
    $(document).on('click', '.btn-open-duplicate-game-modal', (event) => {
        // uncheck all checkbox initially
        $('.select-item').iCheck('uncheck');
    });

    /*
     * open delete games modal
     */
    $(document).on('click', '.btn-open-delete-game-modal', (event) => {
        // uncheck all checkbox initially
        $('.select-item').iCheck('uncheck');
    });

    // sets the reference for the events array that event objects should be getting pushed to when submiting a modal
    function setCurrentEventsArray(openModal) {
        switch (openModal) {
            case 'masterStageModal':
                currentEventsArray = 'experience_events';
                break;
            case 'stageModal':
                currentEventsArray = 'm_stg_events';
                break;
            case 'puzzleModal':
                currentEventsArray = 'stg_events';
                break;
            default:
                // nothing required as we would be at the experience level here
                break;
        }
    }

    // store or update the screen
    function saveScreenModalData(screenType, screenTable, screenModal) {
        let screenIndex = $('#index', $(screenModal)).val();
        let title = $('#title', $(screenModal)).val();
        let html = $('#content', $(screenModal)).val();
        let backgroundFile = $('#' + screenType + '_screen_bg_file', $(screenModal)).val();

        if (isEmpty(screen.title)) {
            // add a new entry in the table
            $('table#' + screenTable).DataTable().row.add([
                screenIndex,
                '<a href="#" class="btn-open-screen-modal screen-title" data-toggle="modal" data-target="' + screenModal + '" data-screen-index=' + screenIndex + ' data-screen-type="' +screenType + '">' + title + '</a>',
                '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-type="' + screenType + '" data-id=' + screenIndex + ' data-name=' + title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
            ]).draw();
        } else {
            $('table#' + screenTable +' .screen-title[data-screen-index=' + screenIndex + ']').text(title);
            $('table#' + screenTable +' .btn-open-delete-modal[data-id=' + screenIndex + ']').attr('data-name', title);
        }

        screen = {};
        screen.title = title;
        screen.html = html;
        screen.bg = backgroundFile;
        setScreen(screenIndex); // screen{} => screens[{}]
        setScreens(screenType); // screens => gameData
    }

    /*
     * Form Submission Handling
     * Three types of form submit
     * 1. Normal form submit - send request to server - refresh/redirect
     * 2. Submit from a modal - dynamically update the content - not refresh the page
     * 3. Ajax submit - send request to server - can refresh/redirect or not
     */

    // 2. Submit from a modal
    const modalSubmitButton = $('.modal-submit');
    if (modalSubmitButton.length) {
        modalSubmitButton.on('click', (event) => {
            event.preventDefault();
            saveModal($(event.target));
        });
    }

    function saveModal(target) {
        let currentModal = target.closest('.modal');// check which modal
        if (currentModal.length) {
            // reset validation when close the modal
            currentModal.on('hide.bs.modal', (event) => {
                $('.validation-error', currentModal).removeClass('validation-error');
            });
            // js validator
            $.when(checkModalValidity(currentModal)).then((isValid) => {
                if (isValid) {
                    saveModalData(currentModal.attr('id'));
                    currentModal.closest('.modal').modal('hide');
                    setCurrentEventsArray(currentModal.attr('id'));
                } else {
                    currentModal.animate({ scrollTop: 0 }, 1000);
                }
            });
        }
    }

    // checks current open modal for any missing required components and prevents progress until it's resolved
    function checkModalValidity(currentModal) {
        var valid = true;
        $('.js-required', currentModal).each((index, element) => {
            if (isEmpty($(element).val())) {
                if ($(element).hasClass('select2')) {
                    // select
                    $('.select2-selection', $(element).next('.select2')).addClass('validation-error');
                } else if ($(element).hasClass('textarea--wysiwyg')) {
                    // wysiwyg
                    $(element).next('iframe').addClass('validation-error');
                } else {
                    // other input
                    $(element).addClass('validation-error');
                }
                valid = false;
                showError('Error: Highlighted fields are required to be filled in before proceeding');
            } else {
                // now check current data against saved data and check if there are any differences. If there are, prompt the user to save or discard their changes
                if ($(element).hasClass('select2')) {
                    // select
                    $('.select2-selection', $(element).next('.select2')).removeClass('validation-error');
                } else if ($(element).hasClass('textarea--wysiwyg')) {
                    // wysiwyg
                    $(element).next('iframe').removeClass('validation-error');
                } else {
                    // other input
                    $(element).removeClass('validation-error');
                }
            }
        });

        return valid;
    }

    // Save button pressed when a modal is open
    function saveModalData(openModal) {
        // check if the current modals is complete or not
        let currentModal = '#'+openModal;
        switch (openModal) {
            // GAME DATA
            case 'introScreenModal':
                saveScreenModalData('intro', 'intro_screen_table', currentModal);
                break;
            case 'outroScreenModal':
                saveScreenModalData('outro', 'outro_screen_table', currentModal);
                break;
            case 'preSummaryScreenModal':
                saveScreenModalData('preSummary', 'pre_summary_screen_table', currentModal);
                break;
            case 'mailchimpModal':
                saveMailchimpMergeTags();
                break;
            case 'mailchimpMergeTagModal':
                mergeTag = {
                    description: $('#tag_description', currentModal).val(),
                    resultObj: {
                        mStgIndex: $('#master_stage_index', currentModal).val(),
                        stgIndex: $('#stage_index', currentModal).val(),
                        interactionIndex: $('#interaction_index', currentModal).val()
                    },
                    tag: $('#merge_tags', currentModal).val()
                };

                // store the current merge tag value
                addMailchimpMergeTag(mergeTagIndex); // lbColumn{} => lbColumns[{}][index]               
                break;
            case 'leaderboardBuilderModal':
                let lbURLPath = $('#leaderboard_url', currentModal).text();
                let sessionId = $('#session_id', currentModal).val();
                let leaderboardTitle = $('#leaderboard_title', currentModal).val();

                let searchable = false;
                if ($('#searchable', currentModal).is(':checked')) {
                    searchable = true;
                }

                let frequentUpdate = false;
                if ($('#frequent_update', currentModal).is(':checked')) {
                    frequentUpdate = true;
                }

                let frequentRefresh = false;
                let refreshRate = '';
                if ($('#frequent_refresh', currentModal).is(':checked')) {
                    frequentRefresh = true;
                    refreshRate = $('#refresh_rate', currentModal).val();
                }
                let leaderboardBGImage = $('#lb_bg_image', currentModal).val();
                let leaderboardLogoImage = $('#lb_logo', currentModal).val();
                let leaderboardCompanyLogo = $('#company_logo', currentModal).val();
                let defaultSortingOrder = {
                    table_column_title: $('#table_order option:selected', currentModal).text(),
                    table_order: $('#table_order', currentModal).val(),
                    table_sorting_order: $('#table_sorting_order', currentModal).val()
                }
                let priorityOrder = $('#priority_order', currentModal).val();
                let priorityOrderValue = '';
                let secondaryOrder = $('#secondary_order', currentModal).val();
                let secondaryOrderValue = '';
                let sortingOrder = $('#sorting_order', currentModal).val();
                let secondarySortingOrder = $('#secondary_sorting_order', currentModal).val();

                // required so we know which progressive points value is required to be used
                if (priorityOrder === 'progressive_points') {
                    leaderboard.priorityOrderValue = $('#priority_order option:selected', currentModal).text();
                }
                // required so we know which progressive points value is required to be used
                if (secondaryOrder === 'progressive_points') {
                    leaderboard.secondaryOrderValue = $('#secondary_order option:selected', currentModal).text();
                }

                leaderboard.columns = getLeaderboardColumns();
                leaderboard.experience = gameData.name;
                leaderboard.session_id = sessionId;
                leaderboard.title = leaderboardTitle;
                leaderboard.url_path = lbURLPath;
                leaderboard.searchable = searchable;
                leaderboard.frequent_update = frequentUpdate;
                leaderboard.frequent_refresh = frequentRefresh;
                leaderboard.refresh_rate = refreshRate;
                leaderboard.lb_bg_image = leaderboardBGImage;
                leaderboard.lb_logo = leaderboardLogoImage;
                leaderboard.company_logo = leaderboardCompanyLogo;
                leaderboard.default_sorting_order = defaultSortingOrder;
                leaderboard.priority_order = priorityOrder;
                leaderboard.secondary_order = secondaryOrder;
                leaderboard.sorting_order = sortingOrder;
                leaderboard.secondary_sorting_order = secondarySortingOrder;

                setLeaderboardData();
                break;
            case 'leaderboardColumnModal':
                let columnTitle = $('#column_title', currentModal).val();
                let sortingTitle = $('#sorting_title', currentModal).val();
                let columnVariable = $('#column_variable', currentModal).val();

                // check if the index exists within the table, if it does, update it, if not, add it
                if (isEmpty(lbColumn)) {
                    $('table#leaderboard_columns_tables').DataTable().row.add([
                        lbColumnIndex,
                        '<a class="btn-open-column-variable-modal leaderboard-column-title" href="#" data-toggle="modal" data-target="#leaderboardColumnModal" data-leaderboard-column-index=' + lbColumnIndex + '>' + columnTitle + '</a>',
                        '<span class="leaderboard-sorting-title" data-leaderboard-column-index=' + lbColumnIndex + '>' + sortingTitle + '</span>',
                        '<span class="leaderboard-column-variable" data-leaderboard-column-index=' + lbColumnIndex + '>' + columnVariable + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + lbColumnIndex + ' data-type="lb_delete" data-name=' + columnTitle + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                } else { // updates selected item in table
                    $('table#leaderboard_columns_tables .leaderboard-column-title[data-leaderboard-column-index=' + lbColumnIndex + ']').text(columnTitle);
                    $('table#leaderboard_columns_tables .leaderboard-sorting-title[data-leaderboard-column-index=' + lbColumnIndex + ']').text(sortingTitle);
                    $('table#leaderboard_columns_tables .leaderboard-column-variable[data-leaderboard-column-index=' + lbColumnIndex + ']').text(columnVariable);
                }

                lbColumn = {};
                lbColumn.columnTitle = columnTitle;
                lbColumn.sortingTitle = sortingTitle;
                lbColumn.columnVariable = columnVariable;

                switch (columnVariable) {
                    case 'interaction_result':
                        lbColumn.resultObj = {
                            mStgIndex: $('#master_stage_column', currentModal).val(),
                            stgIndex: $('#stage_column', currentModal).val(),
                            interactionIndex: $('#interaction_column', currentModal).val()
                        }
                        break;
                    case 'progressive_points':
                        lbColumn.resultObj = {
                            mStgIndex: $('#master_stage_column', currentModal).val(),
                            stgIndex: $('#stage_column', currentModal).val(),
                        }
                        break;
                    case 'time_spent':
                    case 'time_left':
                        let timerIndex = $('#leaderboard_timers', currentModal).val();

                        lbColumn.timeObj = timerList[timerIndex];
                        break;
                }

                // store the current master stage value
                setLeaderboardColumn(lbColumnIndex); // lbColumn{} => lbColumns[{}][index]
                setLeaderboardColumns(lbColumns); // lbColumns[{}] => leaderboard
                updateLeaderboardColumnTable(); // updates the leaderboard columns table
                break;
            case 'eventModal':
                let eventsTable = getEventsArray(currentEventsArray); 
                // store or update event             
                title = $('#title', currentModal).val();
                let persistentEvent = false;
                if ($('#persistent_event', currentModal).is(':checked')) {
                    persistentEvent = true;
                }
                let trigger = $('#trigger', currentModal).val();
                let parameter = $('#parameter', currentModal).val();
                let timerTriggerIndex = $('#trigger_timer', currentModal).val();
                // parameter values
                let num_value = $('#num_value', currentModal).val();
                let beacon_uuid = $('#ibeacon_uuid', currentModal).val();
                let beacon_major = $('#ibeacon_major', currentModal).val();
                let beacon_minor = $('#ibeacon_minor', currentModal).val();
                let beacon_proximity = $('#ibeacon_proximity', currentModal).val();
                let beacon_duration = $('#ibeacon_duration', currentModal).val();
                let completedChoice = $('#completed_selection', currentModal).val();
                // event type selection
                let eventType = $('#event_type', currentModal).val();
                let timerEventIndex = $('#event_timer', currentModal).val();
                // event type content values
                //let textContent = $('#text_content', currentModal).val();
                let numContent = $('#num_content', currentModal).val();
                let wysiwygContent = $('#wysiwyg_content', currentModal).val();
                let indexMStg = $('#masterStageOptions', currentModal).val();
                let indexStg = $('#stageOptions', currentModal).val();
                let indexInteraction = $('#interactionOptions', currentModal).val();
                // feedback values
                let feedback_choice = $('#eventFeedbackOptions', currentModal).val();
                let feedback_content = $('#feedback_content', currentModal).val();
                let feedback_wysiwyg = $('#event_wysiwyg_feedback', currentModal).val();
                let feedback_background_file = $('#event_feedback_bg_file', currentModal).val();
                let eventTableRef = 'table#'+eventTable;

                // check if the index exists within the table, if it does, update it, if not, add it
                if (eventIndex === eventsTable.length) {
                    $(eventTableRef).DataTable().row.add([
                        eventIndex,
                        '<a class="btn-open-event-modal event-title" href="#" data-toggle="modal" data-target="#eventModal" data-event-index=' + eventIndex + '>' + title + '</a>',
                        '<span class="event-trigger" data-event-index=' + eventIndex + '>' + trigger + '</span>',
                        '<span class="event-event" data-event-index=' + eventIndex + '>' + eventType + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + eventIndex + ' data-type="event" data-name=' + title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                } else { // updates selected item in table
                    $(eventTableRef+' .event-title[data-event-index=' + eventIndex + ']').text(title);
                    $(eventTableRef+' .event-trigger[data-event-index=' + eventIndex + ']').text(trigger);
                    $(eventTableRef+' .event-event[data-event-index=' + eventIndex + ']').text(eventType);
                }

                eventObj = {}; // clear the object so we don't have unnecessary variables sitting there
                eventObj.title = title;
                eventObj.persistent_event = persistentEvent;
                eventObj.trigger = trigger;
                eventObj.parameter = parameter;

                // sets the parameter values
                if (trigger === 'Time') {
                    let timerTrigger = timerTriggerList[timerTriggerIndex];
                    eventObj.parameterValue = {};
                    eventObj.parameterValue = {
                        parentTimer: timerTrigger.timer,
                        timerIndex: timerTrigger.timerIndex,
                        title: timerTrigger.title,
                        value: num_value
                    };
                    eventObj.trigger_timer = timerTriggerIndex;
                } else if (trigger === 'Beacon') {
                    eventObj.parameterValue = {};
                    eventObj.parameterValue.object_value = {
                        'ibeacon_uuid': beacon_uuid,
                        'ibeacon_major': beacon_major,
                        'ibeacon_minor': beacon_minor,
                        'ibeacon_proximity': beacon_proximity,
                        'ibeacon_duration': beacon_duration
                    };
                } else if (trigger === 'Completed') {
                    if (parameter === 'Stage') {
                        eventObj.parameterValue = {};
                        eventObj.parameterValue = {
                            mStgIndex: masterStageIndex,
                            stgIndex: completedChoice
                        }
                    } else if (parameter === 'Interaction') {
                        eventObj.parameterValue = {};
                        eventObj.parameterValue = {
                            mStgIndex: masterStageIndex,
                            stgIndex: stageIndex,
                            interactionIndex: completedChoice
                        }
                    }
                }

                eventObj.event_type = eventType;
                // conditionally set the content variable based on event type selected
                if (eventType === 'give_points' || eventType === 'take_points') {
                    eventObj.content = numContent;
                } else if (eventType === 'give_time' || eventType === 'take_time') {
                    var timerEvent = timerList[timerEventIndex];
                    eventObj.content = {};
                    eventObj.content = {
                        value: numContent,
                        parentTimer: timerEvent.timer,
                        timerIndex: timerEvent.timerIndex,
                        title: timerEvent.title
                    };
                    eventObj.event_timer = timerEventIndex;
                } else if (eventType === 'give_note') {
                    eventObj.content = wysiwygContent;
                } else if (eventType === 'go_to_masterStage' || eventType === 'go_to_stage'|| eventType === 'go_to_interaction' || eventType === 'unlock_stage' || eventType === 'unlock_interaction') {
                    eventObj.content = {};
                    eventObj.content = {
                        'mStgIndex': indexMStg,
                        'stgIndex': indexStg,
                        'interactionIndex': indexInteraction
                    }
                }

                eventObj.feedback_option = feedback_choice;
                if (feedback_choice === 'pop_up_modal') {
                    eventObj.feedback_content = {
                        html: feedback_wysiwyg,
                        bg: feedback_background_file
                    }
                } else if (feedback_choice === 'notification') {
                    eventObj.feedback_content = feedback_content;
                }

                // store the current event value
                setEventForArray(currentEventsArray, eventIndex); // eventObj{} => level specific[{}][index]
                setEventsArray(currentEventsArray); // level specific[{}] => gameData
                break;
            case 'masterStageModal':
                // store or update the screen
                title = $('#title', currentModal).val();
                let structure = $('#structure', currentModal).val();
                let abandonStageAllowed = 'no';
                if ($('#abandon', currentModal).is(':checked')) {
                    abandonStageAllowed = 'yes';
                }
                let minimumRequirements = $('#minimum_requirements', currentModal).val();
                let timeRestrictionMasterStage = $('#time_restriction', currentModal).val();
                let timePenaltyMasterStage = $('#time_penalty', currentModal).val();
                let timeRestrictionTitleMasterStage = $('#time_restriction_title', currentModal).val();
                let masterStageMap = $('#master_stage_map', currentModal).val();
                let stage_sel_instructions = $('#stage_sel_instructions', currentModal).val();
                // feedback components
                let instruction = $('#instruction', currentModal).val();
                let instructionBGFile = $('#mstg_instruction_bg_file', currentModal).val();
                let successScreenMS = $('#success_screen', currentModal).val();
                let successScreenBGFile = $('#mstg_success_screen_bg_file', currentModal).val();
                let failureScreenMS = $('#failure_screen', currentModal).val();
                let failureScreenBGFile = $('#mstg_failure_screen_bg_file', currentModal).val();

                // save theme settings
                let masterStageThemeSettings = {};
                masterStageThemeSettings.master_stage_theme_logo_image = $('.master_stage_theme #master_stage_theme_logo_image').val();
                masterStageThemeSettings.master_stage_theme_background_image = $('.master_stage_theme #master_stage_theme_background_image').val();
                masterStageThemeSettings.primary_color = $('.master_stage_theme #primary_color').val();
                masterStageThemeSettings.primary_font_family = $('.master_stage_theme #primary_font_family').val();
                masterStageThemeSettings.primary_font_size = convertToAppPercentage($('.master_stage_theme #primary_font_size').val(), 40);
                masterStageThemeSettings.secondary_color = $('.master_stage_theme #secondary_color').val();
                masterStageThemeSettings.secondary_font_family = $('.master_stage_theme #secondary_font_family').val();
                masterStageThemeSettings.secondary_font_size = convertToAppPercentage($('.master_stage_theme #secondary_font_size').val(), 30);
                masterStageThemeSettings.tertiary_color = $('.master_stage_theme #tertiary_color').val();
                masterStageThemeSettings.tertiary_font_family = $('.master_stage_theme #tertiary_font_family').val();
                masterStageThemeSettings.tertiary_font_size = convertToAppPercentage($('.master_stage_theme #tertiary_font_size').val(), 20);

                if (isEmpty(masterStage.title)) {
                    // recently created
                    // add a new entry in the table
                    $('table#master_stage_table').DataTable().row.add([
                        masterStageIndex,
                        '<a class="btn-open-master-stage-modal master-stage-title" href="#" data-toggle="modal" data-target="#masterStageModal" data-master-stage-index=' + masterStageIndex + '>' + title + '</a>',
                        '<span class="master-stage-type" data-master-stage-index=' + masterStageIndex + '>' + structure + '</span>',
                        '<span class="master-stage-minimum-requirements" data-master-stage-index=' + masterStageIndex + '>' + minimumRequirements + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-type="master stage" data-id=' + masterStageIndex + ' data-name=' + title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                } else {
                    // existing one
                    // update the table
                    $('table#master_stage_table .master-stage-title[data-master-stage-index=' + masterStageIndex + ']').text(title);
                    $('table#master_stage_table .master-stage-type[data-master-stage-index=' + masterStageIndex + ']').text(structure);
                    $('table#master_stage_table .master-stage-minimum-requirements[data-master-stage-index=' + masterStageIndex + ']').text(minimumRequirements);
                    $('table#master_stage_table .btn-open-delete-modal[data-id=' + masterStageIndex + ']').attr('data-name', title);
                }

                masterStage.title = title;
                masterStage.structure = structure;
                masterStage.minimum_requirements = minimumRequirements;
                masterStage.abandon_stage_allowed = abandonStageAllowed;
                masterStage.time_restriction = timeRestrictionMasterStage;
                masterStage.time_penalty = timePenaltyMasterStage;
                masterStage.time_restriction_title = timeRestrictionTitleMasterStage;
                masterStage.master_stage_map_image = masterStageMap;
                masterStage.stage_sel_instructions = stage_sel_instructions;
                // feedback components
                masterStage.instruction = {
                    html: instruction,
                    bg: instructionBGFile
                };
                masterStage.success_screen = {
                    html: successScreenMS,
                    bg: successScreenBGFile
                };
                masterStage.failure_screen = {
                    html: failureScreenMS,
                    bg: failureScreenBGFile
                };
                masterStage.theme_settings = masterStageThemeSettings;

                // store the current master stage value
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                break;
            case 'stageModal':
                // store or update the screen
                title = $('#title', currentModal).val();
                let displayTitle = $('#display_title', currentModal).val();
                let gameMode = $('#game_mode', currentModal).val();
                structure = $('#structure', currentModal).val();
                minimumRequirements = $('#minimum_requirements', currentModal).val();
                let showProgress = 'no';
                if ($('#show_progress', currentModal).is(':checked')) {
                    showProgress = 'yes';
                }
                let repeatableStage = 'no';
                if ($('#repeatable_stage', currentModal).is(':checked')) {
                    repeatableStage = 'yes';
                }
                let targetPoints = $('#target_points', currentModal).val();
                let lockStage = false;
                if ($('#lock_stage', currentModal).is(':checked')) {
                    lockStage = true;
                }
                let unlockOn = $('#unlock_on', currentModal).val();
                let timeRestriction = $('#time_restriction', currentModal).val();
                let timePenalty = $('#time_penalty', currentModal).val();
                let timeRestrictionTitle = $('#time_restriction_title', currentModal).val();
                let selection_instructions = $('#sel_instructions', currentModal).val();
                let selectionImage = $('#stage_selection_image', currentModal).val();
                let selectionMap = $('#stage_selection_map', currentModal).val();
                let instructions = $('#instructions', currentModal).val();
                let instructionsBGFile = $('#instructions_bg_file', currentModal).val();
                let successAllScreen = $('#success_all_screen', currentModal).val();
                let successAllScreenBGFile = $('#success_all_screen_bg_file', currentModal).val();
                let failureScreen = $('#failure_screen', currentModal).val();
                let stgFailureBGFile = $('#stage_failure_screen_bg_file', currentModal).val();
                // save theme settings
                let stageThemeSettings = {};
                stageThemeSettings.stage_theme_logo_image = $('.stage_theme #stage_theme_logo_image').val();
                stageThemeSettings.stage_theme_background_image = $('.stage_theme #stage_theme_background_image').val();
                stageThemeSettings.primary_color = $('.stage_theme #primary_color').val();
                stageThemeSettings.primary_font_family = $('.stage_theme #primary_font_family').val();
                stageThemeSettings.primary_font_size = convertToAppPercentage($('.stage_theme #primary_font_size').val(), 40);
                stageThemeSettings.secondary_color = $('.stage_theme #secondary_color').val();
                stageThemeSettings.secondary_font_family = $('.stage_theme #secondary_font_family').val();
                stageThemeSettings.secondary_font_size = convertToAppPercentage($('.stage_theme #secondary_font_size').val(), 30);
                stageThemeSettings.tertiary_color = $('.stage_theme #tertiary_color').val();
                stageThemeSettings.tertiary_font_family = $('.stage_theme #tertiary_font_family').val();
                stageThemeSettings.tertiary_font_size = convertToAppPercentage($('.stage_theme #tertiary_font_size').val(), 20);

                if (isEmpty(stage.title)) {
                    $('table#stage_table').DataTable().row.add([
                        stageIndex,
                        '<a class="btn-open-stage-modal stage-title" href="#" data-toggle="modal" data-target="#stageModal" data-stage-index=' + stageIndex + '>' + title + '</a>',
                        '<span class="stage-display-title" data-stage-index=' + stageIndex + '>' + displayTitle + '</span>',
                        '<span class="stage-game-mode" data-stage-index=' + stageIndex + '>' + gameMode + '</span>',
                        '<span class="stage-unlock-on" data-stage-index=' + stageIndex + '>' + unlockOn + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + stageIndex + ' data-type="stage" data-name=' + title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                } else {
                    $('table#stage_table .stage-title[data-stage-index=' + stageIndex + ']').text(title);
                    $('table#stage_table .stage-display-title[data-stage-index=' + stageIndex + ']').text(displayTitle);
                    $('table#stage_table .stage-game-mode[data-stage-index=' + stageIndex + ']').text(gameMode);
                    $('table#stage_table .stage-unlock-on[data-stage-index=' + stageIndex + ']').text(unlockOn);
                }

                stage.title = title;
                stage.display_title = displayTitle;
                stage.game_mode = gameMode;
                stage.structure = structure;
                stage.minimum_requirements = minimumRequirements;
                stage.show_progress = showProgress;
                stage.repeatable_stage = repeatableStage;
                stage.target_points = targetPoints;
                stage.locked_stage = lockStage;
                stage.unlock_on = unlockOn;
                stage.time_restriction = timeRestriction;
                stage.time_penalty = timePenalty;
                stage.time_restriction_title = timeRestrictionTitle;
                stage.selection_instructions = selection_instructions;
                stage.selection_image = selectionImage;
                stage.selection_map = selectionMap;
                // feedback components
                stage.instructions = {
                    html: instructions,
                    bg: instructionsBGFile
                };
                stage.success_all_screen = {
                    html: successAllScreen,
                    bg: successAllScreenBGFile
                };
                stage.failure_screen = {
                    html: failureScreen,
                    bg: stgFailureBGFile
                };
                stage.theme_settings = stageThemeSettings;
                stage.puzzles = getPuzzles();

                // store the current stage value
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                break;
            case 'puzzleModal':

                title = $('#title', currentModal).val();
                displayTitle = $('#display_title', currentModal).val();
                let shortDescription = $('#short_description', currentModal).val();
                selectionImage = $('#puzzle_selection_image', currentModal).val();
                let lockInteraction = false;
                if ($('#lock_interaction', currentModal).is(':checked')) {
                    lockInteraction = true;
                }
                let unlockOnInteraction = $('#unlock_on_interaction', currentModal).val();
                let repeatableInteraction = 'no';
                if ($('#repeatable_interaction', currentModal).is(':checked')) {
                    repeatableInteraction = 'yes';
                }
                let display_points = $('#display_points', currentModal).val();
                let attempts = $('#attempts', currentModal).val();
                let submitButtonTitle = $('#submit_button_title', currentModal).val();
                let acceptAnyAnswer = 'no';
                if ($('#accept_any_answer', currentModal).is(':checked')) {
                    acceptAnyAnswer = 'yes'
                }
                let createAnswerNote = false;
                if ($('#create_answer_note', currentModal).is(':checked')) {
                    createAnswerNote = true;
                }
                let textField = 'no';
                if ($('#text_field', currentModal).is(':checked')) {
                    textField = 'yes'
                }
                let arContent = false;
                let arTargetImageReference;
                let arObject;
                if ($('#ar_content', currentModal).is(':checked')) {
                    arContent = true;
                    arTargetImageReference = $('#ar_target_image_reference', currentModal).val();
                    arObject = $('#ar_object', currentModal).val();
                }
                let question = $('#question', currentModal).val();
                type = $('#type', currentModal).val();

                let correctFeedback = $('#correct_feedback', currentModal).val();
                let correctFeedbackBG = $('#correct_feedback_bg_file', currentModal).val();
                let incorrectFeedback = $('#incorrect_feedback', currentModal).val();
                let incorrectFeedbackBG = $('#incorrect_feedback_bg_file', currentModal).val();

                let numberOfHints = getHints().length;

                // store the answer data based on type
                let answer = {} // object
                switch (type) {
                    case 'text':
                        answer.default_content = $('#text_default_content', currentModal).val();
                        break;
                    case 'number':
                        answer.default_content = $('#number_default_content', currentModal).val();
                        break;
                    case 'proximity':
                        break;
                    case 'image selection':
                        answer.images = $('#image_selection_images', currentModal).val();
                        answer.match_all = $('#match_all', currentModal).is(':checked') ? 'yes' : 'no';
                        answer.order_required = $('#order_required', currentModal).is(':checked') ? 'yes' : 'no';
                        answer.combine_answers = $('#combine_answers', currentModal).is(':checked') ? true : false;
                        answer.min_selection = $('#min_selection', currentModal).val();
                        answer.max_selection = $('#max_selection', currentModal).val();
                        break;
                    case 'image pairing':
                        break;
                    case 'image scanning barcode':
                        break;
                    case 'image scanning qr':
                        break;
                    case 'content':
                        answer.content = $('#content', currentModal).val();
                        break;
                }

                if (isEmpty(puzzle.title)) {
                    $('table#puzzle_table').DataTable().row.add([
                        puzzleIndex,
                        '<a class="btn-open-puzzle-modal puzzle-title" href="#" data-toggle="modal" data-target="#puzzleModal" data-puzzle-index=' + puzzleIndex + '>' + title + '</a>',
                        '<span class="puzzle-type" data-puzzle-index=' + puzzleIndex + '>' + type + '</span>',
                        '<span class="puzzle-num-hints" data-puzzle-index=' + puzzleIndex + '>' + numberOfHints + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + puzzleIndex + ' data-type="puzzle" data-name=' + title + '><i class="fa fa-trash"></i> <span>Delete</span></a>',
                        '<span class="puzzle-unlock-on" data-puzzle-index=' + puzzleIndex + '>' + unlockOnInteraction + '</span>',
                    ]).draw();
                } else {
                    $('table#puzzle_table .puzzle-title[data-puzzle-index=' + puzzleIndex + ']').text(title);
                    $('table#puzzle_table .puzzle-type[data-puzzle-index=' + puzzleIndex + ']').text(type);
                    $('table#puzzle_table .puzzle-num-hints[data-puzzle-index=' + puzzleIndex + ']').text(numberOfHints);
                    $('table#puzzle_table .puzzle-unlock-on[data-puzzle-index=' + puzzleIndex + ']').text(unlockOnInteraction);
                }

                puzzle.title = title;
                puzzle.display_title = displayTitle;
                puzzle.short_description = shortDescription;
                puzzle.selection_image = selectionImage;
                puzzle.locked_interaction = lockInteraction;
                puzzle.unlock_on_interaction = unlockOnInteraction;
                puzzle.repeatable_interaction = repeatableInteraction;
                puzzle.display_points = display_points;
                puzzle.attempts = attempts;
                puzzle.accept_any_answer = acceptAnyAnswer;
                puzzle.create_answer_note = createAnswerNote;
                puzzle.text_field = textField;
                puzzle.submit_button_title = submitButtonTitle;
                puzzle.ar_content = arContent;
                if (arContent === true) {
                    puzzle.ar_target_image_reference = arTargetImageReference;
                    puzzle.ar_object = arObject;
                }
                puzzle.question = question;
                puzzle.type = type;
                puzzle.answer = answer;
                puzzle.correct_feedback = {
                    html: correctFeedback,
                    bg: correctFeedbackBG
                };
                puzzle.incorrect_feedback = {
                    html: incorrectFeedback,
                    bg: incorrectFeedbackBG
                };

                // store the current puzzle value
                setPuzzle(puzzleIndex); // puzzle{} => puzzles[{}][index]
                setPuzzles(); // puzzles[{}] => stage{}.puzzles
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                break;
            case 'answerModal':
                let stringValue = $('#string_value', currentModal).val();
                let intValue = $('#int_value', currentModal).val();
                let imageValue = $('#image_value', $('#answerModal')).val().split(',');
                let objectValue = {};

                objectValue.ibeacon_uuid = $('#ibeacon_uuid', $('#answerModal')).val();
                objectValue.ibeacon_major = $('#ibeacon_major', $('#answerModal')).val();
                objectValue.ibeacon_minor = $('#ibeacon_minor', $('#answerModal')).val();
                objectValue.ibeacon_proximity = $('#ibeacon_proximity', $('#answerModal')).val();
                objectValue.ibeacon_duration = $('#ibeacon_duration', $('#answerModal')).val();

                let incorrect_answer_response = 'no';

                if ($('#incorrect_answer_response', currentModal).is(':checked')) {
                    incorrect_answer_response = 'yes';
                }

                let description = $('#description', currentModal).val();
                let answerData = null;
                let answerRewards = $('#rewards', currentModal).val();

                // add answer data to table if there is any present
                if ($('#type', $('#puzzleModal')).val() === 'image selection') {
                    answerData = imageValue;
                }

                if (isEmpty(resolution.description)) {
                    $('table#answer_table').DataTable().row.add([
                        resolutionIndex,
                        '<a class="btn-open-answer-modal answer-description" href="#" data-toggle="modal" data-target="#answerModal" data-answer-index=' + resolutionIndex + '>' + description + '</a>',
                        '<span class="answer-data" data-answer-index=' + resolutionIndex + '>' + answerData + '</span>',
                        '<span class="answer-reward" data-answer-index=' + resolutionIndex + '>' + answerRewards + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + resolutionIndex + ' data-type="answer" data-name=' + description + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();

                    // check if the image index header is missing or not, if it is hide the answer-data column <- For non image selection interactions
                    if ($('.image_index').css('display') === 'none') {
                        $('.answer-data', $('#answer_table')).parent().hide();
                    }

                } else {
                    $('table#answer_table .answer-description[data-answer-index=' + resolutionIndex + ']').text(description);
                    $('table#answer_table .answer-data[data-answer-index=' + resolutionIndex + ']').text(answerData);
                    $('table#answer_table .answer-reward[data-answer-index=' + resolutionIndex + ']').text(answerRewards);
                }

                resolution.incorrect_answer_response = incorrect_answer_response;
                resolution.description = description;
                resolution.answerData = answerData;
                resolution.string_value = stringValue;
                resolution.int_value = intValue;
                resolution.image_value = imageValue;
                resolution.object_value = objectValue;
                resolution.rewards = answerRewards;

                // store the current resolution value
                setResolution(resolutionIndex); // resolution{} => resolutions[{}][index]
                setResolutions(); // resolutions[{}] => puzzle{}.resolutions
                setPuzzle(puzzleIndex); // puzzle{} => puzzles[{}][index]
                setPuzzles(); // puzzles[{}] => stage{}.puzzles
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                break;
            case 'rewardModal':
                type = $('#type', currentModal).val();

                if (type !== 'none') {
                    title = $('#title', currentModal).val();
                    let penalty = 'no';
                    if ($('#penalty', currentModal).is(':checked')) {
                        penalty = 'yes';
                    }
                    
                    points = $('#points', currentModal).val();
                    let percentage = $('#percentage', currentModal).val();
                    let notes = $('#notes', currentModal).val();

                    if (isEmpty(reward.title)) {
                        $('table#reward_table').DataTable().row.add([
                            rewardIndex,
                            '<a class="btn-open-reward-modal reward-title" href="#" data-toggle="modal" data-target="#rewardModal" data-reward-index=' + rewardIndex + '>' + title + '</a>',
                            '<span class="reward-type" data-reward-index=' + rewardIndex + '>' + type + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + rewardIndex + ' data-type="reward" data-name=' + title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    } else {
                        $('table#reward_table .reward-title[data-reward-index=' + rewardIndex + ']').text(title);
                        $('table#reward_table .reward-type[data-reward-index=' + rewardIndex + ']').text(type);
                    }

                    reward.title = title;
                    reward.penalty = penalty;
                    reward.type = type;
                    reward.points = points;
                    reward.percentage = percentage;
                    reward.notes = notes;

                    // store the current reward value
                    setReward(rewardIndex); // reward{} => rewards[{}][index]
                    setRewards(); // rewards[{}] => puzzle{}.rewards
                    setPuzzle(puzzleIndex); // puzzle{} => puzzles[{}][index]
                    setPuzzles(); // puzzles[{}] => stage{}.puzzles
                    setStage(stageIndex); // stage{} => stages[{}][index]
                    setStages(); // stages[{}] => masterStage{}.stages
                    setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                    setMasterStages(); // masterStages[{}] => gameData
                } else {
                    showError('You cannot save reward that has not had it\'s time defined');
                }
                break;
            case 'hintModal':
                title = $('#title', currentModal).val();
                let penaltyType = $('#penalty_type', currentModal).val();
                let penaltyValue = $('#penalty_value', currentModal).val();
                content = $('#content', currentModal).val();

                if (isEmpty(hint.title)) {
                    $('table#hint_table').DataTable().row.add([
                        hintIndex,
                        '<a class="btn-open-hint-modal hint-title" href="#" data-toggle="modal" data-target="#hintModal" data-hint-index=' + hintIndex + '>' + title + '</a>',
                        '<span class="hint-penalty-type" data-hint-index=' + hintIndex + '>' + penaltyType + '</span>',
                        '<span class="hint-penalty-value" data-hint-index=' + hintIndex + '>' + penaltyValue + '</span>',
                        '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + hintIndex + ' data-type="hint" data-name=' + title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                    ]).draw();
                } else {
                    $('table#hint_table .hint-title[data-hint-index=' + hintIndex + ']').text(title);
                    $('table#hint_table .hint-penalty-type[data-hint-index=' + hintIndex + ']').text(penaltyType);
                    $('table#hint_table .hint-penalty-value[data-hint-index=' + hintIndex + ']').text(penaltyValue);
                }

                hint.title = title;
                hint.penalty_type = penaltyType;
                hint.penalty_value = penaltyValue;
                hint.content = content;

                // store the current hint value
                setHint(hintIndex); // hint{} => hints[{}][index]
                setHints(); // hints[{}] => puzzle{}.hints
                setPuzzle(puzzleIndex); // puzzle{} => puzzles[{}][index]
                setPuzzles(); // puzzles[{}] => stage{}.puzzles
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                break;
            case 'duplicateGameModal':
                var gameId = $('.select-item:checked').attr('data-game-id');
                $.ajax({
                    type: 'POST',
                    url: '/games/duplicate/' + gameId,
                    success: function(response) {
                        if (response.status == 'success') {
                            showNotification(response.message);
                            setTimeout(function() {
                                window.location.href = '/games/' + response.game_id;
                            }, 2000);
                        } else {
                            ajaxValidationError(response.errors);
                        }
                    }
                });
                break;
        }
    }

    // 3. Ajax submit
    function ajaxValidationError(errors) {
        errorsHtml = '<ul>';
        $.each(errors, (key, value) => {
            errorsHtml += '<li>' + value[0] + '</li>'; // showing only the first error.
        });
        errorsHtml += '</ul>';
        $('.form-errors').html(errorsHtml);
        $('.error-alert').removeClass('hidden');
        $('body').animate({ scrollTop: 0 }, 1000);
    }

    const ajaxSubmitButton = $('.ajax-submit');

    if (ajaxSubmitButton.length) {
        ajaxSubmitButton.on('click', (event) => {
            event.preventDefault();
            checkSaveData($(event.target), event);
        });
    }

    function checkSaveData(target, event) {
        let currentModal = '';

        if (target.closest('.modal').length) {
            // check which modal
            currentModal = target.closest('.modal');
        }

        if (currentModal !== '') {
            $.when(checkModalValidity(currentModal)).then((isValid) => {
                if (!isValid) {
                    currentModal.animate({ scrollTop: 0 }, 1000);
                } else {
                    saveDataToDatabase(currentModal, event);
                }
            });
        } else {
            saveDataToDatabase(currentModal, event);
        }
    }

    // saveDataToDatabase is responsible for saving Game Data, User Data or Company Data based on the current page the user is viewing
    function saveDataToDatabase(currentModal, event) {
        // check which view
        if (gameView.length) {
            let gameId = gameView.attr('data-game-id');

            // extra settings
            let extraSettings = [];
            $('#extra_settings_component input').each((index, element) => {
                if ($(element).is(':checked')) {
                    extraSettings.push($(element).attr('id'));
                    // setting a global game timer
                    if ($(element).attr('id') === 'time_restriction') {
                        extraSettings.push($('#extra_settings_component input#time_restriction_value').val());
                        extraSettings.push($('#extra_settings_component input#time_restriction_title').val());
                    }
                    // sending to an external database
                    if ($(element).attr('id') === 'send_data') {
                        extraSettings.push($('#extra_settings_component input#db_address').val());
                    }
                    // sending user media to dropbox
                    if ($(element).attr('id') === 'send_user_media') {
                        let db_token = {
                            dropbox_token: $('#extra_settings_component input#dropbox_token').val()
                        };

                        extraSettings.push(db_token);
                    }

                }
                if ($(element).attr('id') === 'initial_points') {
                    extraSettings.push('initial_points');
                    extraSettings.push($('#extra_settings_component input#initial_points').val());
                }
            });

            // construct new game object
            gameData.name = $('#name').val();

            // construct the game status object
            let status = $('#mode').val();
            let statusLabel = 'none';

            switch(status) {
                case 'production':
                    statusLabel = 'danger';
                    break;
                case 'review':
                    statusLabel = 'warning';
                    break;
                case 'good':
                    statusLabel = 'success';
                    break;
                case 'template':
                    statusLabel = 'default';
                    break;
                case 'testing':
                    statusLabel = 'primary';
                    break;
                case 'deprecated':
                    statusLabel = 'default';
                    break;
            }
            
            let gameStatus = {
                status: status,
                statusText: $('#mode option:selected').text(),
                labelType: statusLabel
            }
            gameData.mode = JSON.stringify(gameStatus);
            gameData.short_description = $('#short_description').val();
            gameData.logo = $('#logo').val();
            gameData.map = $('#map').val();
            gameData.extra_settings = JSON.stringify(extraSettings);
            gameData.terms_conditions = $('#terms_conditions').val();
            gameData.start_pin_code = $('#start_pin_code').val();
            gameData.exit_pin_code = $('#exit_pin_code').val();

            helpScreen = formatFeedbackContent('help_screen', gameData.help_screen);

            gameData.help_screen = JSON.stringify(helpScreen);

            // gameData.ar_device_database = $('#ar_device_database').val();

            gameData.team_singular = $('#team_singular').val();
            gameData.team_plural = $('#team_plural').val();
            gameData.stage_singular = $('#stage_singular').val();
            gameData.stage_plural = $('#stage_plural').val();
            gameData.puzzle_singular = $('#puzzle_singular').val();
            gameData.puzzle_plural = $('#puzzle_plural').val();
            gameData.points_singular = $('#points_singular').val();
            gameData.points_plural = $('#points_plural').val();
            gameData.points_preface = $('#points_preface').val();

            successScreen = formatFeedbackContent('success_screen', gameData.success_screen);
            gameData.success_screen = JSON.stringify(successScreen);

            failureScreen = formatFeedbackContent('failure_screen', gameData.failure_screen);
            gameData.failure_screen = JSON.stringify(failureScreen);

            gameData.summary_pin_code = $('#summary_pin_code').val();

            // make sure we save everything possible - need to do checks to validate the saving of the data
            if (currentModal !== '') {
                if (currentModal.attr('id') === 'leaderboardBuilderModal') {
                    saveModalData(currentModal.attr('id'));
                    sendToLeaderboardPlugin(event); // POST to wordpress plugin to create the leaderboard
                } else {
                    saveModalData(currentModal.attr('id'));
                }
            }

            themeSettings.master_theme_logo_image = $('.master_theme #master_theme_logo_image').val();
            themeSettings.master_theme_background_image = $('.master_theme #master_theme_background_image').val();
            themeSettings.primary_color = $('.master_theme #primary_color').val();
            themeSettings.primary_font_family = $('.master_theme #primary_font_family').val();
            themeSettings.primary_font_size = convertToAppPercentage($('.master_theme #primary_font_size').val(), 40);
            themeSettings.secondary_color = $('.master_theme #secondary_color').val();
            themeSettings.secondary_font_family = $('.master_theme #secondary_font_family').val();
            themeSettings.secondary_font_size = convertToAppPercentage($('.master_theme #secondary_font_size').val(), 30);
            themeSettings.tertiary_color = $('.master_theme #tertiary_color').val();
            themeSettings.tertiary_font_family = $('.master_theme #tertiary_font_family').val();
            themeSettings.tertiary_font_size = convertToAppPercentage($('.master_theme #tertiary_font_size').val(), 20);
            setThemeSettings();

            if (isEmpty(gameId)) {
                // create a game
                $.ajax({
                    type: 'POST',
                    url: '/games',
                    data: gameData, 
                }).done((response) => {
                    if (response.status === 'success') {
                        showNotification(response.message);
                        gameId = response.game_id;

                        if (response.redirect) {
                            // here you actually redirect to the new page
                            window.location = response.redirect;
                        }
                    } else {
                        ajaxValidationError(response.errors);
                    }
                }).fail((response) => {
                    ajaxValidationError('Server not responding. Please contact your closest dev.\n', response.errors);
                });
            } else {
                // update a game
                $.ajax({
                    type: 'PUT',
                    url: '/games/' + gameId,
                    data: gameData,
                }).done((response) => {
                    if (response.status === 'success') {
                        showNotification(response.message);
                    } else {
                        ajaxValidationError(response.errors);
                    }
                }).fail((response) => {
                    ajaxValidationError('Update failed. Server not responding. Please contact your closest dev.\n', response.errors);
                });
            }
        }

        if (accountView.length) {
            let accountData = {
                'first_name': $('#first_name').val(),
                'last_name': $('#last_name').val(),
                'email': $('#email').val(),
                'company': $('#company').val(),
                'contact_number': $('#contact_number').val(),
                'password': $('#password').val(),
                'avatar': $('#avatar').val()
            };
            // update current user
            $.ajax({
                type: 'POST',
                url: '/account',
                data: accountData,
                success: function(response) {
                    if (response.status == 'success') {
                        showNotification(response.message);
                        setTimeout(function () {
                            window.location.href = '/account'
                        }, 1000);
                    } else {
                        ajaxValidationError(response.errors);
                    }
                }
            });
        }

        if (companyView.length) {
            if (currentModal) {
                // if company admin, pressing save will save the company data as expected since only the company form is displayed
                switch (currentModal.attr('id')) {
                    case 'employeeModal':
                        saveUserData(employeeID, employeeData);
                        break;
                    case 'setCompanyExperiencesModal':
                        setCompanyExperienceTableData(currentModal);
                        break;
                    default:
                        saveCompanyData(currentModal);
                        break;
                }
            } else {
                saveCompanyData('');
            }
        }
    }

    function saveUserData(accountID, newUserData) {
        let currentCompanyID = $('.company-view').attr('data-company-id');
        let accessGroup = newUserData.group ? newUserData.group : 'staff';
        let accessType = newUserData.access_options ? newUserData.access_options : 'view_only';

        newUserData.first_name = $('#first_name').val();
        newUserData.last_name = $('#last_name').val();
        newUserData.email = $('#email').val();
        newUserData.contact_number = $('#contact_number').val();
        newUserData.beacon_login = setUserBeaconLoginDetails();
        newUserData.default_experience = setDefaultExperience();

        if ($('.super', $('#employeeModal')).length) {
            currentCompanyID = $('#employee_company').val();
            accessGroup = $('input[name="group"]:checked').val();
            accessType = accessSettings.access_type;
        }

        assignAccessSettings(accessType);

        newUserData.company = currentCompanyID;
        newUserData.group = accessGroup;
        newUserData.access_options = accessType;
        
        if (isEmpty(accountID)) {
            // create a new user
            // password need to be sent
            newUserData.password = $('#password').val();

            $.ajax({
                type: 'POST',
                url: '/users',
                data: newUserData,
                success: function(response) {
                    if (response.status == 'success') {
                        // if we just added an employee to a company, update the employees table for that company
                        if (companyView.length) {
                            newUserData.id = response.user_id;
                            populateCompanyEmployeesTable(response.user_id, newUserData);
                        }

                        showNotification(response.message);

                        // update user access table with new values
                        sendToUserAccess(response.user_id);
                    } else {
                        ajaxValidationError(response.errors);
                    }
                }
            });
        } else {
            // update a user
            // password will not be send if it is empty
            if (!isEmpty($('#password').val())) {
                newUserData.password = $('#password').val();
            }
            $.ajax({
                type: 'PUT',
                url: '/users/' + accountID,
                data: newUserData,
                success: function(response) {
                    if (response.status == 'success') {
                        // if we just added an employee to a company, update the employees table for that company
                        if (companyView.length) {
                            populateCompanyEmployeesTable(accountID, newUserData, true);
                        }
                        showNotification(response.message);

                        // update user access table with new values
                        sendToUserAccess(accountID);

                    } else {
                        ajaxValidationError(response.errors);
                    }
                },
                error: function(err) {
                    console.error('Error uploading userdata: ', err);
                }
            });
        }
    }

    // updates user_access table
    function sendToUserAccess (userId) {
        let ids = {
            user_id: userId,
            access_id: 0
        };
        let accessOptions = {};
        // loop through accessSettings and remove "_component" from each key name so that the data can be passed to the correct column in the user_access table
        Object.keys(accessSettings).forEach(function(accessSettingKey, accessSettingIndex) {
            if (accessSettingKey !== 'access_type') {
                var newName = accessSettingKey.replace('_component', '');
                accessOptions[newName] = accessSettings[accessSettingKey];

            } else {
                accessOptions[accessSettingKey] = accessSettings[accessSettingKey];
            }
        });

        let userAccess = $.extend({}, ids, accessOptions);
        let data = JSON.stringify(userAccess);

        $.ajax({
            type: 'PUT',
            url: '/access/' + userId,
            data: data,
            success: function(response) {
                if (response.status !== 'success') {
                    ajaxValidationError(response.errors);
                }
            }
        });
    }

    /******************************* CLOSE/CANCEL ************************************/
    /*
     * Close/Cancel button logic
     * Ensures that the current event array is set to the correct value when closing modals without saving them.
     */

    const modalCloseButton = $('.modal-close');
    if (modalCloseButton.length) {
        modalCloseButton.on('click', (event) => {
            event.preventDefault();
            let target = $(event.target);
            if (target.closest('.modal').length) {
                // check which modal
                let currentModal = target.closest('.modal');
                switch (currentModal.attr('id')) {
                    case 'masterStageModal':
                        currentEventsArray = 'experience_events';
                        break;
                    case 'stageModal':
                        currentEventsArray = 'm_stg_events';
                        break;
                    case 'puzzleModal':
                        currentEventsArray = 'stg_events';
                        break;
                    default:
                        break;
                }
            }
        });
    }


    /******************************* DELETE ***********************************/
    /*
     * delete button logic
     * Steps:
     * 1 . Open the delete confirm modal - pass type, id, name.
     * 2 . Delete when the Yes button on the modal is clicked
     */

    // 1. Open the delete confirm modal
    // passing the variable to the modal

    const deleteConfirmModal = $('#deleteConfirmModal');

    $(document).on('click', '.btn-open-delete-modal', (event) => {
        let target = $(event.currentTarget);
        let type = target.data('type');
        let id = target.data('id');
        let name = target.data('name');
        let rowId = target.parents('tr').attr('id');

        // change the modal-body prop to allow for custom messages to be set within it when assigned one.
        let modalMsg = '<p>Delete ' + name + '</p>';

        switch(type) {
            case 'experience':
                modalMsg = '<p>Deleting experiences is a permanent action\nThis will remove the experience for other users too and you will not be able to undo this action\nAre you sure you want to continue?</p>';
                break;
            case 'user game':
                modalMsg = '<p>Are you sure you want to remove ' + name + ' from your experience list?</p>';
                break;
            case 'employee':
                modalMsg = '<p>Remove ' + name + '?</p>';
                break;
        }

        $('.modal-body', deleteConfirmModal).html(modalMsg);
        $('.btn-delete', deleteConfirmModal).attr('data-type', type);
        $('.btn-delete', deleteConfirmModal).attr('data-id', id);
        $('.btn-delete', deleteConfirmModal).attr('data-row-id', rowId);

        // if delete stage, add master stage index
        if (!isEmpty(target.attr('data-master-stage-index'))) {
            $('.btn-delete', deleteConfirmModal).attr('data-master-stage-index', target.attr('data-master-stage-index'));
        }
    });

    // 2. Perform the delete function
    $(document).on('click', '.btn-delete', (event) => {
        let target = $(event.currentTarget);
        let type = target.attr('data-type');
        let id = target.attr('data-id');
        let rowId = target.attr('data-row-id');        
        let tempArray = []; // used to assist with ensuring we don't have arrays with null objects

        switch (type) {
            // intro screen
            case 'intro':
                screens = getScreens(type);
                delete screens[id];
                removeScreenData(type, 'intro_screen_table', 'introScreenModal');
                break;
            // outro screen
            case 'outro':
                screens = getScreens(type);
                delete screens[id];
                removeScreenData(type, 'outro_screen_table', 'outroScreenModal');
                break;
            // pre summary screen
            case 'preSummary':
                screens = getScreens(type);
                delete screens[id];
                removeScreenData(type, 'pre_summary_screen_table', 'preSummaryScreenModal');
                break;
            // mailchimp merge tag
            case 'mailchimp_merge_tag':
                let currentTags = getMailchimpMergeTags();
                delete currentTags[id];
                // ensures null objects are not kept within the array
                tempArray = currentTags.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                mergeTags = tempArray;

                saveMailchimpMergeTags();
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Merge Tag has been deleted successfully.');

                $('table#mailchimp_merge_tags_table').DataTable().row('#' + rowId).remove().draw();

                // $('table#mailchimp_merge_tags_table').DataTable().clear().draw();
                // updateMergeTagsTable();
                break;
            // leaderboard column
            case 'lb_column':
                let currentColumns = getLeaderboardColumns();
                delete currentColumns[id];
                // ensures null objects are not kept within the array
                tempArray = currentColumns.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                setLeaderboardColumns(tempArray);
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Column has been deleted successfully.');

                $('table#leaderboard_columns_table').DataTable().clear().draw();
                updateLeaderboardColumnTable();
                break;
            // Event
            case 'event':
                let currentEvents = getEventsArray(currentEventsArray);
                delete currentEvents[id];
                // ensures null objects are not kept within the array
                tempArray = currentEvents.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                setEventsArray(currentEventsArray, tempArray);
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Event has been deleted successfully.');

                $('table#'+eventTable).DataTable().clear().draw();
                $.each(tempArray, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#'+eventTable).DataTable().row.add([
                            key,
                            '<a class="btn-open-event-modal event-title" href="#" data-toggle="modal" data-target="#eventModal" data-event-index=' + key + '>' + value.title + '</a>',
                            '<span class="event-trigger" data-event-index=' + key + '>' + value.trigger + '</span>',
                            '<span class="event-event" data-event-index=' + key + '>' + value.event_type + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="event" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    }
                });
                break;
            // master stage
            case 'master stage':
                delete masterStages[id];
                // ensures null objects are not kept within the array
                tempArray = masterStages.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                masterStages = tempArray;
                setMasterStages(); // masterStages[{}] => gameData
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Master Stage has been deleted successfully.');

                $('table#master_stage_table').DataTable().clear().draw();
                $.each(tempArray, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#master_stage_table').DataTable().row.add([
                            key,
                            '<a class="btn-open-master-stage-modal master-stage-title" href="#" data-toggle="modal" data-target="#masterStageModal" data-master-stage-index=' + key + '>' + value.title + '</a>',
                            '<span class="master-stage-type" data-master-stage-index=' + key + '>' + value.structure + '</span>',
                            '<span class="master-stage-minimum-requirements" data-master-stage-index=' + key + '>' + value.minimum_requirements + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-type="master stage" data-id=' + key + ' data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    }
                });
                break;
            // stage
            case 'stage':
                delete stages[id];
                // ensures null objects are not kept within the array
                tempArray = stages.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                stages = tempArray;
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Stage has been deleted successfully.');

                $('table#stage_table').DataTable().clear().draw();
                $.each(tempArray, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#stage_table').DataTable().row.add([
                            key,
                            '<a class="btn-open-stage-modal stage-title" href="#" data-toggle="modal" data-target="#stageModal" data-stage-index=' + key + '>' + value.title + '</a>',
                            '<span class="stage-display-title" data-stage-index=' + key + '>' + value.displayTitle + '</span>',
                            '<span class="stage-game-mode" data-stage-index=' + key + '>' + value.gameMode + '</span>',
                            '<span class="stage-unlock-on" data-stage-index=' + key + '>' + value.unlockOn + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="stage" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    }
                });
                break;
            case 'puzzle':
                delete puzzles[id];
                // ensures null objects are not kept within the array
                tempArray = puzzles.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                puzzles = tempArray;
                setPuzzles(); // puzzles[{}] => stage{}.puzzles
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Puzzle has been deleted successfully.');

                $('table#puzzle_table').DataTable().clear().draw();
                $.each(tempArray, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#puzzle_table').DataTable().row.add([
                            key,
                            '<a class="btn-open-puzzle-modal puzzle-title" href="#" data-toggle="modal" data-target="#puzzleModal" data-puzzle-index=' + key + '>' + value.title + '</a>',
                            '<span class="puzzle-type" data-puzzle-index=' + key + '>' + value.type + '</span>',
                            '<span class="puzzle-num-hints" data-puzzle-index=' + key + '>' + value.numberOfHints + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="puzzle" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>',
                            '<span class="puzzle-unlock-on" data-puzzle-index=' + key + '>' + value.unlockOnInteraction + '</span>',
                        ]).draw();
                    }
                });
                break;
            case 'answer':
                delete resolutions[id];
                // ensures null objects are not kept within the array
                tempArray = resolutions.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                resolutions = tempArray;
                setResolutions(); // resolutions[{}] => puzzle{}.resolutions
                setPuzzle(puzzleIndex); // puzzle{} => puzzles[{}][index]
                setPuzzles(); // puzzles[{}] => stage{}.puzzles
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Answer has been deleted successfully.');

                $('table#answer_table').DataTable().clear().draw();
                $.each(tempArray, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#answer_table').DataTable().row.add([
                            key,
                            '<a class="btn-open-answer-modal answer-description" href="#" data-toggle="modal" data-target="#answerModal" data-answer-index=' + key + '>' + value.description + '</a>',
                            '<span class="answer-data" data-answer-index=' + key + '>' + value.answerData + '</span>',
                            '<span class="answer-reward" data-answer-index=' + key + '>' + value.rewards + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="answer" data-name=' + value.description + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    }
                });
                break;
            case 'reward':
                delete rewards[id];
                // ensures null objects are not kept within the array
                tempArray = rewards.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                rewards = tempArray;
                setRewards(); // rewards[{}] => puzzle{}.rewards
                setPuzzle(puzzleIndex); // puzzle{} => puzzles[{}][index]
                setPuzzles(); // puzzles[{}] => stage{}.puzzles
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Reward has been deleted successfully.');

                $('table#reward_table').DataTable().clear().draw();
                $.each(tempArray, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#reward_table').DataTable().row.add([
                            key,
                            '<a class="btn-open-reward-modal reward-title" href="#" data-toggle="modal" data-target="#rewardModal" data-reward-index=' + key + '>' + value.title + '</a>',
                            '<span class="reward-type" data-reward-index=' + key + '>' + value.type + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="reward" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    }
                });
                break;
            case 'hint':
                delete hints[id];
                // ensures null objects are not kept within the array
                tempArray = hints.filter(function(val) {
                    if (val) {
                        return val;
                    }
                });
                hints = tempArray;
                setHints(); // hints[{}] => puzzle{}.hints
                setPuzzle(puzzleIndex); // puzzle{} => puzzles[{}][index]
                setPuzzles(); // puzzles[{}] => stage{}.puzzles
                setStage(stageIndex); // stage{} => stages[{}][index]
                setStages(); // stages[{}] => masterStage{}.stages
                setMasterStage(masterStageIndex); // masterStage{} => masterStages[{}][index]
                setMasterStages(); // masterStages[{}] => gameData
                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Hint has been deleted successfully.');

                $('table#hint_table').DataTable().clear().draw();
                $.each(tempArray, (key, value) => {
                    if (!isEmpty(value)) {
                        $('table#hint_table').DataTable().row.add([
                            key,
                            '<a class="btn-open-hint-modal hint-title" href="#" data-toggle="modal" data-target="#hintModal" data-hint-index=' + key + '>' + value.title + '</a>',
                            '<span class="hint-penalty-type" data-hint-index=' + key + '>' + value.penaltyType + '</span>',
                            '<span class="hint-penalty-value" data-hint-index=' + key + '>' + value.penaltyValue + '</span>',
                            '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-id=' + key + ' data-type="hint" data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                        ]).draw();
                    }
                });
                break;
            case 'theme':
                $.ajax({
                    type: 'DELETE',
                    url: '/themes/' + id,
                    success: function() {
                        deleteConfirmModal.modal('hide');
                        showNotification(response.message);
                    }
                });
                $('table#theme_listing_table').DataTable().row('#' + rowId).remove().draw();
                break;
            case 'user':
                $.ajax({
                    type: 'DELETE',
                    url: '/users/' + id,
                    success: function(response) {
                        deleteConfirmModal.modal('hide');
                        showNotification(response.message);
                    }
                });
                $('table#user_listing_table').DataTable().row('#' + rowId).remove().draw();
                break;
            case 'employee':
                // If user listing table is present, then a Super Admin is using the page
                if ($('#user_listing_table').length) {
                    // if Super admin is in a Company modal, then only remove the user from the company
                    if ($('#companyModal').hasClass('in')) {
                        // remove company id from the employee and update the employee's table entry
                        $.ajax({
                            type: 'POST',
                            url: '/users/removeCompany/' + id,
                            success: function(response) {
                                if (response.status == 'success') {
                                    // if we just added an employee to a company, update the employees table for that company
                                    showNotification(response.message);
                                } else {
                                    ajaxValidationError(response.errors);
                                }
                            },
                            error: function(err) {
                                console.log('Error uploading userdata: ', err);
                            }
                        });
                    } else {
                        // If company modal is not present, delete the User
                        $.ajax({
                            type: 'DELETE',
                            url: '/users/' + id,
                            success: function(response) {
                                showNotification(response.message);
                            }
                        });
                    }
                } else {
                    // remove company id from the employee and update the employee's table entry
                    $.ajax({
                        type: 'POST',
                        url: '/users/removeCompany/' + id,
                        success: function(response) {
                            if (response.status == 'success') {
                                // if we just added an employee to a company, update the employees table for that company
                                showNotification(response.message);
                            } else {
                                ajaxValidationError(response.errors);
                            }
                        },
                        error: function(err) {
                            console.log('Error uploading userdata: ', err);
                        }
                    });
                }

                deleteConfirmModal.modal('hide');                
                $('table#company_employees_table').DataTable().row('#' + rowId).remove().draw();
                break;
            case 'company':
                $.ajax({
                    type: 'DELETE',
                    url: '/companies/' + id,
                    success: function(response) {
                        deleteConfirmModal.modal('hide');
                        showNotification(response.message);
                    },
                    error: function(err) {
                        console.log('Error deleting company: ', err);
                    }
                });
                $('table#company_listing_table').DataTable().row('#' + rowId).remove().draw();
                break;
            case 'experience':
                let experiencesToDelete = [];

                $('.select-item').each((index, element) => {
                    if ($(element).is(':checked')) {
                        let experienceID = $(element).attr('data-game-id');

                        experiencesToDelete.push(experienceID);
                    }
                });
               
                // get their id and send it to the server to delete them
                if (experiencesToDelete.length > 0) {
                    $.ajax({
                        type: 'POST',
                        url: '/games/deleteMore',
                        data: JSON.stringify(experiencesToDelete),
                        success: function(response) {
                            if (response.status == 'success') {
                                showNotification(response.message);

                                // remove from the Delete games table
                                $('table#delete-game-table').DataTable().rows((rowIndex, data, node) => {
                                    for (var i = 0; i < experiencesToDelete.length; i++) {
                                        if ($(node).attr('data-game-id') === experiencesToDelete[i]) {
                                            return node;
                                        }
                                    }
                                 }).remove().draw();

                                // remove from the Experience list
                                 $('table#game_listing_table').DataTable().rows((rowIndex, data, node) => {
                                    for (var i = 0; i < experiencesToDelete.length; i++) {
                                        if ($(node).attr('data-game-id') === experiencesToDelete[i]) {
                                            return node;    
                                        }
                                        
                                    }
                                 }).remove().draw();
                            } else {
                                ajaxValidationError(response.errors);
                            }
                            deleteConfirmModal.modal('hide');
                        },
                        error: function(err) {
                            console.log('Error deleting games ', err);
                        }
                    });
                }
                break;
            case 'company-experience':
                rowId = parseInt(id);

                if (companyExperiences.length > 1) {
                    companyExperiences.splice(id, 1);
                } else {
                    companyExperiences = [];
                }

                // after delete
                deleteConfirmModal.modal('hide');
                showNotification('Experience has been removed for this user successfully.');
                $('table#company_experiences_table').DataTable().row(rowId).remove().draw();
                break;
        }
    });
    /***************************** DELETE SCREEN LOGIC **********************************/
    function removeScreenData(screenType, screenTable, screenModal) {
        // ensures null objects are not kept within the array
        tempArray = screens.filter(function(val) {
            if (val) {
                return val;
            }
        });
        screens = tempArray;
        setScreens(screenType); // screens[{}] => gameData
        // after delete
        deleteConfirmModal.modal('hide');
        showNotification('Screen has been deleted successfully.');
        $('table#' + screenTable).DataTable().clear().draw();
        $.each(tempArray, (key, value) => {
            if (!isEmpty(value)) {
                $('table#' + screenTable).DataTable().row.add([
                    key,
                    '<a href="#" class="btn-open-screen-modal screen-title" data-toggle="modal" data-target="#' + screenModal + '" data-screen-index=' + key + ' data-screen-type="' + screenType + '">' + value.title + '</a>',
                    '<a class="btn btn-danger btn-open-delete-modal" data-toggle="modal" data-target="#deleteConfirmModal" data-type="' + screenType + '" data-id=' + key + ' data-name=' + value.title + '><i class="fa fa-trash"></i> <span>Delete</span></a>'
                ]).draw();
            }
        });
    }

    /***************************** SEND LEADERBOARD DATA TO LEADERBOARD PLUGIN **********************************/
    /*
     * POST
     * Sends all leaderboard data to leaderboard plugin in Wordpress.
     */
    function sendToLeaderboardPlugin(e) {
        var lbObj = getLeaderboardData();
        lbObj.source = 'admin_portal'; // Assists plugin with identifying what it's supposed to do with the obj it receives
        var lbData = JSON.stringify(lbObj);
        let lbPackage = {
            api_key: 'DOTEAPIKEY',
            lb_obj: lbData,
            message: 'leaderboard obj received'
        };

        e.preventDefault();

        $.ajax({
            type: 'POST',
            url: globalLeaderboardWebURLPrepend + 'wp-json/api/leaderboard/data',
            dataType: 'JSONP',
            data: lbPackage,
            success: function(res) {
                if (res.status === 'success') {

                    showNotification(res.message);
                } else {
                    ajaxValidationError(res.errors);
                }
            }
        });
    }

    /*
     * POST
     * Sends message to wordpress leaderboard plugin to 'clear' user results from an experience/sessions leaderboard
     */
    function clearUserResults() {
        var lbObj = getLeaderboardData();
        lbObj.source = 'clear_results';
        var lbData = JSON.stringify(lbObj);
        let lbPackage = {
            api_key: 'DOTEAPIKEY',
            lb_obj: lbData,
            message: 'leaderboard obj received - attempting to clear results'
        };

        $.ajax({
            type: 'POST',
            url: globalLeaderboardWebURLPrepend + 'wp-json/api/leaderboard/data',
            dataType: 'JSONP',
            data: lbPackage,
            success: function(res) {
                if (res.status === 'success') {
                    showNotification('User results have been cleared successfully');
                } else {
                    showNotification('There was a problem with clearing user results. Try again. If symptoms persist, please contact a developer');
                }
            }
        });
    }

/***************************** EXPORT REPORT **********************************/
    $('.report-user-select').on('change', function() {
        var userId = $(this).val();
        if (userId) {
            $.ajax({
                type: 'POST',
                url: '/api/portal/game-list',
                data: { userId: userId },
                success: function(res) {
                    if (res.result == 'success') {
                        $('.report-experience-select').select2('destroy');
                        $('.report-experience-select').html('<option value selected disabled>Experience</option>');
                        $('.report-experience-select').select2({
                            data: res.games
                        });
                    } else {
                        showNotification('There was a problem with retrieving user results. Try again. If symptoms persist, please contact a developer');
                    }
                }
            });
        }
    });

    
    $('.report-export').on('click', function(e) {
        e.preventDefault();

        var validity = true;
        $('.js-required').each((index, element) => {
            if (isEmpty($(element).val())) {
                if ($(element).hasClass('select2')) {
                    $('.select2-selection', $(element).next('.select2')).addClass('validation-error');
                }
                validity = false;
            } else {
                if ($(element).hasClass('select2')) {
                    $('.select2-selection', $(element).next('.select2')).removeClass('validation-error');
                }
            }
        });

        if (validity) {
            $('.report-form').submit();
        }
    });

/***************************** Keyboard Shortcuts **********************************/
    $(document).keypress("s",function(e) {
        if (e.ctrlKey || e.metaKey) {           
            checkSaveData($(e.target));
        }
    });

/***************************** Font Conversions **********************************/
    // converts font value set in Admin panel to value based on screen dimensions of device
    function convertToAppPercentage(adjustment, defaultValue) {
        let adjustmentValue = defaultValue * (adjustment / 100);
        let fontSize = defaultValue + adjustmentValue;
        let baseWidth = 1024;
        let percentage = ( fontSize / baseWidth ) * 100;

        return percentage;
    }

    // converts font percentage saved to value back into number
    function convertFromAppPercentage(savedValue, defaultValue) {
        let baseWidth = 1024;
        let size = (baseWidth * savedValue) / 100;
        let adjustmentValue = size - defaultValue;
        let percentage = (adjustmentValue / defaultValue) * 100;

        return percentage;
    }

/***************************** Generic functions **********************************/
    function isValidJSON(string) {
        try {
            return (JSON.parse(string) && !!string);
        } catch (e) {
            return false;
        }
    }

    // keeping this as generic as possible for future implementation
    function assignFileToFileComponent(componentName, file, position, type, parentObj) {
        if (parentObj) {
            $('#' + componentName + position + type, $('#' + parentObj)).val(file); //file
            fileUploadRefreshSingle($('#' + componentName + position, $('#' + parentObj)), file);
        } else {
            $('#' + componentName + position + type).val(file); //file
            fileUploadRefreshSingle($('#' + componentName + position), file);
        }
    }

    function assignHTMLtoWYSIWYGComponent(componentName, content, parentObj) {
        if (parentObj === undefined) {
            setWysiwygContent($('#' + componentName), content); // wysiwyg
        } else {
            setWysiwygContent($('#' + componentName, $('#' + parentObj)), content); // wysiwyg
        }
    }

    // remove null objects from within an array
    function cleanNullsFromArrayData(array) {
        let dirtyArray = array;
        let cleanArray = [];

        if (isValidJSON(dirtyArray)) {
            dirtyArray = JSON.parse(dirtyArray);
        }

        if (dirtyArray !== undefined) {
            for (let i = 0; i < dirtyArray.length; i++) {
                if (dirtyArray[i] !== null) {
                    cleanArray.push(dirtyArray[i]);
                }
            }
        }

        return cleanArray;
    }

    function formatFeedbackContent(feedbackName, dirtyFeedbackObj, parentObj) {
        let cleanFeedbackObj = dirtyFeedbackObj;
        let feedbackHTML = $('#' + feedbackName).val();
        let feedbackBG = $('#' + feedbackName + '_bg_file').val();

        // required if saving newly created experience
        if (dirtyFeedbackObj === undefined || dirtyFeedbackObj === '') {
            cleanFeedbackObj = {
                html: '',
                bg:''
            }
        }

        // if somehow by some miracle the feedbackObj has been converted to JSON object
        if (isValidJSON(dirtyFeedbackObj)) {
            cleanFeedbackObj = JSON.parse(dirtyFeedbackObj);
        }

        if (parentObj !== undefined) {
            feedbackHTML = $('#' + feedbackName, $('#' + parentObj)).val();
            feedbackBG = $('#' + feedbackName + '_bg_file', $('#' + parentObj)).val();
        }

        cleanFeedbackObj.html = feedbackHTML;
        cleanFeedbackObj.bg = feedbackBG;

        return cleanFeedbackObj;
    }
}

$(document)
    .ready(init)
